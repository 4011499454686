import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const NavigationItem = ({ drawerIsOpen, item }) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(false);
    }, [drawerIsOpen]);

    const handleOnClick = () => {
        // toggleOpen solo si el drawer está abierto
        if (drawerIsOpen) setExpanded(prev => !prev);
        
        navigate(item.path)
    }

    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: drawerIsOpen ? 'initial' : 'center',
                    px: 2.5,
                }}
                onClick={handleOnClick}
            >
                <ListItemIcon
                    color='primary'
                    sx={{
                        minWidth: 0,
                        mr: drawerIsOpen ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {item.iconElement}
                </ListItemIcon>
                <ListItemText primary={item.descripcion} sx={{ opacity: drawerIsOpen ? 1 : 0 }}/>
            </ListItemButton>
            {
                (drawerIsOpen && item.children) &&
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            item.children.map((child, index) =>
                                <ListItemButton key={`child-nav-${child.nombre}`} sx={{ pl: 4 }}>
                                    <ListItemText inset primary={child.descripcion} />
                                </ListItemButton>
                            )
                        }
                    </List>
                </Collapse>
            }
        </ListItem>
    )
}

export default NavigationItem;