import React from 'react';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export default function EditButtonComponent({ onClick }) {
    return (
        <IconButton size='large' onClick={onClick}>
            <EditIcon />
        </IconButton>
    );
}