import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';

const Loading = ({ }) => {
    const loader = useSelector((state) => state.shared.loader);

    return (
        (loader.isLoading)
            ? <Backdrop open={loader.isLoading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2000 }} >
                <CircularProgress variant={loader.variant} color={loader.color} />
            </Backdrop>
            : <></>
    );
};

export default Loading;