/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEncuestaBocaUrna = /* GraphQL */ `
  mutation CreateEncuestaBocaUrna(
    $input: CreateEncuestaBocaUrnaInput!
    $condition: ModelEncuestaBocaUrnaConditionInput
  ) {
    createEncuestaBocaUrna(input: $input, condition: $condition) {
      id
      circuitoElectoral
      codigoEscuela
      escuela
      operativo
      encuestador
      rangoEtario
      genero
      pudoVotar
      votoEnBlanco
      cortoBoleta
      votoListaPresidente
      votoListaGobernador
      votoListaIntendente
      noPudoVotarMotivo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEncuestaBocaUrna = /* GraphQL */ `
  mutation UpdateEncuestaBocaUrna(
    $input: UpdateEncuestaBocaUrnaInput!
    $condition: ModelEncuestaBocaUrnaConditionInput
  ) {
    updateEncuestaBocaUrna(input: $input, condition: $condition) {
      id
      circuitoElectoral
      codigoEscuela
      escuela
      operativo
      encuestador
      rangoEtario
      genero
      pudoVotar
      votoEnBlanco
      cortoBoleta
      votoListaPresidente
      votoListaGobernador
      votoListaIntendente
      noPudoVotarMotivo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEncuestaBocaUrna = /* GraphQL */ `
  mutation DeleteEncuestaBocaUrna(
    $input: DeleteEncuestaBocaUrnaInput!
    $condition: ModelEncuestaBocaUrnaConditionInput
  ) {
    deleteEncuestaBocaUrna(input: $input, condition: $condition) {
      id
      circuitoElectoral
      codigoEscuela
      escuela
      operativo
      encuestador
      rangoEtario
      genero
      pudoVotar
      votoEnBlanco
      cortoBoleta
      votoListaPresidente
      votoListaGobernador
      votoListaIntendente
      noPudoVotarMotivo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPersonal = /* GraphQL */ `
  mutation CreatePersonal(
    $input: CreatePersonalInput!
    $condition: ModelPersonalConditionInput
  ) {
    createPersonal(input: $input, condition: $condition) {
      id
      nickname
      rol
      codigoEscuelaAsignada
      nombreEscuelaAsignada
      circuitoElectoralAsignado
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePersonal = /* GraphQL */ `
  mutation UpdatePersonal(
    $input: UpdatePersonalInput!
    $condition: ModelPersonalConditionInput
  ) {
    updatePersonal(input: $input, condition: $condition) {
      id
      nickname
      rol
      codigoEscuelaAsignada
      nombreEscuelaAsignada
      circuitoElectoralAsignado
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePersonal = /* GraphQL */ `
  mutation DeletePersonal(
    $input: DeletePersonalInput!
    $condition: ModelPersonalConditionInput
  ) {
    deletePersonal(input: $input, condition: $condition) {
      id
      nickname
      rol
      codigoEscuelaAsignada
      nombreEscuelaAsignada
      circuitoElectoralAsignado
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSeguimiento = /* GraphQL */ `
  mutation CreateSeguimiento(
    $input: CreateSeguimientoInput!
    $condition: ModelSeguimientoConditionInput
  ) {
    createSeguimiento(input: $input, condition: $condition) {
      id
      encuestador
      operativo
      codigoEscuela
      circuitoElectoral
      escuela
      cantidadEncuestas
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSeguimiento = /* GraphQL */ `
  mutation UpdateSeguimiento(
    $input: UpdateSeguimientoInput!
    $condition: ModelSeguimientoConditionInput
  ) {
    updateSeguimiento(input: $input, condition: $condition) {
      id
      encuestador
      operativo
      codigoEscuela
      circuitoElectoral
      escuela
      cantidadEncuestas
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSeguimiento = /* GraphQL */ `
  mutation DeleteSeguimiento(
    $input: DeleteSeguimientoInput!
    $condition: ModelSeguimientoConditionInput
  ) {
    deleteSeguimiento(input: $input, condition: $condition) {
      id
      encuestador
      operativo
      codigoEscuela
      circuitoElectoral
      escuela
      cantidadEncuestas
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMesaTestigo = /* GraphQL */ `
  mutation CreateMesaTestigo(
    $input: CreateMesaTestigoInput!
    $condition: ModelMesaTestigoConditionInput
  ) {
    createMesaTestigo(input: $input, condition: $condition) {
      id
      operativo
      seccionElectoral
      partido
      circuitoElectoral
      nombreEscuela
      codigoEscuela
      mesa
      fiscalizador
      cantidadPersonasPadron
      cantidadPersonasVotaron
      lastSyncPersonasVotaron
      expansor
      cerrada
      cargoCallCenter
      escrutinioPresidenteSubido
      escrutinioGobernadorSubido
      escrutinioIntendenteSubido
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMesaTestigo = /* GraphQL */ `
  mutation UpdateMesaTestigo(
    $input: UpdateMesaTestigoInput!
    $condition: ModelMesaTestigoConditionInput
  ) {
    updateMesaTestigo(input: $input, condition: $condition) {
      id
      operativo
      seccionElectoral
      partido
      circuitoElectoral
      nombreEscuela
      codigoEscuela
      mesa
      fiscalizador
      cantidadPersonasPadron
      cantidadPersonasVotaron
      lastSyncPersonasVotaron
      expansor
      cerrada
      cargoCallCenter
      escrutinioPresidenteSubido
      escrutinioGobernadorSubido
      escrutinioIntendenteSubido
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMesaTestigo = /* GraphQL */ `
  mutation DeleteMesaTestigo(
    $input: DeleteMesaTestigoInput!
    $condition: ModelMesaTestigoConditionInput
  ) {
    deleteMesaTestigo(input: $input, condition: $condition) {
      id
      operativo
      seccionElectoral
      partido
      circuitoElectoral
      nombreEscuela
      codigoEscuela
      mesa
      fiscalizador
      cantidadPersonasPadron
      cantidadPersonasVotaron
      lastSyncPersonasVotaron
      expansor
      cerrada
      cargoCallCenter
      escrutinioPresidenteSubido
      escrutinioGobernadorSubido
      escrutinioIntendenteSubido
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEscrutinio = /* GraphQL */ `
  mutation CreateEscrutinio(
    $input: CreateEscrutinioInput!
    $condition: ModelEscrutinioConditionInput
  ) {
    createEscrutinio(input: $input, condition: $condition) {
      id
      operativo
      seccionElectoral
      partido
      circuitoElectoral
      nombreEscuela
      codigoEscuela
      mesa
      fiscalizador
      tipo
      lista
      codigoLista
      candidato
      fuerzaPolitica
      votos
      votosExpandidos
      expansor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEscrutinio = /* GraphQL */ `
  mutation UpdateEscrutinio(
    $input: UpdateEscrutinioInput!
    $condition: ModelEscrutinioConditionInput
  ) {
    updateEscrutinio(input: $input, condition: $condition) {
      id
      operativo
      seccionElectoral
      partido
      circuitoElectoral
      nombreEscuela
      codigoEscuela
      mesa
      fiscalizador
      tipo
      lista
      codigoLista
      candidato
      fuerzaPolitica
      votos
      votosExpandidos
      expansor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEscrutinio = /* GraphQL */ `
  mutation DeleteEscrutinio(
    $input: DeleteEscrutinioInput!
    $condition: ModelEscrutinioConditionInput
  ) {
    deleteEscrutinio(input: $input, condition: $condition) {
      id
      operativo
      seccionElectoral
      partido
      circuitoElectoral
      nombreEscuela
      codigoEscuela
      mesa
      fiscalizador
      tipo
      lista
      codigoLista
      candidato
      fuerzaPolitica
      votos
      votosExpandidos
      expansor
      createdAt
      updatedAt
      __typename
    }
  }
`;
