import { Container, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../components/salidas/boca-urna/muestrales/Header';
import NavigationDrawer from '../components/shared/navigation-drawer/NavigationDrawer';
import { useDispatch } from 'react-redux';
import { showAlert, toggleLoader } from '../store/slices/sharedSlice';
import MuestralesEscuelas from '../components/salidas/boca-urna/muestrales/MuestralesEscuela';
import { SalidasApi } from '../apis/SalidasApi';


const SalidaMuestrales = () => {
    const dispatch = useDispatch();
    const [lista, setLista] = useState('presidente');
    const [muestreoData, setMuestreoData] = useState([]);

    useEffect(() => {
        dispatch(toggleLoader({ isLoading: true }));
        SalidasApi.getEncuestasLista(lista)
            .then((result) => {
                const items = result.data?.listEncuestaBocaUrnas.items;
                setMuestreoData(items);
            })
            .catch((err) => {
                dispatch(showAlert({
                    message: `${err}`,
                    severity: "error",
                }));
            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }));
            });
    }, [lista]);

    return (
        <NavigationDrawer
            content={
                <Container
                    maxWidth='md'
                    sx={{
                        marginY: 2,
                        marginX: "auto",
                    }}>
                    <Stack direction={'column'} spacing={4}>
                        <Header  onChangeLista={setLista}/>                        
                        <MuestralesEscuelas lista={lista} data={muestreoData} />
                    </Stack>
                </Container>
            }
        />
    )
}

export default SalidaMuestrales;