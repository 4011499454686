import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, IconButton, Stack, Typography } from '@mui/material'
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ROLES, MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS, TIPOS_OPERATIVOS } from '../../constants/AppConstants';
import { showAlert, toggleLoader } from '../../store/slices/sharedSlice';
import { MesaTestigoApi } from '../../apis/MesaTestigoApi';
import { mesaCerrada } from '../../store/slices/MesaTestigoSlice';
import { escrutinioFinalizado } from '../../store/slices/EscrutinioSlice';
import DialogIconActions from '../shared/DialogIconActions';

const CerrarMesa = () => {
    const dispatch = useDispatch();
    const { rol } = useSelector((state) => state.account.user);
    const { id: mesaId, operativo, cerrada } = useSelector((state) => state.mesaTestigo);
    const { estatusEscrutinio } = useSelector((state) => state.escrutinio)
    const [canSubmit, setCanSubmit] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        let canClose = false
        switch (operativo) {
            case TIPOS_OPERATIVOS.LANUS.value:
                ///debe haber completado los 3 escrutinios
                canClose = (Object.entries(estatusEscrutinio).every(([key, status]) => status === MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.COMPLETADO));
                break;
            case TIPOS_OPERATIVOS.PROVINCIA_BA.value:
                //solo presidente y gobernador
                const requiredForApproval = Object.entries(estatusEscrutinio).filter(([key, value]) => key !== "escrutinioIntendente");
                ///para determinar "canClose", y debido a que filtré intendente, necesito llamar a "Object.values" en vez de "Object.entries"
                canClose = (Object.values(requiredForApproval).every(([key, status]) => status === MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.COMPLETADO));
                break;
            default:
                canClose = false;
                break;
        }
        setCanSubmit(canClose);
    }, [operativo, estatusEscrutinio]);

    const handleOnClick = () => {
        if (!canSubmit) {
            dispatch(
                showAlert({
                    message: `Para cerrar la mesa, primero debe completar y enviar todos los formularios.`,
                    severity: "warning",
                }));
            return;
        }
        setIsOpen(true)
    };

    const handleClickConfirm = () => {
        dispatch(toggleLoader({ isLoading: true }))
        const cargoCallCenter = (rol === APP_ROLES.CALL_CENTER_LANUS || rol === APP_ROLES.CALL_CENTER_PROV) ? true : false
        MesaTestigoApi.cerrarMesa(mesaId, cargoCallCenter)
            .then((success) => {
                setCanSubmit(false);
                setIsOpen(false);
                dispatch(mesaCerrada());
                dispatch(escrutinioFinalizado())
                dispatch(
                    showAlert({
                        message: `Mesa cerrada correctamente.`,
                        severity: "success",
                    }));
            })
            .catch((err) => {
                dispatch(
                    showAlert({
                        message: `No ha sido posible cerrar la mesa. Intente nuevamente`,
                        severity: "error",
                    }));
            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }))
            })
    }

    if (cerrada) return (<></>)

    return (
        <>
            <DialogIconActions
                open={isOpen}
                cancelText={"Cancelar"}
                confirmText={"Finalizar"}
                content={`¿Desea finalizar su fiscalización? Todos los datos de la mesa serán enviados y no podrán editarse.`}
                handleCancel={() => setIsOpen(false)}
                handleConfirm={handleClickConfirm}
                icon={<SendAndArchiveIcon sx={{ color: "#7A75A0" }} />}
                title={"Finalizar y cerrar mesa"}
            />

            <Card
                elevation={0}
                sx={{
                    borderRadius: "12px",
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.main,
                    marginTop: 4,
                    // position: 'absolute',
                    bottom: '28px',
                    right: '16px',
                    left: '16px',
                    // maxWidth:'444px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
                onClick={handleOnClick}
            >
                <CardContent sx={{ '&:last-child': { pb: "16px" } }}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant='h3' color={(theme) => theme.palette.primary.contrastText}>
                            Cerrar mesa
                        </Typography>
                        <Box sx={{
                            borderRadius: "12px",
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <IconButton color='secondary' size='large'>
                                <SendAndArchiveIcon />
                            </IconButton>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </>
    )
}

export default CerrarMesa