import { Container, Stack, Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import ResetSurveyButton from '../../components/boca-urna/ResetSurveyButton';
import GoBackButton from '../../components/boca-urna/GoBackButton';

const FinishEncuesta = () => {
    const encuestasRealizadas = useSelector((state) => state.bocaUrna.encuestasRealizadas);

    return (
        <Container
            maxWidth='xs'
            sx={{
                paddingY: 4,
                height: "100vh",
                background: (theme) => theme.palette.primary.main,
            }}
        >
            <Stack height={1} direction={'column'} spacing={2} justifyContent={'space-between'}>
                <Stack spacing={3}>
                    <Typography variant='h1' color={(theme) => theme.palette.primary.contrastText}>
                        Encuesta [{encuestasRealizadas - 1}] finalizada
                    </Typography>

                    <Typography variant='h4' color={(theme) => theme.palette.primary.contrastText}>
                        ¡La encuesta fue enviada!
                    </Typography>

                    <Typography variant='h4' color={(theme) => theme.palette.primary.contrastText}>
                        ¿Querés volver a inicio o generar <br /> una <span style={{ color: "#E0E4B4" }}>nueva encuesta</span>?
                    </Typography>
                </Stack>

                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                    <GoBackButton />
                    <ResetSurveyButton />
                </Stack>
            </Stack>
        </Container>
    )
}

export default FinishEncuesta;