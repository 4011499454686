import React from 'react'
import { Button, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ProfileAvatarIcon from '../shared/icons/ProfileAvatarIcon'
import LogoutIcon from '@mui/icons-material/Logout';
import { Auth } from 'aws-amplify';
import { showAlert } from '../../store/slices/sharedSlice';
import { Box } from '@mui/system';

const Header = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.account.user);
    const { nombre, apellido } = user;

    const handleOnClickLogOut = async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            dispatch(showAlert({
                message: `Hubo un error. Por favor, vuelva a intentar.`,
                severity: "error",
            }));
        }
    };

    return (
        <Box className="ancho-celular">
        <Stack spacing={2} direction="row">
            <ProfileAvatarIcon />
            <Stack justifyContent="flex-start"
                        alignItems="flex-start" direction={"column"}>
                <Typography variant='h3' sx={{ color: (theme) => theme.palette.primary.main }}>
                    {nombre || "Usuario"} {apellido}
                </Typography>
                <Button size='small' variant="text" endIcon={<LogoutIcon />} sx={{ color: (theme) => theme.palette.cta.main }} onClick={handleOnClickLogOut}>
                    Cerrar sesión
                </Button>
            </Stack>
        </Stack>
        </Box>
    )
}

export default Header;