import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS } from "../constants/AppConstants";
import { mockEscrutinioByFiscalizador_dummy } from "../mocks/graphql/EscrutrinioApiMock";

export const EscrutinioApi = {
    sendEscrutinio: async (status, mesaAsignada, fiscalizador, tipo, escrutinio) => {
        if (!mesaAsignada || !fiscalizador || !escrutinio) return;

        let results = [];

        await escrutinio.forEach(async esc => {
            let variables = {
                ...mesaAsignada,
                tipo,
                fiscalizador,
                ...esc,
            };

            if (status === MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.SIN_EMPEZAR) {
                const result = await API.graphql(
                    graphqlOperation(mutations.createEscrutinio, { input: variables })
                );

                results.push(result);
            }
            else {
                delete variables.__typename;
                delete variables.createdAt;
                delete variables.updatedAt;
                const result = await API.graphql(
                    graphqlOperation(mutations.updateEscrutinio, { input: variables })
                );

                results.push(result);

            }
        });

        return results;
    },
    getEscrutinioPorFiscalizador: async (fiscalizador, filters) => {
        if (!fiscalizador) return;
        // if(process.env.NODE_ENV === "development") return mockEscrutinioByFiscalizador_dummy;

        const { tipo, codigoEscuela, operativo, } = filters;
        const variables = {
            fiscalizador: fiscalizador,
            filter: {
                tipo: { eq: tipo },
                codigoEscuela: { eq: codigoEscuela },
                operativo: { eq: operativo }
            }
        };

        const result = await API.graphql(
            graphqlOperation(queries.escrutiniosByFiscalizador, variables)
        )

        return result;
    }
}