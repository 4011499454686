import { Box, Button, MenuItem, Modal, Stack, TextField, Typography } from "@mui/material";
import *  as React from 'react';
import { Form } from "react-router-dom";
import { SeguimientoApi } from "../../apis/SeguimientoApi";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { TIPOS_OPERATIVOS } from "../../constants/AppConstants";
import { PersonalApi } from "../../apis/PersonalApi";
import { ESCUELAS_LANUS } from "../../constants/Escuelas";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  //border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const EditUsersModal = ({ open, onClose, row}) => {

 
  const EscuelasListado = Object.values(ESCUELAS_LANUS);
  const [escuela, setEscuela] = useState(row.codigoEscuelaAsignada);

  
  const Operativos = Object.values(TIPOS_OPERATIVOS);
  const [operativo, setOperativo] = useState(TIPOS_OPERATIVOS.LANUS);
  const dispatch = useDispatch();

  const handleOnSubmit = async () => 
  {
    try
    {
      
      const escuelaNew = EscuelasListado.find(e => e.codigoEscuela === escuela);
      const datosAEditarPersonal = 
      {
        id:row.id,
        nickname: row.nickname,
        rol: row.rol,
        codigoEscuelaAsignada:  escuelaNew.codigoEscuela,
        nombreEscuelaAsignada: escuelaNew.nombreEscuela,
        circuitoElectoralAsignado: escuelaNew.circuitoElectoral,

      };

      const datosAEditarSegumiento = 
      {
        encuestador: row.nickname,
        operativo: operativo.value,
        codigoEscuela:  escuelaNew.codigoEscuela,
        circuitoElectoral: escuelaNew.circuitoElectoral,
        escuela: escuelaNew.nombreEscuela
      };


      dispatch(toggleLoader({ isLoading: true }));
      
      SeguimientoApi.UpdateEscuelaAsignada(datosAEditarSegumiento);
      PersonalApi.updatePersonal(datosAEditarPersonal);

      dispatch(
        showAlert({
            message: `Escuela editada correctamente.`,
            severity: "success",
        })
    );

      onClose();
    }
    catch (error) {
              console.log('error signing up', error);
              dispatch(
                  showAlert({
                      message: `${error}`,
                      severity: "error",
                  })
              );
      
              dispatch(toggleLoader({ isLoading: false }));
          }

          dispatch(toggleLoader({ isLoading: false }));
  };

  const handleChange = async (event) =>
  {
    setEscuela(event.target.value);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Modificar Usuario
        </Typography>
        
        <Form onSubmit={handleOnSubmit}>
        <Stack direction={'column'} spacing={3}>
          <TextField id="outlined-basic" name="nickaname" disabled  label="nickname" variant="outlined" value={row.nickname} />
          <TextField id="outlined-basic" name="rol" disabled  label="Rol" variant="outlined" value={row.rol} />

          <TextField
            select
            name="operativos"
            label="Operativos"
            required
            value={operativo}
            onChange={(e) => setOperativo(e.target.value)}
            variant="outlined"
          >
            {Operativos.map((option) => (
              <MenuItem key={option.value} value={option}>
                {option.descripcion}
              </MenuItem>
            ))}
          </TextField>

        <TextField
            select
            name="escuelas"
            label="Escuelas"
            required
            value={escuela}
            onChange= {(ev) => handleChange(ev)}
            variant="outlined"
          >
            {EscuelasListado.map((option) => (
              <MenuItem key={option.codigoEscuela} value={option.codigoEscuela}>
                {option.nombreEscuela}
              </MenuItem>
            ))}
          </TextField>

          <Button variant="contained" color="primary" onClick={handleOnSubmit}>Guardar</Button>
          <Button variant="contained" color="secondary" onClick={onClose}>Cancelar</Button>
          </Stack>
        </Form>



      </Box>
    </Modal>

  )
}

export default EditUsersModal;