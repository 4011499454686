import { Divider, ListItem, ListItemText, TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { splitLista } from '../../utils/CommonUtils';
import { conteoRecibido } from '../../store/slices/EscrutinioSlice';
import { showAlert } from '../../store/slices/sharedSlice';

const ListItemEscrutinioTotal = ({ candidatura, canSubmit, toggleCanSubmit }) => {
    const dispatch = useDispatch();
    const { cantidadPersonasVotaron, expansor } = useSelector((state) => state.mesaTestigo);
    const { escrutinioPresidente, escrutinioGobernador, escrutinioIntendente } = useSelector((state) => state.escrutinio);

    const totalEscrutinio = useMemo(() => {
        // if (!escrutinio) return 0;

        let entries = [];
        switch (candidatura.tipo) {
            case "escrutinioPresidente":
                entries = Object.entries(escrutinioPresidente);
                break;
            case "escrutinioGobernador":
                entries = Object.entries(escrutinioGobernador);
                break;
            case "escrutinioIntendente":
                entries = Object.entries(escrutinioIntendente);
                break;
        }

        const _sum = entries.reduce((accumulator, [key, value]) => accumulator += value.votos, 0);
        return _sum;
    }, [candidatura, escrutinioPresidente, escrutinioGobernador, escrutinioIntendente]);

    useEffect(() => {
        if(!canSubmit) return; //no se ingresó la cantidad de votos de alguna lista
        // if(!itemOtros) return;
        ////deprecated la categoria "Otros candidatoes"
        //la cantidad de votos para "Otros candidatos" se calcula haciendo: cantidadPersonasVotaron - totalEscrutinio
        //si el resultado < 0 -> error, pues "Otros" estaría dando negativo

        //la suma del escrutinio no puede superar al total de personas que votaron.
        const votosOtros = cantidadPersonasVotaron - totalEscrutinio;
        if(votosOtros < 0 )
        {
            dispatch(showAlert({
                message: "El escrutinio supera a la cantidad de personas que emitieron su voto.",
                severity: "warning"
            }));
            //deshabilitar boton de "enviar resultados"
            toggleCanSubmit(false)
            return;
        }

        // const { codigo, candidato, partido } = splitLista(itemOtros.lista);
        // const tipo = "escrutinioOtros";
        // const lista = itemOtros.lista
        // const _voto = (votosOtros) ? parseInt(votosOtros) : 0;
        // const _votoExpandido = (votosOtros) ? parseInt(votosOtros * expansor) : 0;
        // const _payload = { tipo, lista, codigoLista: codigo, candidato, fuerzaPolitica: partido, votos: _voto, votosExpandidos: _votoExpandido };

        // dispatch(conteoRecibido(_payload));
        toggleCanSubmit(true)
    }, [totalEscrutinio]);

    return (
        <>
            <ListItem>
                <ListItemText primary={"Suma total"} sx={{ marginRight: '14px' }} />
                <TextField variant='outlined' sx={{ maxWidth: "80px" }} color='primary' inputProps={{ style: { textAlign: "center" } }} value={totalEscrutinio} disabled />
            </ListItem>
            <Divider variant='middle' />
        </>
    )
}

export default ListItemEscrutinioTotal;