import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/AppConstants';
import { useSelector } from 'react-redux';

const NewSurveyButton = ({ }) => {
  const { operativo } = useSelector((state) => state.bocaUrna);
  const navigate = useNavigate();

  const [showNewSurveyButton, setShowNewSurveyButton] = useState(false)

  useEffect(() => {
    (!operativo) ? setShowNewSurveyButton(false) : setShowNewSurveyButton(true)
  }, [operativo])

  const handleOnClick = () => {
    //navigate(`${APP_ROUTES.BOCA_DE_URNA}${APP_ROUTES.BOCA_DE_URNA_ENCUESTA}`)
    navigate(`${APP_ROUTES.BOCA_DE_URNA_ENCUESTA}`)

  };

  if (!showNewSurveyButton) return (<></>);

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: "12px",
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.primary.main,
        marginTop: 4,
        // position: 'absolute',
        bottom: '28px',
        right: '16px',
        left: '16px',
        maxWidth: '444px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      onClick={handleOnClick}
    >
      <CardContent sx={{ '&:last-child': { pb: "16px" } }}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography fontFamily={"Chivo"} fontWeight={"500"} fontSize={"1.1rem"}>
            Nueva encuesta
          </Typography>
          {/* este boton capaz se puede reemplazar con un SVG exportado de Figma. no encontré un componente en material UI que quede igual a la maqueta */}
          <Box sx={{
            borderRadius: "12px",
            backgroundColor: (theme) => theme.palette.primary.main,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <IconButton color='secondary' size='large'>
              <AddIcon />
            </IconButton>
          </Box>
        </Stack>

      </CardContent>
    </Card>
  )
}

export default NewSurveyButton;