import { configureStore } from "@reduxjs/toolkit";
import shared from "./slices/sharedSlice";
import account from "./slices/AccountSlice";
import bocaUrna from "./slices/BocaUrnaSlice";
import mesaTestigo from "./slices/MesaTestigoSlice";
import escrutinio from "./slices/EscrutinioSlice";

export default configureStore({
    reducer: {
        shared,
        account,
        bocaUrna,
        mesaTestigo,
        escrutinio,
    },
});