import { Divider, ListItem, ListItemText, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { splitLista } from '../../utils/CommonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { conteoRecibido } from '../../store/slices/EscrutinioSlice';

function ListItemCandidato({ tipo, lista, votos, toggleCanSubmit }) {
    const dispatch = useDispatch();
    const { expansor } = useSelector((state) => state.mesaTestigo);
    const [voto, setVoto] = useState(votos);
    const [helperText, setHelperText] = useState('');
    const { codigo, candidato, partido } = splitLista(lista);

    const handleOnChangeVotos = (ev) => {
        const _value = ev.target.value;
        if (_value === "") {
            setHelperText("*Requerido")
            toggleCanSubmit(false);
        }
        else {
            setHelperText(null);
            toggleCanSubmit(true);
        }

        setVoto(_value);
    };

    useEffect(() => {
        const _voto = (voto) ? parseInt(voto) : 0;
        const _votoExpandido = (voto) ? parseInt(voto * expansor) : 0;
        const _payload = { tipo, lista, codigoLista: codigo, candidato, fuerzaPolitica: partido, votos: _voto, votosExpandidos: _votoExpandido };
        dispatch(conteoRecibido(_payload));
    }, [voto]);

    return (
        <>
            <ListItem>
                <ListItemText primary={`${candidato} (${partido})`} sx={{ marginRight: '14px' }} />
                <TextField type='number' variant='outlined' sx={{ maxWidth: "80px" }} color='primary' inputProps={{ min: 0, style: { textAlign: "center" } }} value={voto} onChange={handleOnChangeVotos} helperText={helperText} error={!!helperText} />
            </ListItem>
            <Divider variant='middle' />
        </>
    );
}
// inputProps={{ inputMode: "numeric", min: 0, pattern: "[0-9]*" }} 
export default ListItemCandidato;