import Button from "@mui/material/Button";

const ContainedButton = ({ label, ...props }) => {

    return (
        <Button
            sx={{
                height: "40px",
                width: 'fit-content',
                textTransform: "capitalize"
            }}
            variant="contained"
            {...props}
        >
            {label}
        </Button>
    );
};

export default ContainedButton;