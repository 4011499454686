import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    id: "",
    operativo: "",
    seccionElectoral: "",
    partido: "",
    circuitoElectoral: "",
    nombreEscuela: "",
    codigoEscuela: "",
    mesa: "",
    cantidadPersonasPadron: 0,
    cantidadPersonasVotaron: 0,
    lastSyncPersonasVotaron: "",
    expansor: 0,
    cerrada: false,
    mesasCallCenter: [],
}
export const MesaTestigoSlice = createSlice({
    name: "mesaTestigo",
    initialState: INITIAL_STATE,
    reducers: {
        mesaAsignada: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        participacionActualizada: (state, action) => {
            return {
                ...state,
                cantidadPersonasPadron: action.payload,
            }
        },
        cantidadPadronActualizado: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        mesaCerrada: (state, action) => {
            return {
                ...state,
                cerrada: true,
            }
        },
        mesaReseteada: (state, action) => {
            return {
                ...state,
                ...INITIAL_STATE,
            }

        },
        mesasRecibidas: (state, action) => {
            return {
                ...state,
                mesasCallCenter: [...action.payload]
            }
        }
    }
});

export const { mesaAsignada, participacionActualizada, cantidadPadronActualizado, mesaCerrada, mesaReseteada, mesasRecibidas } = MesaTestigoSlice.actions;

export default MesaTestigoSlice.reducer;