import React, { } from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate } from "react-router-dom";
import RouteProtected from "./RouteProtected";
import { APP_ROUTES } from "../constants/AppConstants";
import Login from "../pages/Login";
import { useSelector } from "react-redux";
import SignUp from "../pages/SignUp";
import HomeBocaDeUrna from "../pages/boca-urna/HomeBocaDeUrna";
import StatusOperativo from "../pages/StatusOperativo";
import IsAuthenticated from "./loaders/IsAuthenticated";
import Encuesta from "../pages/boca-urna/Encuesta";
import FinishEncuesta from "../pages/boca-urna/EncuestaFinish";
import SalidaMuestrales from "../pages/SalidaMuestrales";
import HomeMesaTestigo from "../pages/mesa-testigo/HomeMesaTestigo";
import EscrutinioMesa from "../pages/mesa-testigo/EscrutinioMesa";
import ParticipacionMesa from "../pages/mesa-testigo/ParticipacionMesa";
import CantidadPadronMesa from "../pages/mesa-testigo/CantidadPadronMesa";
import Users from "../pages/Users";

const RouteConfig = ({ }) => {
  const user = useSelector((state) => state.account.user);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path={`${APP_ROUTES.LOGIN}`}
          element={<Login />}
        />
        <Route
          path={`${APP_ROUTES.SIGN_UP}`}
          element={<SignUp />}
        />
        {/* add authorization logic for 'authorized' attribute */}
        <Route
          path={`${APP_ROUTES.BOCA_DE_URNA}`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<HomeBocaDeUrna />}
            />
          }
        />
        <Route
          path={`${APP_ROUTES.MESA_TESTIGO}`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<HomeMesaTestigo />}
            />
          }
        />
        <Route
          path={`${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CONTEO}/:_candidatura`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<EscrutinioMesa />}
            />
          }
        />
        <Route
          path={`${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CANT_PADRON}`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<CantidadPadronMesa />}
            />
          }
        />
        <Route
          path={`${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CANT_PARTICIPACION}`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<ParticipacionMesa />}
            />
          }
        />
        <Route
          path={`${APP_ROUTES.STATUS_OPERATIVO}`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<StatusOperativo />}
            />
          }
        />,
        <Route
          path={`${APP_ROUTES.BOCA_DE_URNA_ENCUESTA}`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<Encuesta />}
            />
          }
        />,
        <Route
          path={`${APP_ROUTES.FINISH_BOCA_DE_URNA_ENCUESTA}`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<FinishEncuesta />}
            />
          }
        />
        <Route
          path={`${APP_ROUTES.MUESTRALES}`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<SalidaMuestrales />}
            />
          }
        />
          <Route
          path={`${APP_ROUTES.USUARIOS}`}
          loader={IsAuthenticated}
          element={
            <RouteProtected
              authorized={user.authorized || true}
              Component={<Users />}
            />
          }
        />
        <Route
          path={`${APP_ROUTES.HOME}`}
          element={<Navigate to={`${APP_ROUTES.LOGIN}`} replace={true} />}
        />
      </>
    ), {
    basename: "", //here, we'll able to set the root path based on the environment we are deploying our web app
  });

  return (
    <RouterProvider router={router} />
  );
};

export default RouteConfig;
