import React, { useEffect, useState } from 'react'
import { Container, IconButton, Stack, TextField, Typography } from '@mui/material'
import OutlinedButton from '../../components/shared/OutlinedButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContainedButton from '../../components/shared/ContainedButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert, toggleLoader } from '../../store/slices/sharedSlice';
import { MesaTestigoApi } from '../../apis/MesaTestigoApi';
import { cantidadPadronActualizado } from '../../store/slices/MesaTestigoSlice';
import { isDecimal } from '../../utils/CommonUtils';

const CantidadPadronMesa = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, cantidadPersonasPadron } = useSelector((state) => state.mesaTestigo);
    const [cantidad, setCantidad] = useState(cantidadPersonasPadron ?? 0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        //if id is null or undefined, evitar cualquier accion
        if (!id) {
            dispatch(showAlert({
                message: `No se ha seleccionado una mesa. Por favor, regrese a la página anterior`,
                severity: "warning",
            }));
            setHasError(true);
            return;
        }
    }, []);

    const handleOnChange = (ev) => {
        const _value = ev.target.value;
        setCantidad(_value);

        if (!_value) { setHasError(true); setErrorMessage("Este campo es obligatorio."); return; }
        if (_value < 0) { setHasError(true); setErrorMessage("Ingrese un número mayor a 0."); return; }
        if (isDecimal(_value)) { setHasError(true); setErrorMessage("Ingrese solo números enteros."); return; }

        setErrorMessage(null);
        setHasError(false);
    }

    const handleOnClickCancel = () => {
        navigate(-1);
    }

    const handleOnClickSubmit = () => {
        dispatch(toggleLoader({ isLoading: true }));
        const _status = {
            id: id,
            cantidadPersonasPadron: parseInt(cantidad)
        }
        MesaTestigoApi.updateStatusPadron(_status)
            .then((updated) => {
                dispatch(showAlert({
                    message: `El total de personas habilitadas para votar ha sido actualizado.`,
                    severity: "success",
                }));

                dispatch(cantidadPadronActualizado(_status.cantidadPersonasPadron));
                navigate(-1);
            })
            .catch((err) => {
                dispatch(showAlert({
                    message: `${err.errors[0].message}`,
                    severity: "error",
                }));

            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }));
            })
    }

    return (
        <Container maxWidth='xs' sx={{ paddingY: 4 }}>
            <Stack direction={'column'} spacing={5} >
                <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'start'}>
                    <IconButton color='info' size='medium' onClick={handleOnClickCancel}>
                        <ArrowBackIcon />
                    </IconButton>

                    <Typography variant='h3' color={(theme) => theme.palette.primary.main}>
                        Editar número de personas habilitadas para votar
                    </Typography>
                </Stack>

                <TextField type='number' inputMode='numeric' variant='outlined' color='primary' label='Personas habilitadas para votar' value={cantidad} onChange={handleOnChange} inputProps={{ min: 0, step: 1 }} required error={hasError} helperText={errorMessage} />

                <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                    <OutlinedButton label={"Cancelar"} color='primary' onClick={handleOnClickCancel} />
                    <ContainedButton
                        label={"Editar"} color='primary' onClick={handleOnClickSubmit} disabled={hasError} />
                </Stack>
            </Stack>
        </Container>
    )
}

export default CantidadPadronMesa