import { Card, CardContent, Typography } from '@mui/material';
import { textAlign } from '@mui/system';
import React from 'react'

const CardIndicador = ({ titulo, valor, ultimaSincronizacion }) => {
    // alignItems={'center'} justifyContent={'space-between'}
    return (
        <Card
            variant="outlined"
            sx={{
                width: "277px",
                height: '195px',
                borderRadius: "12px",
                display: "flex",
                borderColor: "#BCD0B2",
                alignItems: 'start',
                justifyContent: 'center',
                textAlign: 'center',
            }}
        >
            <CardContent sx={{ '&:last-child': { pb: "16px" }, marginBottom: 'auto', marginTop:'auto', }}>
                <Typography variant='h1' color={(theme) => theme.palette.primary.main}>
                    {valor?.toLocaleString('es-AR', { minimumIntegerDigits: 3 }) ?? "-"}
                </Typography>
                <Typography variant='subtitle1' color={(theme) => theme.palette.primary.main}>
                    {titulo}
                </Typography>
            </CardContent>
            {
                    (ultimaSincronizacion) && <Typography variant='caption' color={"#9E9E9E"} position={'absolute'} paddingTop={'135px'}>
                        Última sincronización {new Date(ultimaSincronizacion).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false })}hs
                    </Typography>
                }
        </Card>
    )
}

export default CardIndicador;