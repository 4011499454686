/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "sa-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://fuzmbhxukj.execute-api.sa-east-1.amazonaws.com/main",
            "region": "sa-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vqyqon7fkrh7zbag3hdiixd3o4.appsync-api.sa-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "sa-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "sa-east-1:04fd7385-1d8d-489a-8f31-eca48489720b",
    "aws_cognito_region": "sa-east-1",
    "aws_user_pools_id": "sa-east-1_jWyOf2pze",
    "aws_user_pools_web_client_id": "5riu962snvtf9k16slugm9hbdb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
