import React, { useEffect, useRef, useState } from 'react'
import { Box, Fade, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { PersonalApi } from '../../apis/PersonalApi';
import { APP_ROLES } from '../../constants/AppConstants';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../store/slices/sharedSlice';
import SkeletonTitleTable from '../shared/skeletons/SkeletonTitleTable';

const SeguimientoOperativoEncuestador = ({ operativoId, data }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [dataSeguimiento, setDataSeguimiento] = useState([]);

    useEffect(() => {
        if(!data || !operativoId) return;
        setIsLoading(true);
        PersonalApi.getPersonalByRol(APP_ROLES.ENCUESTADOR, operativoId)
            .then((result) => {
                const encuestadores = result.data?.personalsByRol.items;

                const encuestadoresRealizaron = encuestadores.map(enc => {
                    return {
                        nombreEncuestador: enc.nickname,
                        circuitoElectoral: enc.circuitoElectoralAsignado ?? "-",
                        nombreEscuela: enc.nombreEscuelaAsignada ?? "-",
                        realizo: data.find(item => item.encuestador === enc.nickname)?.cantidadEncuestas ?? 0
                    }
                })

                setDataSeguimiento(encuestadoresRealizaron);
                setDataSeguimientoFiltered(encuestadoresRealizaron);
            }).catch((err) => {
                dispatch(showAlert({
                    message: `${err.errors[0]?.message}`,
                    severity: "error",
                }));
            }).finally(() => {
                setIsLoading(false);
            });
    }, [operativoId, data]);

    //###### INICIO - para la tabla de siguimiento
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [dataSeguimientoFiltered, setDataSeguimientoFiltered] = useState([]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataSeguimiento.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearchInput = (event) => {
        
        if (!event.target.value) setDataSeguimientoFiltered(dataSeguimiento);
        const searchTerm = event.target.value.trim().toLowerCase();
        const dataFiltrada = dataSeguimiento.filter(ds => ds.nombreEncuestador.toLowerCase().includes(searchTerm));
   
        setDataSeguimientoFiltered(dataFiltrada);
    }
    //###### FIN - para la tabla de siguimiento

    const [checked, setChecked] = useState(false);
    const containerRef = useRef(null);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };



    if (isLoading) return <SkeletonTitleTable />;

    const inputSearch = (
        <TextField
            placeholder='Filtrar encuestador/a'
            size='small'
            color='primary'
            variant="standard"
            onChange={(ev) => handleChangeSearchInput(ev) }
        />
    );


    return (
        <Stack direction={'column'} spacing={2}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='h4' color={(theme) => theme.palette.primary.main}>
                    Seguimiento operativo
                </Typography>
                <Box container={containerRef.current}>
                <Fade direction="left" in={checked}>
                    {inputSearch}
                </Fade>
                <IconButton aria-label="search" color='primary'
                    onClick={handleChange} ref={containerRef}>
                    <SearchIcon />
                </IconButton>
                </Box>
            </Stack>


            <TableContainer component={Box}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Encuestador/a</TableCell>
                            <TableCell align="left">Circuito</TableCell>
                            <TableCell align="left">Escuela asignada</TableCell>
                            <TableCell align="left">Realizó</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? dataSeguimientoFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : dataSeguimientoFiltered
                        ).map((row) => (
                            <TableRow key={`row-${row.nombreEncuestador}-${row.nombreEscuela}`}>
                                <TableCell component="th" scope="row">
                                    {row.nombreEncuestador}
                                </TableCell>
                                <TableCell align="left">
                                    {row.circuitoElectoral}
                                </TableCell>
                                <TableCell align="left">
                                    {row.nombreEscuela}
                                </TableCell>
                                <TableCell align="left">
                                    {row.realizo} encuestas
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={4} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage={"Resultados por página"}
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                // colSpan={3}
                                count={dataSeguimiento.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Stack>
    )

}



export default SeguimientoOperativoEncuestador;