export const withClearOption = (options) => {
    return [
        {
            label: <em>Eliminar</em>,
            value: "",
        },
        ...options,
    ];
};

/**
 * Given an email address, checks if it's a valid one or not.
 * @param {string} email E-mail address to validate.
 * @returns `True` if `email` passes the RegEx test
 * @author `RegExp` provided by ChatGPT.
 */
export const validateEmailAddress = (email) => {
    if (!email || email.trim() === "") return true;
    const emailRegex = /^[\w\.-]+@[\w-]+(\.[\w-]+)*\.[a-zA-Z]{2,}$/
    return emailRegex.test(email);
}

export const getDetailsFromUser = (userInfo, session) => {
    const userDetails = {
        username: userInfo.attributes?.sub,
        rol: session.idToken?.payload["cognito:groups"].slice(0, 1)[0] ?? "SIN_ROL",
        nombre: userInfo.attributes?.given_name,
        apellido: userInfo.attributes?.family_name,
        email: userInfo.attributes?.email,
        nickname: userInfo.attributes?.nickname ?? `${userInfo.attributes?.given_name.substring(0, 1)}${userInfo.attributes?.family_name}`.toLocaleLowerCase()
    };
    return userDetails;
}


/**
 * @typedef {Object} RespuestaVoto
 * @property {string} codigo - Cógido de la lista electoral
 * @property {string} candidato - Nombre de los/as principales candidatos/as
 * @property {string} partido - Nombre del partido político
 */

/**
 * Split de la opción seleccionada para pregunta de `voto`s y devuelve el código de la lista, principales candadidatos y nombre del partido
 * @param {string} opcion 
 * @return {RespuestaVoto|null} Devuelve `RespuestaVoto` con los detalles desagregados. Caso contrario, devuelve `null` si el `opcion` es `null`, `undefined` o no coincide con la expresión RegEx.
 */
export const splitLista = (opcion) => {
    if (!opcion) return null;
    const regex = /^(?<codigo>\w+)\s*\|\s*(?<candidato>[^()|-]+(?:\s+-\s+[^()|-]+)*)\s*\((?<partido>[^()]+)\)$/;
    const matched = regex.test(opcion);

    if (!matched) return null;
    const result = regex.exec(opcion);
    return { ...result.groups };
}

export const isDecimal = (str) => {
    // Regular expression for a decimal number
    const decimalRegex = /^[-+]?(\d*\.\d+)$/;

    return decimalRegex.test(str);
}