import { API, Auth, graphqlOperation } from "aws-amplify";
import * as mutations from '../graphql/mutations';
const API_NAME = "AdminQueries";

export const AdminQueriesApi = {
    addUserToGroup: async (username, usergroup) => {
        let path = '/addUserToGroup';
        let init = {
            body: {
                "username": username,
                "groupname": usergroup
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return await API.post(API_NAME, path, init);
    },

    saveUserAsPersonal: async (nickname, rol) => {
        const details = {
            nickname: nickname.trim(),
            rol: rol.trim()
        };

        const result = await API.graphql(graphqlOperation(mutations.createPersonal, { input: details }));
        return result;
    },

    saveUserAsSeguimiento: async (nickname) => {
        const details = {
            encuestador: nickname,
            operativo: " ",
            codigoEscuela: "",
            circuitoElectoral: "",
            escuela: "",
            cantidadEncuestas: 0
        };

        const result = await API.graphql(graphqlOperation(mutations.createSeguimiento, { input: details }));
        return result;
    },
};