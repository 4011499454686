export const mockPersonalByRol_Encuestador_Lanus = {
    "data": {
        "personalsByRol": {
            "items": [
                {
                    "id": "582eff83-d121-457f-8f71-cd66a84a0a5e",
                    "nickname": "jcastro",
                    "rol": "Encuestador",
                    "codigoEscuelaAsignada": "LAN26",
                    "nombreEscuelaAsignada": "INSTITUTO REMEDIOS DE ESCALADA",
                    "circuitoElectoralAsignado": "274",
                    "createdAt": "2023-08-03T12:55:24.086Z",
                    "updatedAt": "2023-08-03T12:55:24.086Z",
                    "__typename": "Personal"
                },
                {
                    "id": "a3ef400a-5203-42d0-bcd9-e67e89c0c9ee",
                    "nickname": "jdonati",
                    "rol": "Encuestador",
                    "codigoEscuelaAsignada": "LAN11",
                    "nombreEscuelaAsignada": "SOC.DE FOMENTO M.M.GUEMES",
                    "circuitoElectoralAsignado": "262",
                    "createdAt": "2023-08-03T13:38:05.944Z",
                    "updatedAt": "2023-08-03T13:38:05.944Z",
                    "__typename": "Personal"
                },
                {
                    "id": "00f5f38b-6c1c-4553-b453-f3b79d21905e",
                    "nickname": "mlago",
                    "rol": "Encuestador",
                    "codigoEscuelaAsignada": "LAN29",
                    "nombreEscuelaAsignada": "COLEGIO JOSE M.ESTRADA",
                    "circuitoElectoralAsignado": "276",
                    "createdAt": "2023-08-03T12:58:37.652Z",
                    "updatedAt": "2023-08-03T12:58:37.652Z",
                    "__typename": "Personal"
                },
                {
                    "id": "a00080fa-f5ad-430a-bafe-a189ed6b43fc",
                    "nickname": "dschiavone",
                    "rol": "Encuestador",
                    "codigoEscuelaAsignada": "LAN16",
                    "nombreEscuelaAsignada": "ESCUELA EP N°8/ESB N°29",
                    "circuitoElectoralAsignado": "271",
                    "createdAt": "2023-08-03T12:56:53.653Z",
                    "updatedAt": "2023-08-03T12:56:53.653Z",
                    "__typename": "Personal"
                }
            ],
            "nextToken": null,
            "__typename": "ModelPersonalConnection"
        }
    }
}

export const mockPersonalByRol_Encuestador_Prov = {
    "data": {
        "personalsByRol": {
            "items": [
            ],
            "nextToken": null,
            "__typename": "ModelPersonalConnection"
        }
    }
}