import { Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'

const SkeletonTitleTable = () => {

    const table = Array.from({ length: 5 }, (item, index) =>
        <Stack direction={'row'} spacing={2}>
            <Skeleton width={"40%"} />
            <Skeleton width={"15%"} />
            <Skeleton width={"20%"} />
            <Skeleton width={"25%"} />
        </Stack>
    );

    return (
        <Stack direction={'column'} spacing={2}>
            <Typography variant='h5'>
                <Skeleton width={"30%"} />
            </Typography>

            {table}
        </Stack>
    )
}

export default SkeletonTitleTable;