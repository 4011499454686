import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { getDetailsFromUser } from "../../utils/CommonUtils";

const USER_INITIAL_STATE = {
    username: "",
    nombre: "",
    apellido: "",
    email: "",
    rol: "",
    nickname: "",
};

export const AccountSlice = createSlice({
    name: "account",
    initialState: {
        user: USER_INITIAL_STATE,
        fiscalizador: '',
    },

    reducers: {
        userLoggedIn: (state, action) => {
            return {
                ...state,
                user: action.payload,
            };
        },
        userLoggedOut: (state, action) => {
            return {
                ...state,
                user: USER_INITIAL_STATE,
                fiscalizador: '',
            };
        },
        fiscalizadorSeleccionado: (state, action) => {
            return {
                ...state,
                fiscalizador: action.payload
            }
        },
    },
});

export const { userLoggedIn, userLoggedOut, fiscalizadorSeleccionado } = AccountSlice.actions;

export default AccountSlice.reducer;

export const getUserInfo = () => async (dispatch) => {
    try {
        const currentInfo = await Auth.currentUserInfo();
        const currentSession = await Auth.currentSession();
        const userDetails = getDetailsFromUser(currentInfo, currentSession);

        dispatch(userLoggedIn(userDetails));
    } catch (error) {
        
    }
};