import { Container, Stack } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Header from '../components/indicadores-operativo/Header';
import CardIndicador from '../components/indicadores-operativo/CardIndicador';
import SeguimientoOperativoEncuestador from '../components/indicadores-operativo/SeguimientoOperativoEncuestador';
import { TIPOS_OPERATIVOS } from '../constants/AppConstants';
import NavigationDrawer from '../components/shared/navigation-drawer/NavigationDrawer';
import { useDispatch } from 'react-redux';
import { showAlert, toggleLoader } from '../store/slices/sharedSlice';
import { SeguimientoApi } from '../apis/SeguimientoApi';
import { ESCUELAS_LANUS } from '../constants/Escuelas';

const INDICADORES = {
    ENCUESTAS_REALIZADAS: {
        value: 0,
        titulo: "Encuestas realizadas",
        ultimaSincronizacion: new Date().toLocaleString(),
    },
    CIRCUITOS_ELECTORALES: {
        value: 0,
        titulo: "Circuitos electorales",
        ultimaSincronizacion: null,
    },
    ESCUELAS_EN_OPERATIVO: {
        value: 0,
        titulo: "Escuelas en operativo",
        ultimaSincronizacion: null,
    },
};

const StatusOperativo = () => {
    const dispatch = useDispatch();
    const [operativo, setOperativo] = useState('');
    const [seguimientoData, setSeguimientoData] = useState(null);
    const [indicadores, setIndicadores] = useState(INDICADORES);
    const [seguimientoNextToken, setSeguimientoNextToken] = useState(null);

    useEffect(() => {
        if (!operativo) return;
        dispatch(toggleLoader({ isLoading: true }));
        SeguimientoApi.getAvanceOperativo(operativo)
            .then((result) => {
                const items = result.data?.seguimientosByOperativo.items;
                setSeguimientoData(items);
                setSeguimientoNextToken(result.data?.seguimientosByOperativo.nextToken);
            })
            .catch((err) => {
                dispatch(showAlert({
                    message: `${err.errors[0]?.message}`,
                    severity: "error",
                }));
            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }));
            });

        return () => {
            //clean-up
            setSeguimientoData(null);
            setSeguimientoNextToken(null);
        }
    }, [operativo]);

    ///effect para continuar con la query si DynamoDB cortó en "nextToken"
    useEffect(() => {
        if (!seguimientoNextToken) return;

        dispatch(toggleLoader({ isLoading: true }));
        SeguimientoApi.getAvanceOperativo(operativo, seguimientoNextToken)
            .then((moreResults) => {
                const items = moreResults.data?.seguimientosByOperativo.items;
                const nextSeguimientoData = [...seguimientoData, ...items];
                setSeguimientoData(nextSeguimientoData);
                setSeguimientoNextToken(moreResults.data?.seguimientosByOperativo.nextToken);
            })
            .catch((err) => {
                dispatch(showAlert({
                    message: `${err.errors[0]?.message}`,
                    severity: "error",
                }));
            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }));
            });
    }, [seguimientoNextToken])

    ///effect para calcular indicadores
    useEffect(() => {
        if (!seguimientoData) {
            setIndicadores(INDICADORES);
            return;
        };

        const totalEncuestas = seguimientoData.reduce((accumulator, item) => accumulator += item.cantidadEncuestas ?? 0, 0);
        ///TODO: agregar logica para manejar el caso de operativo es Provincia
        const cantidadCircuitos = (operativo === TIPOS_OPERATIVOS.LANUS.value) ? new Set(ESCUELAS_LANUS.map(esc => esc.circuitoElectoral)).size : 0
        const cantidadEscuelas = (operativo === TIPOS_OPERATIVOS.LANUS.value) ? ESCUELAS_LANUS.length : 0;
        const cantidadEncuestas = (operativo === TIPOS_OPERATIVOS.LANUS.value) ? totalEncuestas : 0;

        let nextState = { ...indicadores };
        nextState.CIRCUITOS_ELECTORALES.value = cantidadCircuitos;
        nextState.ESCUELAS_EN_OPERATIVO.value = cantidadEscuelas;
        nextState.ENCUESTAS_REALIZADAS.value = cantidadEncuestas;
        nextState.ENCUESTAS_REALIZADAS.ultimaSincronizacion = new Date().toLocaleString();

        setIndicadores(nextState);
    }, [seguimientoData])

    const indicadoresOperativo = useMemo(() => {
        return Object.values(indicadores).map((i, index) => (<CardIndicador key={`card-indicador-${index}`} titulo={i.titulo} valor={i.value} ultimaSincronizacion={i.ultimaSincronizacion} />));
    }, [indicadores]);

    return (
        <NavigationDrawer
            content={
                <Container
                    maxWidth='md'
                    sx={{
                        marginY: 2,
                        marginX: "auto",
                    }}>
                    <Stack direction={'column'} spacing={4}>
                        <Header onChangeOperativo={setOperativo} />

                        <Stack marginTop={4} direction={'row'} spacing={4} alignItems={'center'} justifyContent={'space-between'}>
                            {indicadoresOperativo}
                        </Stack>

                        <SeguimientoOperativoEncuestador operativoId={operativo} data={seguimientoData} />
                    </Stack>
                </Container>
            }
        />
    )
}

export default StatusOperativo;