import React, { useMemo } from 'react'
import { IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS } from '../../constants/AppConstants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../store/slices/sharedSlice';

function ListadoCandidaturasItem({ isLoading, item, status }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const _iconButton = useMemo(() => {
        switch (status) {
            case MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.SIN_EMPEZAR:
                return <IconButton sx={{ color: '#564A7C' }}>
                    <PlaylistAddIcon />
                </IconButton>
            case MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ENVIADO:
                return <IconButton sx={{ color: '#636F41' }}>
                    <CheckCircleOutlineIcon />
                </IconButton>
            case MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.COMPLETADO:
                return <IconButton sx={{ color: '#636F41' }}>
                    <CheckCircleOutlineIcon />
                </IconButton>
            case MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ERROR_SINC:
                return <IconButton sx={{ color: '#636F41' }}>
                    <SyncProblemIcon />
                </IconButton>
            default:
                return <PlaylistAddIcon />
        }
    }, [status]);

    const _backgroundColor = useMemo(() => {
        switch (status) {
            case MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.SIN_EMPEZAR:
                return "#CECEDF";
            case MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ENVIADO:
                return "#ECEFD0";
            case MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.COMPLETADO:
            return "#ECEFD0";
            case MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ERROR_SINC:
                return "#ECEFD0";
            default:
                return "#CECEDF";
        }
    }, [status]);

    const handleOnClick = () => {
        if (status === MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ENVIADO) {
            dispatch(
                showAlert({
                    message: `${item.label} ya han sido enviados correctamente.`,
                    severity: "info",
                })
            );
            return
        };
        navigate(item.linkTo)
    };

    if (isLoading) return (<Skeleton />);

    return (
        <Paper
            variant='elevation'
            elevation={0}
            sx={{
                px: 2,
                py: 2,
                background: _backgroundColor,
            }}
            onClick={handleOnClick}
        >
            <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='body2'>
                    {item.label}
                </Typography>
                {_iconButton}
            </Stack>
        </Paper>
    )
}

export default ListadoCandidaturasItem;