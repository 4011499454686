//import logo from './logo.svg';
import logo from './logo-oe.png';
import './App.css';
import RouteConfig from './routing/RouteConfig';
import AlertSnackbar from './components/shared/AlertSnackbar';
import Loading from './components/shared/Loading';
import { Hub } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { getUserInfo, userLoggedOut } from './store/slices/AccountSlice';
import { useEffect } from 'react';
import { redirect } from 'react-router-dom';
import { APP_ROUTES } from './constants/AppConstants';
import { mesaReseteada } from './store/slices/MesaTestigoSlice';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    /* start listening for "auth" messages */
    const authHubListener = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          dispatch(getUserInfo());
          break;
        case 'signUp':
          break;
        case 'signOut':
          dispatch(userLoggedOut());
          dispatch(mesaReseteada());
          redirect(APP_ROUTES.LOGIN);
          break;
        case 'signIn_failure':
          break;
      }
    });

    dispatch(getUserInfo());

    return () => {
      authHubListener(); //stop listening
    }
  }, []);

  return (
    <div className="App">
      <AlertSnackbar />
      <Loading />
      <RouteConfig />
    </div>
  );
}

export default App;
