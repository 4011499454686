import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';

export const SalidasApi = {
    getEncuestasLista: async () => {

        const result = await API.graphql(
            graphqlOperation(queries.listEncuestaBocaUrnas)
        );

        return result;
    },
}