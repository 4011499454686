import { createSlice } from "@reduxjs/toolkit";

export const BocaUrnaSlice = createSlice({
    name: "bocaUrna",
    initialState: {
        operativo: "",
        circuitoElectoral: "",
        codigoEscuela: "",
        nombreEscuela: "",
        encuestasRealizadas: 0,
        respuestas: null,
    },

    reducers: {
        operativoAsignado: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        encuestasRealizadasActualizado: (state, action) => {
            return {
                ...state,
                encuestasRealizadas: action.payload,
            };
        },
        respuestaRecibida: (state, action) => {
            return {
                ...state,
                respuestas: { ...state.respuestas, ...action.payload }
            };
        },
        respuestaDescartada: (state, action) => {
            let _respuestas = { ...state.respuestas };
            delete _respuestas[action.payload];
            return {
                ...state,
                respuestas: { ..._respuestas }
            };
        },
        encuestaGuardada: (state, action) => {
            //reseteo las respuestas e incremento en una undiad `encuestasRealizadas`
            return {
                ...state,
                encuestasRealizadas: state.encuestasRealizadas + 1,
                respuestas: null
            };
        },
        votoBoletaEntera: (state, action) => {
            const respuestaVotoPresidente = Object.entries(state.respuestas).find(([key, value]) => key === "votoListaPresidente")[1]

            switch (respuestaVotoPresidente) {
                case "Patricia Bullrich - Luis Petri (JxC)":
                    return {
                        ...state,
                        respuestas: {
                            ...state.respuestas,
                            [`votoListaGobernador`]: "Néstor Grindetti - Miguel Fernández (JxC)",
                            [`votoListaIntendente`]: "Diego Kravetz (JxC)",
                        }
                    }
                case "Javier Milei - Victoria Villaroel (La libertad Avanza)":
                    return {
                        ...state,
                        respuestas: {
                            ...state.respuestas,
                            [`votoListaGobernador`]: "Carolina Píparo - Francisco Oneto (La libertad Avanza)",
                            [`votoListaIntendente`]: "Jorge García Zajac (La libertad Avanza)",
                        }
                    }
                case "Sergio Massa - Agustín Rossi (Unión por la Patria)":
                    return {
                        ...state,
                        respuestas: {
                            ...state.respuestas,
                            [`votoListaGobernador`]: "Axel Kicillof - Verónica Magario (Unión por la Patria)",
                            [`votoListaIntendente`]: "Julián Álvarez (Unión por la Patria)",
                        }
                    }
                default:
                    return {
                        ...state,
                        respuestas: {
                            ...state.respuestas,
                            [`votoListaGobernador`]: "Otros",
                            [`votoListaIntendente`]: "Otros",
                        }
                    }
            }
        }
    },
});

export const { operativoAsignado, encuestasRealizadasActualizado, respuestaRecibida, respuestaDescartada, encuestaGuardada, votoBoletaEntera } = BocaUrnaSlice.actions;

export default BocaUrnaSlice.reducer;