import React, { useState } from 'react'
import { Box, InputAdornment, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@mui/material'
import SkeletonTitleTable from '../../../shared/skeletons/SkeletonTitleTable';
import SearchIcon from '@mui/icons-material/Search';

const MuestralesEscuelas = ({ lista, data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [valueFilter, setCodigoElectoralFilter] = useState('');

    const filterData =  data.filter(
        (obj) => (obj.circuitoElectoral.includes(valueFilter) || obj.codigoEscuela.toLowerCase().includes(valueFilter))

    )

    const groupAndCountByEscuela = filterData.reduce((acc, obj) => {
        const circuito = obj.circuitoElectoral;
        let votos;
        switch (lista) {
            case 'presidente':
                votos = obj.votoListaPresidente;
                break;
            case 'gobernador':
                votos = obj.votoListaGobernador;
                break;
            case 'intendente':
                votos = obj.votoListaIntendente;
                break;
            default:
                break
        }
        if (!acc[circuito]) {
            acc[circuito] = {
                Escuela: obj.codigoEscuela,
              };
        }
        if (!acc[circuito][votos]) {
            acc[circuito][votos] = 1;
        } else {
            acc[circuito][votos] += 1;
        }
        return acc;
    }, {});

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const uniqueVotos = Object.keys(groupAndCountByEscuela).reduce((acc, circuito) => {
        return [...acc, ...Object.keys(groupAndCountByEscuela[circuito])];
    }, []);

    const uniqueVotosSet = Array.from(new Set(uniqueVotos));


    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Object.keys(groupAndCountByEscuela).length) : 0;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




    if (isLoading) return <SkeletonTitleTable />;

    return (
        
        <Stack direction={'column'} spacing={2}>
           <Stack direction={'row'} alignItems={'end'}>
           <TextField
                    placeholder=''
                    size='small'
                    color='primary'
                    variant='outlined'
                    value={valueFilter}
                    onChange={(e) => setCodigoElectoralFilter(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
           </Stack>
            <TableContainer component={Box}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Circuito electoral</TableCell>
                            {uniqueVotosSet.map((voto) => (
                                <TableCell key={voto}>{voto}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(groupAndCountByEscuela).map(([circuito, votos]) => (
                            <TableRow key={circuito}>
                                <TableCell>{circuito}</TableCell>
                                {uniqueVotosSet.map((voto) => (
                                    <TableCell key={voto}>
                                        {votos[voto] ? votos[voto] : 0}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={4} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage={"Resultados por página"}
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                count={Object.keys(groupAndCountByEscuela).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default MuestralesEscuelas;