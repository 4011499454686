import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { mockGetMesaGestigoByOperativo_dummy_generales_prov_eof, mockGetMesaGestigoByOperativo_dummy_generales_prov_nextToken, mockGetMesaTestigo_dummy, mockMesaTestigoByFiscalizador_dummy } from "../mocks/graphql/MesaTestigoApiMock";
import { TIPOS_OPERATIVOS } from "../constants/AppConstants";

export const MesaTestigoApi = {
    getMesa: async (id) => {
        if (!id) return;
        // if(process.env.NODE_ENV === "development") return mockGetMesaTestigo_dummy;

        const result = await API.graphql(
            graphqlOperation(queries.getMesaTestigo, { id: id })
        );

        return result;
    },
    getMesaFiscalizador: async (fiscalizador) => {
        if (!fiscalizador) return;
        // if (process.env.NODE_ENV === "development") return mockMesaTestigoByFiscalizador_dummy;

        const variables = {
            fiscalizador: fiscalizador,
            filter: {
                or: [
                    { operativo: { eq: TIPOS_OPERATIVOS.PROVINCIA_BA.value } },
                    { operativo: { eq: TIPOS_OPERATIVOS.LANUS.value } }
                ]
            }
        }

        const result = await API.graphql(
            graphqlOperation(queries.mesaTestigosByFiscalizador, variables)
        );

        return result;
    },
    updateStatusPadron: async (status) => {
        if (!status) return;

        const updated = await API.graphql(
            graphqlOperation(mutations.updateMesaTestigo, { input: status })
        )

        return updated;
    },
    updateMesa: async (mesa) => {
        if (!mesa ) return;

        const variables = {
            ...mesa,
        };

        const updated = await API.graphql(
            graphqlOperation(mutations.updateMesaTestigo, { input: variables })
        );

        return updated;
    },
    cerrarMesa: async (id, cargoCallCenter = false) => {
        if (!id) return;

        const variables = {
            id: id,
            cerrada: true,
            cargoCallCenter: cargoCallCenter
        }
        const result = await API.graphql(
            graphqlOperation(mutations.updateMesaTestigo, { input: variables })
        )
        return result;
    },
    getMesasByOperativo: async (operativo, nextToken = null) => {
        if(!operativo) return;

        // if(process.env.NODE_ENV === "development" && nextToken === null) return mockGetMesaGestigoByOperativo_dummy_generales_prov_nextToken;
        // if(process.env.NODE_ENV === "development" && nextToken !== null) return mockGetMesaGestigoByOperativo_dummy_generales_prov_eof;

        const result = await API.graphql(
            graphqlOperation(queries.mesaTestigosByOperativo, { operativo: operativo, nextToken: nextToken})
        )

        return result;
    }
}