import { createSlice } from "@reduxjs/toolkit";
import { EscrutinioApi } from "../../apis/EscrutinioApi";
import { MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS } from "../../constants/AppConstants";

const EXAMPLE_ESCRUTINIO = {
    id: "",
    operativo: "",
    seccionElectoral: "",
    partido: "",
    circuitoElectoral: "",
    nombreEscuela: "",
    codigoEscuela: "",
    mesa: "",
    fiscalizador: "",
    tipo: "",
    lista: "",
    codigoLista: "",
    candidato: "",
    fuerzaPolitica: "",
    votos: 0,
    votosExpandidos: 0,
    expansor: 1,
};
export const EscrutinioSlice = createSlice({
    name: "escrutinio",
    initialState: {
        escrutinioPresidente: [],
        escrutinioGobernador: [],
        escrutinioIntendente: [],
        estatusEscrutinio: {
            "escrutinioPresidente": MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.SIN_EMPEZAR,
            "escrutinioGobernador": MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.SIN_EMPEZAR,
            "escrutinioIntendente": MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.SIN_EMPEZAR,
        },
        escrutinioOtros: null,
    },
    reducers: {
        conteoRecibido: (state, action) => {
            const { id, tipo, lista, codigoLista, candidato, fuerzaPolitica, votos, votosExpandidos } = action.payload;

            switch (tipo) {
                case "escrutinioPresidente":
                    if (!state.escrutinioPresidente) state.escrutinioPresidente = [];
                    const presi = state.escrutinioPresidente.find(e => e.lista === lista);
                    if (presi) {
                        presi.votos = votos
                        presi.votosExpandidos = votosExpandidos
                    } else {
                        state.escrutinioPresidente.push({ lista, codigoLista, candidato, fuerzaPolitica, votos, votosExpandidos });
                    }
                    break;
                case "escrutinioGobernador":
                    if (!state.escrutinioGobernador) state.escrutinioGobernador = [];
                    const gobe = state.escrutinioGobernador.find(e => e.lista === lista);
                    if (gobe) {
                        gobe.votos = votos
                        gobe.votosExpandidos = votosExpandidos
                    } else {
                        state.escrutinioGobernador.push({ lista, codigoLista, candidato, fuerzaPolitica, votos, votosExpandidos });
                    }
                    break;
                case "escrutinioIntendente":
                    if (!state.escrutinioIntendente) state.escrutinioIntendente = [];
                    const inte = state.escrutinioIntendente.find(e => e.lista === lista);
                    if (inte) {
                        inte.votos = votos
                        inte.votosExpandidos = votosExpandidos
                    } else {
                        state.escrutinioIntendente.push({ lista, codigoLista, candidato, fuerzaPolitica, votos, votosExpandidos });
                    }
                    break;
                default:
                    //case para "escrutinioOtros"
                    const _id = (!id) ? state.escrutinioOtros?.id : id ;
                    state.escrutinioOtros = {
                        ...state.escrutinioOtros,
                        id: _id
                        , lista, codigoLista, candidato, fuerzaPolitica, votos, votosExpandidos
                    }
                    break;
            }
        },
        conteoDescartado: (state, action) => {
            const { tipo, lista } = action.payload;

            switch (tipo) {
                case "escrutinioPresidente":
                    return {
                        ...state,
                        escrutinioPresidente: state.escrutinioPresidente.filter(e => e.lista !== lista)
                    }
                case "escrutinioGobernador":
                    return {
                        ...state,
                        escrutinioGobernador: state.escrutinioGobernador.filter(e => e.lista !== lista)
                    }
                case "escrutinioIntendente":
                    return {
                        ...state,
                        escrutinioIntendente: state.escrutinioIntendente.filter(e => e.lista !== lista)
                    }
                default:
                    //case para "escrutinioOtros"
                    return {
                        ...state,
                        escrutinioOtros: null
                    }
                    break;
            }
        },
        estatusEscrutinioActualizado: (state, action) => {
            return {
                ...state,
                estatusEscrutinio: {
                    ...state.estatusEscrutinio,
                    ...action.payload
                }
            }
        },
        escrutinioRecibido: (state, action) => {
            const { tipo, escrutinio } = action.payload;

            return {
                ...state,
                [`${tipo}`]: [...escrutinio]
            };
        },
        escrutinioFinalizado: (state, action) => {
            const escrutinioCerrado = {
                "escrutinioPresidente": MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ENVIADO,
                "escrutinioGobernador": MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ENVIADO,
                "escrutinioIntendente": MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ENVIADO,
            };
            return {
                ...state,
                estatusEscrutinio: escrutinioCerrado
            }
        }
    }
});

export const { conteoRecibido, conteoDescartado, estatusEscrutinioActualizado, escrutinioRecibido, escrutinioFinalizado } = EscrutinioSlice.actions;

export default EscrutinioSlice.reducer;

export const fetchEscrutuniosPorFiscalizador = (fiscalizador, mesa, formularios, setIsLoading) => (dispatch) => {
    formularios.forEach(async element => {
        setIsLoading(true)
        const filters = {
            tipo: element.tipo,
            codigoEscuela: mesa.codigoEscuela,
            operativo: mesa.operativo,
        };

        try {
            const result = await EscrutinioApi.getEscrutinioPorFiscalizador(fiscalizador, filters)
            const items = result.data?.escrutiniosByFiscalizador.items;
            const payload = {
                tipo: element.tipo,
                escrutinio: items,
            }
            const detalleStatus = (items.length === 0) ? MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.SIN_EMPEZAR : MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.COMPLETADO
            const _status = {
                [`${element.tipo}`]: detalleStatus
            };
            dispatch(estatusEscrutinioActualizado(_status))
            dispatch(escrutinioRecibido(payload));
        } catch (error) {
            const _status = {
                [`${element.tipo}`]: MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ERROR_SINC,
            };
            dispatch(estatusEscrutinioActualizado(_status))
        }
        setIsLoading(false);
    });
}