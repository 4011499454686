import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { APP_ROUTES, CANDIDATURAS_PASO_23_GOBERNADOR, CANDIDATURAS_PASO_23_INTENDENCIA, CANDIDATURAS_PASO_23_PRESIDENCIA, LISTADO_CANDIDATURAS, MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS } from '../../constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Divider, IconButton, List, ListSubheader, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListItemCandidato from '../../components/mesa-testigo/ListItemCandidato';
import { showAlert, toggleLoader } from '../../store/slices/sharedSlice';
import ListItemEscrutinioTotal from '../../components/mesa-testigo/ListItemEscrutinioTotal';
import ContainedButton from '../../components/shared/ContainedButton';
import OutlinedButton from '../../components/shared/OutlinedButton';
import SendIcon from '@mui/icons-material/Send';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import DialogIconActions from '../../components/shared/DialogIconActions';
import { EscrutinioApi } from '../../apis/EscrutinioApi';
import { conteoDescartado, conteoRecibido, estatusEscrutinioActualizado } from '../../store/slices/EscrutinioSlice';
import { MesaTestigoApi } from '../../apis/MesaTestigoApi';

const INITIAL_STATE = { "operativo": "", "tipo": "", "name": "", "label": "", "linkTo": "" };

const EscrutinioMesa = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { fiscalizador } = useSelector((state) => state.account);
    const {
        id: mesaId,
        operativo,
        seccionElectoral,
        partido,
        circuitoElectoral,
        nombreEscuela,
        codigoEscuela,
        mesa,
        expansor
    } = useSelector((state) => state.mesaTestigo);
    const {
        escrutinioPresidente,
        escrutinioGobernador,
        escrutinioIntendente,
        estatusEscrutinio,
        escrutinioOtros
    } = useSelector((state) => state.escrutinio)
    //por URL, vamos a recibir qué candidatura se desea realizar el escrutinio. esto está definido en el RouteConfig
    const { _candidatura } = useParams();
    const [candidatura, setCandidatura] = useState(INITIAL_STATE);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [submitDialogIsOpen, setSubmitDialogIsOpen] = useState(false);
    const [itemOtros, setItemOtros] = useState(null);
    const [canSubmit, setCanSubmit] = useState(true);

    useEffect(() => {
        const found = LISTADO_CANDIDATURAS.find(lc => lc.name === _candidatura);

        if (!found) {
            dispatch(showAlert({
                message: `Se ha seleccionado una candidatura incorrecta. No es posible realizar el escrutinio.`,
                severity: "warning",
            }));

            //TODO: analizar si hace falta redireccionar hacia la home de mesa testigo
            navigate(APP_ROUTES.MESA_TESTIGO);
            return;
        }

        setCandidatura(found);
    }, [_candidatura]);

    useEffect(() => {
        //si el estatus de la "candidatura" elegida para realizar el escrutinio es igual a "ENVIADO", abort
        if (estatusEscrutinio[candidatura.tipo] === MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.ENVIADO) {
            dispatch(showAlert({
                message: `El escrutinio para esta mesa se encuentra cerrado.`,
                severity: "info",
            }));
            navigate(-1);
        }

    }, [candidatura]);

    const listSubHeader = (
        <ListSubheader>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='body1' color={"#908EB1"}>
                    Candidatos
                </Typography>
                <Typography variant='body1' sx={{ marginRight: '20px' }} color={"#908EB1"}>
                    Votos
                </Typography>
            </Stack>
            <Divider />
        </ListSubheader>
    );

    const listItems = useMemo(() => {
        //si el estado del escrutinio es distinto a "sin empezar", setear como estado inicial donde dejó el usuario final
        let _candidatos = []
        const hasStarted = (estatusEscrutinio[candidatura.tipo] !== MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.SIN_EMPEZAR);

        switch (candidatura.tipo) {
            case "escrutinioPresidente":
                _candidatos = (hasStarted) ? [...escrutinioPresidente] : [...CANDIDATURAS_PASO_23_PRESIDENCIA]
                _candidatos = [
                    ..._candidatos.map(c => {
                        const posicion = CANDIDATURAS_PASO_23_PRESIDENCIA.find(ca => ca.lista === c.lista).posicion
                        return { ...c, posicion }
                    })
                ]
                break;

            case "escrutinioGobernador":
                _candidatos = (hasStarted) ? [...escrutinioGobernador] : [...CANDIDATURAS_PASO_23_GOBERNADOR]
                _candidatos = [
                    ..._candidatos.map(c => {
                        const posicion = CANDIDATURAS_PASO_23_GOBERNADOR.find(ca => ca.lista === c.lista).posicion
                        return { ...c, posicion }
                    })
                ]
                break;

            case "escrutinioIntendente":
                _candidatos = (hasStarted) ? [...escrutinioIntendente] : [...CANDIDATURAS_PASO_23_INTENDENCIA]
                _candidatos = [
                    ..._candidatos.map(c => {
                        const posicion = CANDIDATURAS_PASO_23_INTENDENCIA.find(ca => ca.lista === c.lista).posicion
                        return { ...c, posicion }
                    })
                ]
                break;
            default:
                return;
        }

        // const otros = _candidatos.find(c => c.lista.includes("888"));
        // if (otros !== undefined) {
        //     ///workarround para "Cannot update a component while rendering a different component warning"
        //     setTimeout(() => {
        //         ///lo elimino del escrutinio
        //         dispatch(conteoDescartado({ tipo: candidatura.tipo, ...otros }))
        //         ///y lo guardo la referencia aparte
        //         const tipo = "escrutinioOtros";
        //         const _payload = { ...otros, tipo };
        //         delete _payload.__typename;
        //         delete _payload.createdAt;
        //         delete _payload.updatedAt;
        //         dispatch(conteoRecibido(_payload));
        //         setItemOtros(otros);
        //     }, 100)
        // }

        // _candidatos = _candidatos.filter(c => !c.lista.includes("888"));
        return _candidatos.sort((a, b) => a.posicion - b.posicion).map((c, index) => <ListItemCandidato key={`candidato-${index}`} tipo={candidatura.tipo} lista={c.lista} votos={c.votos} toggleCanSubmit={setCanSubmit} />);
    }, [candidatura]);

    const handleOnClickCancel = () => {
        setCancelDialogIsOpen(true);
    };

    const handleOnClickSubmit = async () => {
        //primero chequeo el estado de la mesa por si alguien de "Call Center" la cerró remotamente.
        //evitar que vuelva actualizar el escrutinio.
        setCanSubmit(false)
        const result = await MesaTestigoApi.getMesa(mesaId);
        const mesa = result.data?.getMesaTestigo;

        if (mesa.cerrada) {
            dispatch(
                showAlert({
                    message: `La mesa ya ha sido cerrada. No es posible enviar los resultados.`,
                    severity: "warning",
                }))
            return;
        }

        setSubmitDialogIsOpen(true);
        setCanSubmit(true)
    };

    const cancelDialogCancel = () => {
        setCancelDialogIsOpen(false)
    }

    const cancelDialogConfirm = () => {
        setCancelDialogIsOpen(false);
        navigate(-1);
    }

    const submitDialogCancel = () => {
        setSubmitDialogIsOpen(false)
    }

    const submitDialogConfirm = () => {
        setSubmitDialogIsOpen(false);

        const mesaAsignada = {
            operativo,
            seccionElectoral,
            partido,
            circuitoElectoral,
            nombreEscuela,
            codigoEscuela,
            mesa,
            expansor
        };
        let escrutinio = []
        switch (candidatura.tipo) {
            case "escrutinioPresidente":
                escrutinio = [...escrutinioPresidente];
                break;

            case "escrutinioGobernador":
                escrutinio = [...escrutinioGobernador];
                break;

            case "escrutinioIntendente":
                escrutinio = [...escrutinioIntendente];
                break;
        }

        // //le agrego "candidato" otros
        // escrutinio = [...escrutinio, escrutinioOtros]

        dispatch(toggleLoader({ isLoading: true }));
        EscrutinioApi.sendEscrutinio(estatusEscrutinio[candidatura.tipo], mesaAsignada, fiscalizador, candidatura.tipo, escrutinio)
            .then((success) => {
                const _status = {
                    [`${candidatura.tipo}`]: MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS.COMPLETADO
                };

                dispatch(estatusEscrutinioActualizado(_status));
                dispatch(showAlert({
                    message: "Escrutinio enviado correctamente",
                    severity: "success"
                }));
                navigate(-1);
            })
            .catch((err) => {
                console.error(err)
                dispatch(
                    showAlert({
                        message: `No se ha podido enviar el escrutinio. Por favor, intente nuevamente`,
                        severity: "error",
                    })
                );
            })
            .finally(() => {
                ///TODO: actualizar el escrutinio···Subido
                const newStateMesa = {
                    id: mesaId,
                    [`${candidatura.tipo}Subido`]: true,
                }
                MesaTestigoApi.updateMesa(newStateMesa)
                ///limpio "escrutinioOtros" para resetear el estado
                dispatch(conteoDescartado({ tipo: "escrutinioOtros", lista: "" }))

                dispatch(toggleLoader({ isLoading: false }));
            });
    }

    return (
        <Container maxWidth='xs' sx={{ paddingY: 2 }}>
            <DialogIconActions
                open={submitDialogIsOpen}
                cancelText={"Cancelar"}
                confirmText={"Enviar"}
                content={`¿Desea enviar el formulario? Los resultados de la mesa serán enviados.`}
                handleCancel={submitDialogCancel}
                handleConfirm={submitDialogConfirm}
                icon={<SendIcon sx={{ color: "#7A75A0" }} />}
                title={"Enviar resultados"}
            />
            <DialogIconActions
                open={cancelDialogIsOpen}
                cancelText={"Cancelar"}
                confirmText={"Volver"}
                content={`Se perderán los datos ingresados.`}
                handleCancel={cancelDialogCancel}
                handleConfirm={cancelDialogConfirm}
                icon={<PhonelinkEraseIcon sx={{ color: "#7A75A0" }} />}
                title={"Volver al inicio"}
            />
            <Box maxWidth='xs' sx={{ paddingY: 2 }}>
                <Stack direction={'column'} spacing={2} >
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'start'}>
                        <IconButton color='info' size='medium' onClick={handleOnClickCancel}>
                            <ArrowBackIcon />
                        </IconButton>

                        <Typography variant='h3' color={(theme) => theme.palette.primary.main}>
                            {candidatura?.label}
                        </Typography>
                    </Stack>

                    <List subheader={listSubHeader}>
                        {listItems}
                        <ListItemEscrutinioTotal candidatura={candidatura} canSubmit={canSubmit} toggleCanSubmit={setCanSubmit} />
                    </List>

                    <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                        <OutlinedButton label={"Cancelar"} color='primary' onClick={handleOnClickCancel} />
                        <ContainedButton label={"Enviar resultados"} color='tertiary' onClick={handleOnClickSubmit} endIcon={<SendIcon />} disabled={!canSubmit} />
                    </Stack>
                </Stack>
            </Box>
        </Container>
    )
}

export default EscrutinioMesa;