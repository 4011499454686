import React from 'react'
import { Button, ButtonGroup, Divider, Skeleton, Stack, Typography } from '@mui/material';
import RoundedInfoCard from '../RoundedInfoCard';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

const SkeletonMesaAsignada = () => {
    return (
        <Stack direction={'column'} spacing={2}>
            <div className='seccion-partido-circuito-group skeleton'>
                <Divider />
                <ButtonGroup variant="text" fullWidth disableRipple disableFocusRipple>
                    <Button sx={{ flexDirection: 'column' }}>
                        <Typography variant='body2' textTransform={'none'}>
                            Sección electoral
                        </Typography>
                        <Typography variant='body2' fontWeight={600} textTransform={'none'}>
                            <Skeleton width={"90px"} />
                        </Typography>
                    </Button>
                    <Button sx={{ flexDirection: 'column' }}>
                        <Typography variant='body2' textTransform={'none'}>
                            Partido
                        </Typography>
                        <Typography variant='body2' fontWeight={600} textTransform={'none'}>
                            <Skeleton width={"40px"} />
                        </Typography>
                    </Button>
                    <Button sx={{ flexDirection: 'column' }}>
                        <Typography variant='body2' textTransform={'none'}>
                            Circuito
                        </Typography>
                        <Typography variant='body2' fontWeight={600} textTransform={'none'}>
                            <Skeleton width={"40px"} />
                        </Typography>
                    </Button>
                </ButtonGroup>
                <Divider />
            </div>

            <Stack direction={'row'} spacing={2}>
                <RoundedInfoCard>
                    <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={"center"}>
                        <GpsFixedIcon color='primary' />
                        <Typography variant='body1' textTransform={'uppercase'}>
                            <Skeleton width={"125px"} />
                        </Typography>
                    </Stack>
                </RoundedInfoCard>

                <RoundedInfoCard>
                    <Stack direction={'column'} spacing={0} alignItems={'center'} justifyContent={"center"}>
                        <Typography variant='body2'>
                            Mesa
                        </Typography>
                        <Typography variant='body2' fontWeight={600}>
                            <Skeleton width={"50px"} />
                        </Typography>
                    </Stack>
                </RoundedInfoCard>
            </Stack>
        </Stack>
    )
}

export default SkeletonMesaAsignada;