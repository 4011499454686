import { API, graphqlOperation } from "aws-amplify";
import { TIPOS_OPERATIVOS } from "../constants/AppConstants";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { ESCUELAS_LANUS } from "../constants/Escuelas";

export const BocaUrnaApi = {
    /**
     * @deprecated
     */
    avanceOperativoPorEncuestador: async (operativoId) => {
        if (process.env.NODE_ENV === "development") return;

        if (!operativoId) throw Error("Operativo inválido.");

        let codEscuelas = [];
        switch (operativoId) {
            case TIPOS_OPERATIVOS.LANUS.value:
                //filtrar, del total de circuitos electorales, solo aquellos que pertenezcan a Lanús
                codEscuelas = ESCUELAS_LANUS.map(el => el.codigoEscuela);
                break;
            case TIPOS_OPERATIVOS.PROVINCIA_BA.value:
                break;
            default:
                throw Error("Seleccione un operativo de la lista.");
        }

        const variables = {
            filter: {
                or: codEscuelas.map(ce => { return { codigoEscuela: { "eq": ce } } })
            }
        };

        const result = await API.graphql(graphqlOperation(queries.encuestaBocaUrnasByCodigoEscuela, variables));

        if(result.errors) throw Error(result.errors);
        return result;
    },
    saveEncuesta: async (encuesta) => {
        if (process.env.NODE_ENV === "development") return;

        const newEncuesta = await API.graphql(
            graphqlOperation(mutations.createEncuestaBocaUrna, { input: encuesta })
        );

        return newEncuesta;
    },
};