import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useState } from 'react'

const Header = ({onChangeLista}) => {
    const [lista, setLista] =  useState("Encuestador");
    const handleChange = (v) => {
        const listaSelected = v.target.value;
        setLista(listaSelected);
        onChangeLista(v.target.value);
    }

    return (
        <div>
            <Stack direction={'column'} spacing={1}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='h4' color={(theme) => theme.palette.primary.main}>
                    Usuarios
                </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <ToggleButtonGroup
                    color="primary"
                    value={lista}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="Encuestador">Encuestadores</ToggleButton>
                    <ToggleButton value="Fiscalizador">Fiscalizadores</ToggleButton>
                    <ToggleButton value="Administrador">Administradores</ToggleButton>
                    <ToggleButton value="Analista">Analista</ToggleButton>
                    <ToggleButton value="CallCenter">Call Center</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
        </Stack>
        </div>
    )
}

export default Header;