import React, { useState } from 'react'
import Container from '@mui/material/Container';
import { Box, IconButton, InputAdornment, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { APP_ROLES, APP_ROUTES } from '../constants/AppConstants';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { showAlert, toggleLoader } from '../store/slices/sharedSlice';
import ContainedButton from '../components/shared/ContainedButton';
import { useNavigate } from 'react-router-dom';

const INITIAL_VALUE_LOGIN_FORM = {
    username: "",
    password: ""
}

const Login = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginForm, setLoginForm] = useState(INITIAL_VALUE_LOGIN_FORM);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    const handleOnSubmit = async () => {

        try {
            dispatch(toggleLoader({ isLoading: true }));
            const user = await Auth.signIn(loginForm.username, loginForm.password);
            const cognitoGroup = user.signInUserSession?.idToken?.payload["cognito:groups"].slice(0, 1)[0];
            dispatch(toggleLoader({ isLoading: false }));

            //redirijo segun el rol del usuario asignado
            switch (cognitoGroup) {
                case APP_ROLES.ENCUESTADOR:
                    navigate(APP_ROUTES.BOCA_DE_URNA, { replace: true })
                    break;
                case APP_ROLES.ANALISTA:
                    navigate(APP_ROUTES.STATUS_OPERATIVO, { replace: true })
                    break;
                case APP_ROLES.FISCALIZADOR:
                    navigate(APP_ROUTES.MESA_TESTIGO, { replace: true })
                    break;
                case APP_ROLES.CALL_CENTER_LANUS:
                    navigate(APP_ROUTES.MESA_TESTIGO, { replace: true })
                    break;
                case APP_ROLES.CALL_CENTER_PROV:
                navigate(APP_ROUTES.MESA_TESTIGO, { replace: true })
                break;
                default:
                    navigate(APP_ROUTES.STATUS_OPERATIVO, { replace: true })
                    break;
            };
        } catch (error) {
            console.log('error signing in', error);
            dispatch(
                showAlert({
                    message: `${error}`,
                    severity: "error",
                })
            );

            dispatch(toggleLoader({ isLoading: false }));
        }

    }

    const handleFormOnChange = (ev) => {
        const { name, value } = ev.target;
        let nextErrors = { ...errors };
        (!value) ? nextErrors[name] = "Este campo es obligatorio" : delete nextErrors[name];

        setErrors(nextErrors);
        setLoginForm((prev) => ({ ...prev, [name]: value }))
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box
            sx={{
                backgroundColor: "#4D416F",
                display: "flex",
            }}
            justifyContent={{ 'sm': 'center' }}
            alignItems={{ 'xs': 'center', 'sm': 'center' }}
            margin={{ 'sm': 'auto' }}
            height={'100%'}
        >
            <Stack
                direction={{
                    'xs': 'column',
                    'sm': 'row'
                }}
                justifyContent={{ 'xs': 'space-between', 'sm': 'center' }}
                alignItems={{ 'xs': 'center', 'sm': 'center' }}
                alignSelf={{ 'xs': 'stretch' }}
                spacing={2}
            >
                {/* <Paper></Paper> */}

                <Box sx={{
                    width: { xs: "88%", sm: "450px", },
                    margin: { xs: "3rem auto" }
                }}
                >
                    <Typography variant='h1' color={'#FAFAFA'} marginBottom={'1.38rem'}>
                        Observatorio electoral
                    </Typography>
                    <Typography variant='h2' color={'#FAFAFA'}>
                        Iniciá sesión con tu usuario y contraseña para ingresar a la aplicación.
                    </Typography>
                </Box>

                <Box sx={{
                    width: { xs: "100%", sm: "480px", },
                }}>
                    <form onSubmit={handleOnSubmit} autoComplete=''>
                        <Paper
                            elevation={0}
                            sx={{
                                paddingX: 8,
                                paddingY: 4,
                                borderRadius: { xs: "20px 20px 0 0", sm: "6px", },
                                padding: { xs: "57px 30px", }
                            }}
                        >
                            <Stack direction={'column'} spacing={6}>
                                <TextField
                                    name="username"
                                    label="Usuario"
                                    required
                                    value={loginForm.username}
                                    onChange={(e) => handleFormOnChange(e)}
                                    variant="outlined"
                                    helperText={errors["username"]}
                                    error={errors["username"]}
                                />

                                <TextField
                                    name="password"
                                    label="Contraseña"
                                    required
                                    value={loginForm.password}
                                    onChange={(e) => handleFormOnChange(e)}
                                    helperText={errors["password"]}
                                    error={errors["password"]}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <ContainedButton
                                    label={"Ingresar"}
                                    variant="contained"
                                    color='primary'
                                    onClick={handleOnSubmit}
                                    disabled={!loginForm.username || !loginForm.password}
                                />

                                {/* <Typography variant='body2' >
                                ¿Olvidaste tu contraseña?
                                <Link sx={{
                                    marginLeft: 1
                                }} href={APP_ROUTES.FORGOT_PASSWORD} underline="always">
                                    {'Regenerar'}
                                </Link>
                            </Typography> */}
                            </Stack>
                        </Paper>
                    </form>
                </Box>
            </Stack>
        </Box>
    )
}

export default Login;