import React from 'react'
import { Button, ButtonGroup, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import SkeletonMesaAsignada from './skeletons/SkeletonMesaAsignada';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import RoundedInfoCard from './RoundedInfoCard';
import { HowToVote } from '@mui/icons-material';

const MesaAsignada = ({ isLoading, operativo, seccion, partido, circuito, nombreEscuela, mesa }) => {

    if (isLoading) return (
        <SkeletonMesaAsignada />
    );

    return (
        <Stack direction={'column'} spacing={2}>
            <div className='seccion-partido-circuito-group'>
                <Divider />
                <ButtonGroup variant="text" fullWidth disableRipple disableFocusRipple>
                    <Button sx={{ flexDirection: 'column' }}>
                        <Typography variant='body2' textTransform={'none'}>
                            Sección electoral
                        </Typography>
                        <Typography variant='body2' fontWeight={600} textTransform={'none'}>
                            Nº {seccion || "-"}
                        </Typography>
                    </Button>
                    <Button sx={{ flexDirection: 'column' }}>
                        <Typography variant='body2' textTransform={'none'}>
                            Partido
                        </Typography>
                        <Typography variant='body2' fontWeight={600} textTransform={'none'}>
                            {partido || "-"}
                        </Typography>
                    </Button>
                    <Button sx={{ flexDirection: 'column' }}>
                        <Typography variant='body2' textTransform={'none'}>
                            Circuito
                        </Typography>
                        <Typography variant='body2' fontWeight={600} textTransform={'none'}>
                            {circuito || "-"}
                        </Typography>
                    </Button>
                </ButtonGroup>
                <Divider />
            </div>

            {/* ESCONDO ESTA ESTRUCTURA PORQUE PREFIEREN QUE NO SE VEAN LOS DATOS DE ESCUELA YA QUE NO VAN A COINCIDIR CON LA MESA :)
            <Stack direction={'row'} spacing={2}>
                <RoundedInfoCard>
                    <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={"center"}>
                        <GpsFixedIcon color='primary' />
                        <Typography variant='body1' textTransform={'uppercase'}>
                            {nombreEscuela ?? "Sin escuela"}
                        </Typography>
                    </Stack>
                </RoundedInfoCard>

                <RoundedInfoCard>
                    <Stack direction={'column'} spacing={0} alignItems={'center'} justifyContent={"center"}>
                        <Typography variant='body2'>
                            Mesa
                        </Typography>
                        <Typography variant='body2' fontWeight={600}>
                            {mesa ?? "-"}
                        </Typography>
                    </Stack>
                </RoundedInfoCard>
            </Stack> */}

          {/* MODIFICO CON LA SIGUIENTE ESTRUCTURA QUE SOLO MUESTRA LA MESA */}
            <Stack>
                <RoundedInfoCard variant='fullWidth'>
                    <Stack direction={'row'} sx={{width:'100%'}} spacing={2} alignItems={'center'} justifyContent={"center"}>
                        <HowToVote color='primary' />
                        <Typography variant='h4' textTransform={'uppercase'} color='primary'>
                            MESA NÚMERO {mesa ?? "-"}
                        </Typography>
                    </Stack>
                </RoundedInfoCard>
            </Stack>
        </Stack>
    )
}

export default MesaAsignada;