import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../store/slices/sharedSlice';
import { fiscalizadorSeleccionado } from '../../store/slices/AccountSlice';
import { MenuItem, TextField } from '@mui/material';
import { MesaTestigoApi } from '../../apis/MesaTestigoApi';
import { APP_ROLES, TIPOS_OPERATIVOS } from '../../constants/AppConstants';
import { mesaReseteada, mesasRecibidas } from '../../store/slices/MesaTestigoSlice';

const SelectListMesas = ({ setIsLoading }) => {
    const dispatch = useDispatch();
    const { nickname, rol } = useSelector((state) => state.account.user);
    const { mesasCallCenter: mesas, id: mesaId } = useSelector((state) => state.mesaTestigo);

    const [operativo, setOperativo] = useState(null);
    const [mesaTestigo, setMesaTestigo] = useState(mesaId);
    const [showSelectMesa, setShowSelectMesa] = useState(false);
    const [nextToken, setNextToken] = useState(null);

    useEffect(() => {
        if (!nickname) return;

        switch (rol) {
            case APP_ROLES.CALL_CENTER_LANUS:
                ///TODO: fetch all "mesas" for the selected "operativo"
                setShowSelectMesa(true)
                setOperativo(TIPOS_OPERATIVOS.LANUS.value)
                break;

            case APP_ROLES.CALL_CENTER_PROV:
                ///TODO: fetch all "mesas" for the selected "operativo"
                setShowSelectMesa(true)
                setOperativo(TIPOS_OPERATIVOS.PROVINCIA_BA.value)
                break;

            case APP_ROLES.FISCALIZADOR:
                dispatch(fiscalizadorSeleccionado(nickname))
                break;

            default:
                dispatch(showAlert({
                    message: `No posee los permisos necesarios para esta página.`,
                    severity: "warning",
                }));
                break;
        }
    }, [nickname]);

    useEffect(() => {
        if (!operativo) return;
        //si ya hay mesas en la store, abort
        if (mesas.length > 0) return;

        fetchMesas()
    }, [operativo])

    const fetchMesas = () => {
        setIsLoading(true);
        ///TODO: fetch all "mesas" for the selected "operativo"
        MesaTestigoApi.getMesasByOperativo(operativo, nextToken)
            .then((result) => {
                const _nextToken = result.data?.mesaTestigosByOperativo.nextToken

                const _items = result.data?.mesaTestigosByOperativo.items;
                // solo necesitamos: id, seccionElectoral, partido, mesa, fiscalizador
                const _mesasReduced = _items.map(m => { return { id: m.id, seccionElectoral: m.seccionElectoral, partido: m.partido, mesa: m.mesa, fiscalizador: m.fiscalizador } })

                const _mesas = [...mesas, ..._mesasReduced]
                //sort by seccionElectoral, then by partido, and finally by numero mesa
                const _mesasSorted = _mesas.sort((a, b) => a.seccionElectoral - b.seccionElectoral || a.partido.localeCompare(b.partido) || a.mesa - b.mesa)
                dispatch(mesasRecibidas(_mesasSorted))

                if(!_nextToken) setIsLoading(false)
                setNextToken(_nextToken)
            })
            .catch((err) => {
                setIsLoading(false);
                dispatch(showAlert({
                    message: `${err.errors[0].message}`,
                    severity: "error",
                }));
            })
            .finally(() => {
                // setIsLoading(false);
            })
    }

    useEffect(() => {
        if (nextToken === null) {
            setIsLoading(false)
            return; //ya no hay más mesas que traer
        }
        fetchMesas() //continue fetching

    }, [nextToken])


    const handleOnChangeMesa = (ev) => {
        const _id = ev.target.value;
        const _mesaSelected = mesas.find(m => m.id === _id);

        setMesaTestigo(_id)
        dispatch(fiscalizadorSeleccionado(_mesaSelected.fiscalizador))
        //reseo slice mesaTestigo porque al cambiar de mesa en callcenter, quedan mal los estados de formularios/esctrutinio/totales
    }

    if (!showSelectMesa) return (<></>)

    return (
        <>
            <TextField
                sx={{
                    // width: '80%'
                }}
                select
                name="mesa"
                label="Sección - Partido - Mesa"
                value={mesaTestigo}
                onChange={(e) => handleOnChangeMesa(e)}
                variant="outlined"
                size='small'
                helperText={(!mesaTestigo) ? "Seleccione una mesa testigo" : null}
            >
                {mesas?.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                        {m.seccionElectoral} - {m.partido} - {m.mesa}
                    </MenuItem>
                ))}
            </TextField>
        </>
    )
}

export default SelectListMesas;