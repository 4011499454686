import { SvgIcon } from '@mui/material';
import React from 'react'

const ProfileAvatarIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="4" fill="#4D416F" />
                <path d="M15 8.25C16.2375 8.25 17.25 9.2625 17.25 10.5C17.25 11.7375 16.2375 12.75 15 12.75C13.7625 12.75 12.75 11.7375 12.75 10.5C12.75 9.2625 13.7625 8.25 15 8.25ZM15 19.5C18.0375 19.5 21.525 20.9513 21.75 21.75H8.25C8.50875 20.94 11.9738 19.5 15 19.5ZM15 6C12.5138 6 10.5 8.01375 10.5 10.5C10.5 12.9862 12.5138 15 15 15C17.4862 15 19.5 12.9862 19.5 10.5C19.5 8.01375 17.4862 6 15 6ZM15 17.25C11.9963 17.25 6 18.7575 6 21.75V24H24V21.75C24 18.7575 18.0037 17.25 15 17.25Z" fill="white" />
            </svg>
        </SvgIcon>
    )
}

export default ProfileAvatarIcon;