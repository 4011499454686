import { Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { LISTADO_CANDIDATURAS } from '../../constants/AppConstants'
import ListadoCandidaturasItem from './ListadoCandidaturasItem';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEscrutuniosPorFiscalizador } from '../../store/slices/EscrutinioSlice';

const ListadoCandidaturas = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { fiscalizador } = useSelector((state) => state.account);
    const { estatusEscrutinio } = useSelector((state) => state.escrutinio);
    const { operativo, codigoEscuela, cerrada } = useSelector((state) => state.mesaTestigo);

    const formularios = useMemo(() => LISTADO_CANDIDATURAS.filter(lc => lc.operativo === operativo), [operativo]);

    useEffect(() => {
        const mesa = { operativo, codigoEscuela };
        // console.log(fiscalizador, operativo, codigoEscuela, cerrada)
        if(!cerrada) dispatch(fetchEscrutuniosPorFiscalizador(fiscalizador, mesa, formularios, setIsLoading));
    }, [formularios, codigoEscuela])

    return (
        <Stack direction={'column'} spacing={2}>
            <Typography variant='h4' sx={{fontWeight:'700'}} color={(theme) => theme.palette.primary.main}>
                Formularios
            </Typography>
            {
                formularios.map(
                    candidatura => <ListadoCandidaturasItem key={candidatura.name} isLoading={isLoading} item={candidatura} status={estatusEscrutinio[candidatura.tipo]} />
                )}
        </Stack>
    )
}

export default ListadoCandidaturas;