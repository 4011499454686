import React, { useMemo, useState } from 'react'
import Container from '@mui/material/Container';
import { IconButton, InputAdornment, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { APP_ROLES } from '../constants/AppConstants';
import { API, Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { showAlert, toggleLoader } from '../store/slices/sharedSlice';
import ContainedButton from '../components/shared/ContainedButton';
import { validateEmailAddress } from '../utils/CommonUtils';
import { AdminQueriesApi } from '../apis/AdminQueriesApi';

const INITIAL_VALUE_SIGNUP_FORM = {
    username: "",
    password: "",
    given_name: "", //nombre
    family_name: "", //apellido
}

const SignUp = ({ }) => {
    const dispatch = useDispatch();
    const [signupForm, setSignupForm] = useState(INITIAL_VALUE_SIGNUP_FORM);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [rol, setRol] = useState('');

    // const ROLES = useMemo(() => Object.values(APP_ROLES).filter(r => r !== "SuperAdmin"), []);
    const ROLES = useMemo(() => Object.values(APP_ROLES), []);

    const handleOnSubmit = async () => {
        try {
            const signUpFields = {
                username: signupForm.username.trim(),
                password: signupForm.password,
                attributes: {
                    given_name: signupForm.given_name.trim(), //nombre
                    family_name: signupForm.family_name.trim(), //apeliido
                    email: signupForm.username.trim(), //coindice con username porque usuario se loguea con email
                    nickname: `${signupForm.given_name.substring(0, 1).toLocaleLowerCase()}${signupForm.family_name.toLocaleLowerCase()}` //tomamos primera letra del nombre y el apeliido. este atributo lo vamos a usar luego para identificar quién creó una encuesta y para armar las salidas
                },
                autoSignIn: { // optional - enables auto sign in after user is confirmed
                    enabled: true,
                }
            }
            dispatch(toggleLoader({ isLoading: true }));
            const user = await Auth.signUp(signUpFields);
            //agrego usuario recien creado al User Group seleccionado
            AdminQueriesApi.addUserToGroup(user.userSub, rol);

            //agrego usuario recien creado a la tabla de Personal
            AdminQueriesApi.saveUserAsPersonal(signUpFields.attributes.nickname, rol);
            AdminQueriesApi.saveUserAsSeguimiento(signUpFields.attributes.nickname);
            //reseteo el form
            setSignupForm(INITIAL_VALUE_SIGNUP_FORM);
            setRol('');
            dispatch(
                showAlert({
                    message: `Usuario creado correctamente.`,
                    severity: "success",
                })
            );
            dispatch(toggleLoader({ isLoading: false }));
        } catch (error) {
            console.log('error signing up', error);
            dispatch(
                showAlert({
                    message: `${error}`,
                    severity: "error",
                })
            );

            dispatch(toggleLoader({ isLoading: false }));
        }
    }


    const handleFormOnChange = (ev) => {
        const { name, value } = ev.target;
        let nextErrors = { ...errors };
        (!value) ? nextErrors[name] = "Este campo es obligatorio" : delete nextErrors[name];

        if (name === "username") {
            (!validateEmailAddress(value)) ? nextErrors[name] = "E-mail inválido" : delete nextErrors[name];
        }

        setErrors(nextErrors);
        setSignupForm((prev) => ({ ...prev, [name]: value }))
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Stack
            direction={{
                'xs': 'column',
                'sm': 'row'
            }}
            justifyContent={{ 'xs': 'space-between', 'sm': 'center' }}
            alignItems={{ 'xs': 'center', 'sm': 'center' }}
            sx={{
                backgroundColor: "#4D416F",
                height: "100%",
            }}
            spacing={2}
        >
            <Paper></Paper>
            <Container maxWidth="sm">
                <Paper
                    elevation={0}
                    sx={{
                        backgroundColor: "transparent",
                    }}
                >
                    <Typography variant='h3' color={'#FAFAFA'}>
                        Observatorio muestral
                    </Typography>
                    <Typography variant='h5' color={'#FAFAFA'}>
                        Complete el siguiente formulario para crear una cuenta en la aplicación.
                    </Typography>
                </Paper>
            </Container>

            <Container maxWidth="xs">
                <form onSubmit={handleOnSubmit} autoComplete=''>
                    <Paper
                        elevation={0}
                        sx={{
                            paddingX: 8,
                            paddingY: 4,
                            borderRadius: 6,
                        }}
                    >
                        <Stack direction={'column'} spacing={6}>
                            <TextField
                                name="username"
                                label="E-mail"
                                required
                                value={signupForm.username}
                                onChange={(e) => handleFormOnChange(e)}
                                variant="outlined"
                                helperText={errors["username"]}
                                error={!!errors["username"]}
                            />

                            <TextField
                                name="password"
                                label="Contraseña"
                                required
                                value={signupForm.password}
                                onChange={(e) => handleFormOnChange(e)}
                                helperText={errors["password"]}
                                error={!!errors["password"]}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                name="given_name"
                                label="Nombre"
                                required
                                value={signupForm.given_name}
                                onChange={(e) => handleFormOnChange(e)}
                                variant="outlined"
                                helperText={!!errors["given_name"]}
                                error={errors["given_name"]}
                            />

                            <TextField
                                name="family_name"
                                label="Apellido"
                                required
                                value={signupForm.family_name}
                                onChange={(e) => handleFormOnChange(e)}
                                variant="outlined"
                                helperText={!!errors["family_name"]}
                                error={errors["family_name"]}
                            />

                            <TextField
                                select
                                name="rol"
                                label="Rol"
                                required
                                value={rol}
                                onChange={(e) => setRol(e.target.value)}
                                variant="outlined"
                                helperText={!!errors["rol"]}
                                error={errors["rol"]}
                            >
                                {ROLES.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <ContainedButton
                                label={"Crear cuenta"}
                                variant="contained"
                                color='primary'
                                onClick={handleOnSubmit}
                                disabled={!errors || !rol}
                            />
                        </Stack>
                    </Paper>
                </form>
            </Container>
        </Stack>
    )
}

export default SignUp