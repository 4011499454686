import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { TIPOS_OPERATIVOS } from '../../constants/AppConstants';
import { useSelector } from 'react-redux';

const AdvertenciaTotalesIncosistentes = () => {
    const [distintos, setDistintos] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const { operativo, cantidadPersonasVotaron } = useSelector((state) => state.mesaTestigo);
    const { escrutinioPresidente, escrutinioGobernador, escrutinioIntendente } = useSelector((state) => state.escrutinio);
    const { fiscalizador } = useSelector((state) => state.account);

    useEffect(() => {
        let escrutinios = { presidente: escrutinioPresidente, gobernador: escrutinioGobernador };

        if (operativo === TIPOS_OPERATIVOS.LANUS.value) {
            escrutinios = { ...escrutinios, intendente: escrutinioIntendente }
        };

        let nextShow = false;
        let _distintos = [];

        Object.entries(escrutinios).forEach(([key, value]) => {
            const totalEscrutinio = value.reduce((accumulator, value) => accumulator += value.votos, 0);
            const esIgual = (totalEscrutinio === cantidadPersonasVotaron)

            if (!esIgual) {
                nextShow = true;
                _distintos.push(key);
            }
        })

        setShowAlert(nextShow)
        setDistintos(_distintos)
    }, [operativo, fiscalizador, cantidadPersonasVotaron, escrutinioGobernador, escrutinioIntendente, escrutinioPresidente]);

    if (!showAlert || !cantidadPersonasVotaron) return (<></>);
    return (
        <Alert severity="warning">
            {`Advertencia: El número ingresado de personas que emitieron voto es menor o mayor al total de votos declarados en escrutinio de: ${distintos.join(', ')}`}
        </Alert>
    )
}

export default AdvertenciaTotalesIncosistentes;