import { Auth } from 'aws-amplify'
import { redirect } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/AppConstants';

const IsAuthenticated = async () => {
    try {
        //try to get current user. if it fails, redirect to login page
        await Auth.currentAuthenticatedUser();
        return null;
    } catch (error) {
        return redirect(APP_ROUTES.LOGIN);
    }
}

export default IsAuthenticated