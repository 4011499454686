import React from 'react'
import { Button, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/AppConstants';

const GoBackButton = () => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(APP_ROUTES.BOCA_DE_URNA);
    }

    return (
        <Button variant='outlined' color='cta' fullWidth onClick={handleOnClick}
            sx={{
                borderRadius: "12px",
                height: "80px",
            }}
        >
            Volver a inicio
        </Button>
    )
}

export default GoBackButton;