import { Container, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { TIPOS_OPERATIVOS } from '../../constants/AppConstants';

const Header = ({ onChangeOperativo }) => {
    const [operativo, setOperativo] = useState('');
    const [description, setDescription] = useState('');

    const handleOnChangeOperativo = (ev) => {
        const operativoSelected = Object.values(TIPOS_OPERATIVOS).find(to => to.value === ev.target.value);
        setOperativo(operativoSelected.value);
        setDescription(operativoSelected.descripcion);
        onChangeOperativo(operativoSelected.value)
    };

    return (
        <Stack direction={'column'} spacing={1}>
            <Typography variant='body2' color={(theme) => theme.palette.text.secondary}>
                Observatorio electoral
            </Typography>

            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='h2' color={(theme) => theme.palette.primary.main}>
                    {description}
                </Typography>

                <TextField
                    sx={{
                        width: '35%'
                    }}
                    select
                    name="operativo"
                    label="Operativo"
                    value={operativo}
                    onChange={(e) => handleOnChangeOperativo(e)}
                    variant="outlined"
                    size='small'
                    helperText={(!operativo) ? "Seleccione una opción" : null}
                >
                    {Object.values(TIPOS_OPERATIVOS).map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.descripcion}
                        </MenuItem>
                    ))}
                </TextField>
            </Stack>
        </Stack>
    )
}

export default Header;