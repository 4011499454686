import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';
import React from 'react'
import TextButton from './TextButton';

const DialogIconActions = ({ open, icon, title, content, cancelText, confirmText, handleConfirm, handleCancel }) => {

    return (
        <Dialog
            maxWidth='xs'
            open={open}
            // onClose={handleUserCancel}
            disableEscapeKeyDown
            PaperProps={{
                sx: {
                    backgroundColor: "#EBEBF2",
                    borderRadius: "28px",
                },
            }}
        >
            <DialogTitle component={Box}>
                <Stack direction={'column'} spacing={2} alignItems={'center'}>
                    {icon}
                    <Typography variant='h4'>
                        {title}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText align='center'>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-around' }}>
                <TextButton label={cancelText} color='primary' onClick={handleCancel} autoFocus />
                <TextButton label={confirmText} color='primary' onClick={handleConfirm} />
            </DialogActions>
        </Dialog>
    );
}

export default DialogIconActions;