import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MesaAsignada from '../shared/MesaAsignada';
import ParticipacionMesa from './ParticipacionMesa';
import ListadoCandidaturas from './ListadoCandidaturas';
import { MesaTestigoApi } from '../../apis/MesaTestigoApi';
import { showAlert } from '../../store/slices/sharedSlice';
import { mesaAsignada } from '../../store/slices/MesaTestigoSlice';
import AdvertenciaTotalesIncosistentes from './AdvertenciaTotalesIncosistentes';
import ReminderTakePicture from './ReminderTakePicture';
import { Box } from '@mui/system';
import { escrutinioFinalizado } from '../../store/slices/EscrutinioSlice';
import SelectListMesas from './SelectListMesas';

const Summary = () => {
    const dispatch = useDispatch();
    const { nickname, rol } = useSelector((state) => state.account.user);
    const { fiscalizador } = useSelector((state) => state.account);
    const { operativo, seccionElectoral, partido, circuitoElectoral, nombreEscuela, mesa } = useSelector((state) => state.mesaTestigo);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!fiscalizador) return;

        setIsLoading(true);
        MesaTestigoApi.getMesaFiscalizador(fiscalizador)
            .then((result) => {
                const mesa = result.data?.mesaTestigosByFiscalizador.items[0];
                const payload = { ...mesa, cerrada: mesa.cerrada ?? false, expansor: mesa.expansor ?? 1 }
                
                dispatch(mesaAsignada(payload));
                if (payload.cerrada) dispatch(escrutinioFinalizado());
            })
            .catch((err) => {
                dispatch(showAlert({
                    message: `${err.errors[0].message}`,
                    severity: "error",
                }));

            })
            .finally(() => {
                setIsLoading(false);
            })

    }, [fiscalizador])

    return (
        <Box className="ancho-celular">
            <Stack spacing={3}>
                <Typography variant='h2' fontWeight={"700"} color={(theme) => theme.palette.primary.main}>
                    Mesa testigo
                </Typography>

                <SelectListMesas setIsLoading={setIsLoading} />

                <MesaAsignada
                    circuito={circuitoElectoral}
                    isLoading={isLoading}
                    mesa={mesa}
                    nombreEscuela={nombreEscuela}
                    operativo={operativo}
                    partido={partido}
                    seccion={seccionElectoral}
                />

                <ReminderTakePicture />

                <ParticipacionMesa />

                <AdvertenciaTotalesIncosistentes />

                <ListadoCandidaturas />
            </Stack>
        </Box>
    )
}

export default Summary;