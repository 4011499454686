import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { mockGetAvanceOperativo_Lanus, mockGetAvanceOperativo_Provincia, mockGetSeguimientoEncuestador_Dummy } from "../mocks/graphql/SeguimientoApiMock";
import { TIPOS_OPERATIVOS } from "../constants/AppConstants";

export const SeguimientoApi = {
    getAvanceOperativo: async (operativo, nextToken = null) => {
        if (!operativo) return;
        if (process.env.NODE_ENV === "development") {
            switch (operativo) {
                case TIPOS_OPERATIVOS.LANUS.value:
                    return mockGetAvanceOperativo_Lanus;

                case TIPOS_OPERATIVOS.PROVINCIA_BA.value:
                    return mockGetAvanceOperativo_Provincia;
            }
        };

        const result = await API.graphql(
            graphqlOperation(queries.seguimientosByOperativo, { operativo: operativo, nextToken: nextToken })
        );

        return result;
    },
    getSeguimientoEncuestador: async (encuestador) => {
        if (!encuestador) return;
        if (process.env.NODE_ENV === "development") return mockGetSeguimientoEncuestador_Dummy;

        const variables = {
            encuestador: encuestador,
            filter: {
                or: [
                    { operativo: { eq: TIPOS_OPERATIVOS.PROVINCIA_BA.value } },
                    { operativo: { eq: TIPOS_OPERATIVOS.LANUS.value } }
                ]
            }
        }

        const result = await API.graphql(
            graphqlOperation(queries.seguimientosByEncuestador, variables)
        );

        return result;
    },

    UpdateEscuelaAsignada: async (encuestador) => {
        if (!encuestador) return;

        const seguimientoToUpdate = await API.graphql(
            graphqlOperation(queries.seguimientosByEncuestador, { encuestador: encuestador.encuestador })
        );

        const seguimientoUpdated = {
            id: seguimientoToUpdate.data.seguimientosByEncuestador.items[0].id,
            encuestador: seguimientoToUpdate.data.seguimientosByEncuestador.items[0].encuestador,
            operativo: encuestador.operativo,
            codigoEscuela: encuestador.codigoEscuela,
            circuitoElectoral: encuestador.circuitoElectoral,
            escuela: encuestador.escuela,
            cantidadEncuestas: seguimientoToUpdate.data.seguimientosByEncuestador.items[0].cantidadEncuestas
        }

        const result = await API.graphql(
            graphqlOperation(mutations.updateSeguimiento, { input: seguimientoUpdated })
        );

        return seguimientoUpdated;
    }

    // createSeguimientoEscuelaAsignada: async (encuestador) => {
    //     if (!encuestador) return;

    //     console.log("crear seguimiento",encuestador);

    //     const seguimientoCreated = {
    //         cantidadEncuestas: 0,
    //         encuestador: encuestador.encuestador,
    //         escuela: encuestador.escuela,
    //         codigoEscuela: encuestador.codigoEscuela,
    //         circuitoElectoral: encuestador.circuitoElectoral
    //     }

    // const result = await API.graphql(
    //     graphqlOperation(mutations.createSeguimiento, { input: seguimientoCreated })
    // );

    // return seguimientoCreated;
    // }
}