import { Box, IconButton, Stack, Tab, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES, CUESTIONARIO_PASO_23_LANUS, TIPOS_OPERATIVOS } from '../../constants/AppConstants';
import { TabList } from '@mui/lab';

const CuestionarioHeader = () => {
    const encuestasRealizadas = useSelector(state => state.bocaUrna.encuestasRealizadas);
    const operativo = useSelector(state => state.bocaUrna.operativo);
    const navigate = useNavigate();

    // const [activeStep, setActiveStep] = useState('01');

    const tabs = useMemo(() => {
        switch (operativo) {
            case TIPOS_OPERATIVOS.LANUS.value:
                return CUESTIONARIO_PASO_23_LANUS.PASOS.map(item => <Tab key={`tab-${item.value}`} value={item.value} label={item.label} disabled />);

            case TIPOS_OPERATIVOS.PROVINCIA_BA.value:
                //TODO: implementar formulario para prov
                break;
            default:
                return CUESTIONARIO_PASO_23_LANUS.PASOS.map(item => <Tab key={`tab-${item.value}`} value={item.value} label={item.label} disabled />);
        }
    }, [operativo]);

    const handleOnClickNavigateBack = () => {
        //presentar dialog para confirmar accion? se perdera todo el progreso
        navigate(`${APP_ROUTES.BOCA_DE_URNA}`);
    };

    return (
        <Box className="ancho-celular">
            <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'start'}>
                <IconButton color='info' size='medium' onClick={handleOnClickNavigateBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='h3' color={(theme) => theme.palette.primary.main}>
                    Encuesta [{encuestasRealizadas + 1}]
                </Typography>
            </Stack>

            <Box sx={{ borderBottom:'1px solid #e6e0e9'}}>
                <TabList
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    {tabs}
                </TabList>
            </Box>
        </Box>
    )
}

export default CuestionarioHeader;