import { Button } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/AppConstants';
import RedoIcon from '@mui/icons-material/Redo';

const ResetSurveyButton = () => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(APP_ROUTES.BOCA_DE_URNA_ENCUESTA);
    }

    return (
        <Button variant='contained' color='secondary' fullWidth onClick={handleOnClick}
            endIcon={<RedoIcon />}
            sx={{
                borderRadius: "12px",
                height: "80px",
            }}
        >
            Nueva
        </Button>
    )
}

export default ResetSurveyButton;