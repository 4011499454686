import { Box, Divider } from '@mui/material';
import React from 'react'
import Header from '../../components/mesa-testigo/Header';
import Summary from '../../components/mesa-testigo/Summary';
import CerrarMesa from '../../components/mesa-testigo/CerrarMesa';
import { Container } from '@mui/system';

const HomeMesaTestigo = () => {
    return (
        <Container maxWidth='xs' sx={{ paddingY: 4 }}>
            <Header />
            <Divider variant='fullWidth' sx={{ marginY: 2 }} />
            <Summary />
            <CerrarMesa />
        </Container>
    )
}

export default HomeMesaTestigo;