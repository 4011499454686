import React from 'react'
import { Alert } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useSelector } from 'react-redux';

const ReminderTakePicture = () => {
    const { cerrada } = useSelector((state) => state.mesaTestigo);

    if (!cerrada) return (<></>)

    return (
        <Alert icon={<CameraAltIcon fontSize="large" />} severity="info">
            No olvides sacar una foto del acta original o copia.
        </Alert>
    )
}

export default ReminderTakePicture;