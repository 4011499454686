export const mockGetAvanceOperativo_Lanus = {
    "data": {
        "seguimientosByOperativo": {
            "items": [
                {
                    "id": "f850a70c-84dc-4b52-8c79-ad7082b01c88",
                    "encuestador": "jcastro",
                    "operativo": "paso-23-lanus",
                    "codigoEscuela": "LAN26",
                    "circuitoElectoral": "274",
                    "escuela": "INSTITUTO REMEDIOS DE ESCALADA",
                    "cantidadEncuestas": 2,
                    "createdAt": "2023-08-03T12:55:28.676Z",
                    "updatedAt": "2023-08-03T15:51:18.229Z",
                    "__typename": "Seguimiento"
                },
                {
                    "id": "4dd41558-d794-432a-9b6e-b110e20a3da4",
                    "encuestador": "cgantus",
                    "operativo": "paso-23-lanus",
                    "codigoEscuela": "LAN6",
                    "circuitoElectoral": "261",
                    "escuela": "ESCUELA EP N°53",
                    "cantidadEncuestas": 0,
                    "createdAt": "2023-08-03T19:01:13.814Z",
                    "updatedAt": "2023-08-03T20:05:59.691Z",
                    "__typename": "Seguimiento"
                },
                {
                    "id": "48b39b7f-708d-426d-a74e-43ce87fe0d3a",
                    "encuestador": "jdonati",
                    "operativo": "paso-23-lanus",
                    "codigoEscuela": "LAN11",
                    "circuitoElectoral": "262",
                    "escuela": "SOC.DE FOMENTO M.M.GUEMES",
                    "cantidadEncuestas": 1,
                    "createdAt": "2023-08-03T12:58:39.380Z",
                    "updatedAt": "2023-08-03T19:07:14.088Z",
                    "__typename": "Seguimiento"
                },
                {
                    "id": "6af9100e-a4d6-4996-97a4-31afd59d6318",
                    "encuestador": "dschiavone",
                    "operativo": "paso-23-lanus",
                    "codigoEscuela": "LAN16",
                    "circuitoElectoral": "271",
                    "escuela": "ESCUELA EP N°8/ESB N°29",
                    "cantidadEncuestas": 1,
                    "createdAt": "2023-08-03T12:56:55.503Z",
                    "updatedAt": "2023-08-03T15:53:25.769Z",
                    "__typename": "Seguimiento"
                },
                {
                    "id": "2f2a057d-6799-40ec-98ef-618c6a388d4d",
                    "encuestador": "mlago",
                    "operativo": "paso-23-lanus",
                    "codigoEscuela": "LAN29",
                    "circuitoElectoral": "276",
                    "escuela": "COLEGIO JOSE M.ESTRADA",
                    "cantidadEncuestas": 30,
                    "createdAt": "2023-08-03T13:38:07.447Z",
                    "updatedAt": "2023-08-07T18:07:32.352Z",
                    "__typename": "Seguimiento"
                }
            ],
            "nextToken": null,
            "__typename": "ModelSeguimientoConnection"
        }
    }
}

export const mockGetAvanceOperativo_Provincia = {
    "data": {
        "seguimientosByOperativo": {
            "items": [],
            "nextToken": null,
            "__typename": "ModelSeguimientoConnection"
        }
    }
};

export const mockGetSeguimientoEncuestador_Dummy = {

    "data": {
        "seguimientosByEncuestador": {
            "items": [
                {
                    "id": "some-random-id",
                    "encuestador": "mcepeda",
                    "operativo": "paso-23-lanus",
                    "codigoEscuela": "LAN-01",
                    "circuitoElectoral": "999",
                    "escuela": "Ana María Janer",
                    "cantidadEncuestas": "32"
                }
            ],
            "nextToken": null,
            "__typename": "ModelSeguimientoConnection"
        }
    }
};