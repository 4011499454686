import React from 'react'
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/AppConstants';

const ParticipacionMesa = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cantidadPersonasPadron, cantidadPersonasVotaron, cerrada } = useSelector((state) => state.mesaTestigo);

    const handleOnClickPadron = () => {
        navigate(`${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CANT_PADRON}`);
    };

    const handleOnClickParticipacion = () => {
        navigate(`${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CANT_PARTICIPACION}`);
    };

    if (cerrada) return (
        <Stack direction={'column'} spacing={2}>
            <Paper variant='outlined' sx={{ px: 2, py: 3, borderColor: (theme) => theme.palette.primary.main }}>
                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-around'}>
                    <Typography variant='body2' color={(theme) => theme.palette.primary.main}>
                        Total personas habilitadas para votar
                    </Typography>

                    <Typography variant='body2' color={(theme) => theme.palette.primary.main} fontWeight={600}>
                        {cantidadPersonasPadron ?? "-"}
                    </Typography>
                </Stack>
            </Paper>

            <Paper variant='outlined' sx={{ px: 2, py: 3, borderColor: (theme) => theme.palette.primary.main }}>
                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-around'}>
                    <Typography variant='body2' color={(theme) => theme.palette.primary.main}>
                        Total de personas que emitieron voto
                    </Typography>

                    <Typography variant='body2' color={(theme) => theme.palette.primary.main} fontWeight={600}>
                        {cantidadPersonasVotaron ?? "-"}
                    </Typography>
                </Stack>
            </Paper>
        </Stack>
    );

    return (
        <Stack direction={'column'} spacing={2}>
            <Paper elevation={0} variant='elevation' sx={{ px: 2, py: 1, background: "#FAFAFA" }}>
                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                    <div className='counter-total-padron'>
                        <Typography variant='body2'>
                            Total personas habilitadas para votar
                        </Typography>
                        <Typography variant='body2' fontWeight={600}>
                            {cantidadPersonasPadron ?? "-"}
                        </Typography>
                    </div>

                    <IconButton onClick={handleOnClickPadron}>
                        <EditIcon />
                    </IconButton>
                </Stack>
            </Paper>

            <Paper elevation={0} variant='elevation' sx={{ px: 2, py: 1, background: "#FAFAFA" }}>
                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                    <div className='counter-total-votaron'>
                        <Typography variant='body2'>
                            Total de personas que emitieron voto
                        </Typography>
                        <Typography variant='body2' fontWeight={600}>
                            {cantidadPersonasVotaron ?? "-"}
                        </Typography>
                    </div>

                    <IconButton onClick={handleOnClickParticipacion}>
                        <EditIcon />
                    </IconButton>
                </Stack>
            </Paper>
        </Stack>
    )
}

export default ParticipacionMesa;