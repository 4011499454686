import { Box, Card, CardContent, Grid, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../store/slices/sharedSlice';
import { encuestasRealizadasActualizado, operativoAsignado } from '../../store/slices/BocaUrnaSlice';
import { SeguimientoApi } from '../../apis/SeguimientoApi';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { TIPOS_OPERATIVOS } from '../../constants/AppConstants';

const Summary = () => {
    const { nickname, rol } = useSelector((state) => state.account.user);
    const { operativo, nombreEscuela, encuestasRealizadas } = useSelector((state) => state.bocaUrna);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!nickname) return;
        setIsLoading(true);
        SeguimientoApi.getSeguimientoEncuestador(nickname)
            .then((result) => {
                const seguimiento = result.data?.seguimientosByEncuestador.items[0];
                try {
                    const { operativo, codigoEscuela, circuitoElectoral, escuela, cantidadEncuestas } = seguimiento;
                    const detalleAsignacion = { operativo, codigoEscuela, circuitoElectoral, nombreEscuela: escuela };
    
                    dispatch(operativoAsignado(detalleAsignacion));
                    dispatch(encuestasRealizadasActualizado(parseInt(cantidadEncuestas)));
                } catch (ex) {
                    dispatch(showAlert({
                        severity: "warning",
                        message: "No tiene operativo asignado. Por favor contáctese con coordinador/a."
                    }))
                }
            })
            .catch((err) => {
                dispatch(showAlert({
                    message: `${err}`,
                    severity: "error",
                }));
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [nickname]);

    const descripcionOperativo = useMemo(() => Object.values(TIPOS_OPERATIVOS).find(op => op.value === operativo)?.descripcion || '-', [operativo]);

    return (

        <Box className="ancho-celular">
            <Stack spacing={3}>
                <div>
                    <Typography variant='h2' fontWeight={"700"} color={(theme) => theme.palette.primary.main}>
                        Boca de Urna
                    </Typography>
                    <Typography variant='h2' color={(theme) => theme.palette.primary.main}>
                        {(isLoading) ? <Skeleton /> : descripcionOperativo}
                    </Typography>
                </div>

                <div>
                    <Typography variant='body1' color={(theme) => theme.palette.primary.main}>
                        {rol}
                    </Typography>
                </div>
                <Card
                    variant="outlined"
                    sx={{
                        borderRadius: "12px",
                        borderColor: (theme) => theme.palette.secondary.main,
                        minHeight: '60px',
                    }}
                >
                    <CardContent sx={{ '&:last-child': { pb: "16px" } }}>
                        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={"start"}>
                            <GpsFixedIcon color='primary' />
                            <Typography variant='body1' textTransform={'uppercase'} color={(theme) => theme.palette.primary.main}>
                                {(isLoading) ? <Skeleton width={"125px"} /> : nombreEscuela}
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>

                <Card
                    variant='outlined'
                    className='box-210h'
                    sx={{ borderColor: (theme) => theme.palette.secondary.main, borderRadius: "12px" }}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ paddingBottom: '35px' }}>
                            <Grid item xs={7}>
                                <Typography fontFamily={"Chivo"} fontWeight={"500"} fontSize={"5rem"} color={(theme) => theme.palette.primary.main} textAlign={"center"}>
                                    {(isLoading) ? <Skeleton width={"50px"} /> : encuestasRealizadas.toLocaleString('es-AR', { minimumIntegerDigits: 2 })}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography fontFamily={"Chivo"} fontWeight={"500"} fontSize={"16px"} lineHeight={'normal'} color={(theme) => theme.palette.primary.main} textAlign={"left"}>
                                    Encuestas realizadas
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Stack>
        </Box>
    )
}

export default Summary;