import React from 'react'
import Header from '../../components/boca-urna/Header'
import { Container, Divider } from '@mui/material';
import Summary from '../../components/boca-urna/Summary';
import NewSurveyButton from '../../components/boca-urna/NewSurveyButton';

const HomeBocaDeUrna = ({ }) => {
    return (
        <Container maxWidth='xs' sx={{ paddingY: 4 }}>
            <Header />
            <Divider variant='fullWidth' sx={{ marginY: 4 }} />
            <Summary />
            <NewSurveyButton />
        </Container>
    )
}

export default HomeBocaDeUrna;