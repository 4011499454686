import React, { useState } from 'react'
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material'
import EditUsersModal from './EditUsersModal';
import EditButtonComponent from './EditButton';


const UsuariosCreadosListado = ({data }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [user, setUser] = useState('');
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = (row) => {setOpenDelete(true); setUser(row)};
    const handleCloseDelete = () => setOpenDelete(false);

    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpenEdit = (row) => {setOpenEdit(true); setUser(row)};
    const handleCloseEdit = () => setOpenEdit(false);
    
    return (
        <div>

            <Stack direction={'column'} spacing={2}>
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Encuestador/a</TableCell>
                                <TableCell align="left">Circuito asignado</TableCell>
                                <TableCell align="left">Escuela asignada</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : data
                            ).map((data) => (
                                <TableRow key={`row-${data.nickname}-${data.nombreEscuelaAsignada}`}>
                                    <TableCell component="th" scope="row">
                                        {data.nickname}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.circuitoElectoralAsignado}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.nombreEscuelaAsignada}
                                    </TableCell>
                                    <TableCell align="left">

                                        <EditButtonComponent onClick={() => handleOpenEdit(data)} />
                                        

                                        {/* <DeleteButtonComponent onClick={() => handleOpenDelete(data)} /> */}
                                        

                                    </TableCell>
                                </TableRow>
                            )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={4} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    labelRowsPerPage={"Resultados por página"}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                    // colSpan={3}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                
            </Stack>
            {
            openEdit &&
            <EditUsersModal open={openEdit} onClose={handleCloseEdit} row = {user}  />
            }
            {/* <DeleteUserModal open={openDelete} onClose={handleCloseDelete} row = {user} /> */}
        </div>
    )
}
export default UsuariosCreadosListado; 