import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ForumIcon from '@mui/icons-material/Forum';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import LogoutIcon from '@mui/icons-material/Logout';
import { ListSubheader, Stack, Tooltip } from '@mui/material';
import NavigationItem from './NavigationItem';
import ProfileAvatarIcon from '../icons/ProfileAvatarIcon';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { APP_ROUTES } from '../../../constants/AppConstants';
import { Apps, Dashboard, DonutLarge } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 405;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const BOCA_URNA_CHILDREN = [
    {
        nombre: "presidente",
        descripcion: "Presidente",
        path: `empty-for-now`,
    },
    {
        nombre: "gobernador",
        descripcion: "Gobernador",
        path: `empty-for-now`,
    },
    {
        nombre: "intendende",
        descripcion: "Intendente",
        path: `empty-for-now`,
    },
    {
        nombre: "muestrales",
        descripcion: "Muestrales",
        path: APP_ROUTES.MUESTRALES,
    },
    {
        nombre: "corte-boleta",
        descripcion: "Corte de boleta",
        path: `empty-for-now`,
    },
    {
        nombre: "inconsistencias",
        descripcion: "Inconsistencias",
        path: `empty-for-now`,
    },
];

const NAVIGATION_ITEMS = [
    //{
    //    nombre: "boca-urna",
    //    descripcion: "Boca de urna",
    //    iconElement: <ForumIcon color='primary' />,
    //    path: `empty-for-now`,
    //    children: BOCA_URNA_CHILDREN,
    //},
    //{
    //    nombre: "mesa-testigo",
    //    descripcion: "Mesa testigo",
    //    iconElement: <HowToVoteIcon color='primary' />,
    //    path: `empty-for-now`,
    //    children: [],
    //},
    {
        nombre: "usuarios",
        descripcion: "Usuarios",
        iconElement: <SupervisedUserCircleIcon color='primary' />,
        path: `${APP_ROUTES.USUARIOS}`,
        children: [],
    },
];


const NavigationDrawer = ({ content = null }) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.account.user);
    const { nombre, apellido, rol } = user;
    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
        setOpen(prev => !prev);
    };

    const handleOnClickLogout = async () => {
        await Auth.signOut();
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
                <DrawerHeader sx={{ padding: '0 20px' }}>
                    {(open)
                        ?
                        <Stack direction={'column'}>
                            <Typography variant='body1' fontWeight={400} color={(theme) => theme.palette.text.secondary}>
                                Usuario
                            </Typography>
                            <Typography variant='h4' fontWeight={500} color={(theme) => theme.palette.text.secondary}>
                                {nombre} {apellido}
                            </Typography>
                        </Stack>
                        : <IconButton color='primary' disableRipple sx={{ padding: '0' }}>
                            <ProfileAvatarIcon />
                        </IconButton>
                    }
                </DrawerHeader>

                <DrawerHeader>
                    <IconButton onClick={toggleDrawer}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider variant='middle' />

                <List>
                    <ListItem key={"inicio"} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }} onClick={() => navigate(`${APP_ROUTES.STATUS_OPERATIVO}`)}>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                                <Apps color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={"Inicio"} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>

                        <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}
                            href='https://search-amplify-opense-7lh8ym56ydyr-jx4pcximtaijyc2q657kjmml74.sa-east-1.es.amazonaws.com/_plugin/kibana/app/dashboards#/list?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))' target='_blank'>
                            {/* Link a test dashbords > modificar por link de producción */}
                            <Tooltip title="Salidas">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <DonutLarge color="primary" />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={"Salidas"} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>

                    {/* {(open)
                        ? <ListSubheader sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            color: "#5C6670",
                        }}>
                            Salidas
                        </ListSubheader>
                        : null
                    } */}
                    {NAVIGATION_ITEMS.map((item) => (
                        <NavigationItem key={`nav-item-${item.nombre}`} drawerIsOpen={open} item={item} />
                    ))}
                </List>

                <List sx={{ marginTop: "auto", marginBottom: 1, }}>
                    <ListItem key={"logout"} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={handleOnClickLogout}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <LogoutIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText primary={"Cerrar sesión"} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1 }}>
                {content}
            </Box>
        </Box>
    );
}

export default NavigationDrawer;