import { Card, CardContent, styled } from '@mui/material';
import React from 'react'

const CustomCard = styled((props) => <Card variant='outlined' {...props} />)(({ theme }) => ({
    borderRadius: "12px",
    borderColor: theme.palette.secondary.main,
}));
const RoundedInfoCard = (props) => {
    return (
        <CustomCard>
            <CardContent sx={{ '&:last-child': { pb: "16px" } }}>
                {props.children}
            </CardContent>
        </CustomCard>
    );
}

export default RoundedInfoCard;