import React, { useEffect, useMemo, useState } from 'react'
import { TabPanel } from '@mui/lab';
import { Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import OutlinedButton from '../shared/OutlinedButton';
import ContainedButton from '../shared/ContainedButton';
import { FLAG_FINALIZAR, PREGUNTAS_DESCARTAR_CONSISTENTE, VOTOS_EN_BLANCO } from '../../constants/AppConstants';
import { useDispatch } from 'react-redux';
import { votoBoletaEntera, respuestaDescartada, respuestaRecibida } from '../../store/slices/BocaUrnaSlice';

const CuestionarioStep = ({ step, handleTabChange }) => {
    const dispatch = useDispatch();
    const [showFinalizar, setShowFinalizar] = useState(false);
    const [optionSelected, setOptionSelected] = useState(null);

    const radioStyle = {
        padding: '0px 9px'
    };

    const radioLabelStyle = {
        alignItems: 'flex-start'
    };

    useEffect(() => {
        setOptionSelected(null);
        (step.siguiente === FLAG_FINALIZAR) ? setShowFinalizar(true) : setShowFinalizar(false)
    }, [step]);

    useEffect(() => {
        if (!optionSelected) return; //no guardar "null"
        const _respuesta = { [`${step.value}`]: optionSelected }
        dispatch(respuestaRecibida(_respuesta));

        switch (step.value) {
            case "votoEnBlanco":
                if (optionSelected === "Sí") {
                    ///hay que guardar, en todas las categorias, que votó en blanco
                    Object.entries(VOTOS_EN_BLANCO).forEach(([key, value]) => {
                        dispatch(respuestaRecibida({ [`${key}`]: value }))
                    });
                } else {
                    ///descartar voto en blanco en todas las caterogias
                    Object.entries(VOTOS_EN_BLANCO).forEach(([key, value]) => {
                        dispatch(respuestaDescartada(key))
                    });
                }
                break;

            case "cortoBoleta":
                if (optionSelected === "No") {
                    ///aplicar sintaxis consistente
                    dispatch(votoBoletaEntera())
                } else {
                    ///descartar respuestas en resto de las categorias
                    Object.entries(PREGUNTAS_DESCARTAR_CONSISTENTE).forEach(([key, value]) => {
                        dispatch(respuestaDescartada(key))
                    });
                }
                break;

            default:
                break;
        }
    }, [optionSelected]);

    const options = useMemo(
        () => step.opciones.map((op, index) => <FormControlLabel style={radioLabelStyle} sx={{ padding: '9px 0' }} key={`rg-option-${index}`} control={<Radio style={radioStyle} />} value={op.value} label={op.label} />)
        , [step]
    );

    const handleOnChangeRadioGroup = (event) => {
        const _value = event.target.value;
        const option = step.opciones.find(op => op.value === _value);
        (option.siguiente === FLAG_FINALIZAR || step.siguiente === FLAG_FINALIZAR) ? setShowFinalizar(true) : setShowFinalizar(false);

        setOptionSelected(_value);
    }

    const handleOnClickAnterior = () => {
        const nextActiveStep = step.anterior;
        dispatch(respuestaDescartada(step.value));
        handleTabChange(nextActiveStep);
    };

    const handleOnClickSiguiente = () => {
        const option = step.opciones.find(op => op.value === optionSelected);
        const nextActiveStep = option.siguiente ?? step.siguiente;

        handleTabChange(nextActiveStep);
    };

    return (
        <TabPanel value={step.value} >
            <Stack direction={'column'} spacing={4} justifyContent={'space-between'}>
                <Stack direction={'column'} spacing={3} sx={{ minHeight: '200px' }}>
                    <Typography variant='h3' color={(theme) => theme.palette.text.secondary}>
                        {step.tituloPregunta}
                    </Typography>
                    <Box>
                        <FormControl style={radioLabelStyle} >
                            <RadioGroup name={`rg-step-${step.value}`} value={optionSelected} onChange={handleOnChangeRadioGroup}>
                                {options}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Stack>

                <Stack>
                    <Stack direction={'row-reverse'} alignItems={'stretch'} justifyContent={'space-between'}>
                        {
                            (showFinalizar)
                                ? <ContainedButton label={"Finalizar"} color='tertiary' onClick={handleOnClickSiguiente} disabled={!optionSelected} />
                                : <ContainedButton label={"Siguiente"} color='primary' onClick={handleOnClickSiguiente} disabled={!optionSelected} />
                        }
                        {step.anterior && <OutlinedButton label={"Anterior"} color='primary' onClick={handleOnClickAnterior} />}
                    </Stack>
                </Stack>
            </Stack>
        </TabPanel>
    )
}

export default CuestionarioStep;