import React, { useState } from 'react'
import { Container } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import { APP_ROUTES, CUESTIONARIO_PASO_23_LANUS, FLAG_FINALIZAR } from '../../constants/AppConstants';
import CuestionarioHeader from '../../components/boca-urna/CuestionarioHeader';
import CuestionarioStep from '../../components/boca-urna/CuestionarioStep';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert, toggleLoader } from '../../store/slices/sharedSlice';
import { BocaUrnaApi } from '../../apis/BocaUrnaApi';
import { encuestaGuardada } from '../../store/slices/BocaUrnaSlice';
import { useNavigate } from 'react-router-dom';

const Encuesta = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { nickname: encuestador } = useSelector((state) => state.account.user);
    const { circuitoElectoral, codigoEscuela, nombreEscuela, respuestas, operativo } = useSelector((state) => state.bocaUrna);

    const [activeStep, setActiveStep] = useState(CUESTIONARIO_PASO_23_LANUS.PREGUNTAS[0]);

    const saveEncuesta = () => {
        dispatch(toggleLoader({ isLoading: true }));
        //construyo la encuesta
        const _encuesta = {
            circuitoElectoral,
            codigoEscuela,
            escuela: nombreEscuela,
            encuestador,
            operativo,
            ...respuestas
        };

        BocaUrnaApi.saveEncuesta(_encuesta)
            .then((result) => {
                dispatch(encuestaGuardada());
                navigate(APP_ROUTES.FINISH_BOCA_DE_URNA_ENCUESTA);
            })
            .catch((err) => {
                const detail = err.errors[0].message
                dispatch(showAlert({
                    message: `${detail}`,
                    severity: "error",
                }));
            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }));
            })
    };

    const tabChanged = (tab) => {
        //se debe guardar la encuesta realizada si tab es igual a "finalizar"
        if (tab === FLAG_FINALIZAR) {
            saveEncuesta();
        } else {
            //pasar a la siguiente/anterior pregunta
            const nextStep = CUESTIONARIO_PASO_23_LANUS.PREGUNTAS.find(preg => preg.value === tab);
            setActiveStep(nextStep)
        };
    };

    return (
        <Container maxWidth='xs' sx={{ paddingY: 4 }}>
            <TabContext value={activeStep.value}>
                <CuestionarioHeader />
                <CuestionarioStep step={activeStep} handleTabChange={tabChanged} />
            </TabContext>
        </Container>
    );
}

export default Encuesta;