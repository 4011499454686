import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useState } from 'react'


const Header = ({ onChangeLista }) => {
    const [lista, setLista] =  useState("presidente");
    const handleChange = (v) => {
        const listaSelected = v.target.value;
        setLista(listaSelected);
        onChangeLista(v.target.value);
    }


    return (
        <Stack direction={'column'} spacing={1}>
            <Typography variant='h5' color={(theme) => theme.palette.primary.main}>
                Boca de urna [Muestrales]
            </Typography>


            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <ToggleButtonGroup
                    color="primary"
                    value={lista}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="presidente">Presidente</ToggleButton>
                    <ToggleButton value="gobernador">Gobernador</ToggleButton>
                    <ToggleButton value="intendente">Intendente</ToggleButton>
                </ToggleButtonGroup>
            </Stack>


        </Stack>

    )
}

export default Header;