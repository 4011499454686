import { createSlice } from "@reduxjs/toolkit";

export const SharedSlice = createSlice({
    name: "shared",
    initialState: {
        loader: {
            isLoading: false,
            variant: "indeterminate",
            color: "inherit",
        },
        alertSnackbar: {
            isOpen: false,
            message: "",
            vertical: "top",
            horizontal: "center",
            severity: "info",
            hideAfter: 6000
        },
    },

    reducers: {
        toggleLoader: (state, action) => {
            return {
                ...state,
                loader: {
                    ...state.loader,
                    ...action.payload,
                }
            };
        },
        showAlert: (state, action) => {
            return {
                ...state,
                alertSnackbar: {
                    ...state.alertSnackbar,
                    isOpen: true,
                    ...action.payload
                }
            };
        },
        hideAlert: (state, action) => {
            return {
                ...state,
                alertSnackbar: {
                    ...state.alertSnackbar,
                    isOpen: false,
                    ...action.payload
                }
            };
        },
    },
});

export const { toggleLoader, showAlert, hideAlert } = SharedSlice.actions;

export default SharedSlice.reducer;