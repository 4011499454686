import { Button } from '@mui/material';
import React from 'react'

const TextButton = ({ label, ...props }) => {
    return (
        <Button
            sx={{
                height: "40px",
                width: 'fit-content',
                textTransform: 'capitalize'
            }}
            variant="text"
            {...props}
        >
            {label}
        </Button>
    );
}

export default TextButton;