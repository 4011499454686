import React from 'react'
import { Navigate } from "react-router-dom";

const Unauthorized = (props) => {
    const { loggedInUser } = props;
    const errorStyle = {
        fontSize: "3rem",
        textAlign: "center",
        display: "block",
        paddingTop: "2rem",
    };

    return (
        <>
            <span style={errorStyle}>
                No posee los permisos necesarios para visualizar esta pantalla.
            </span>
            {loggedInUser && loggedInUser.authorized && (
                <Navigate to={`${props.location.state.path}`} />
            )}
        </>
    );
}

export default Unauthorized