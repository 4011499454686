import React, { useEffect, useState } from 'react'
import UsuariosCreadosListado from '../components/listado-usuarios/UsuariosCreadosListado';
import Header from '../components/listado-usuarios/Header';
import NavigationDrawer from '../components/shared/navigation-drawer/NavigationDrawer';
import { Container } from '@mui/material';
import NewUserButton from '../components/listado-usuarios/NewUserButton';
import { PersonalApi } from '../apis/PersonalApi';
import { useDispatch } from 'react-redux';
import { showAlert } from '../store/slices/sharedSlice';

const Users = () => {

    const [rol, setRol] = useState('Encuestador');
    const [rolData, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        PersonalApi.getPersonalByRol(rol)
            .then((result) => {
                const usuarios = result.data?.personalsByRol.items;
                setData(usuarios);
            }).catch((err) => {
                dispatch(showAlert({
                    message: `${err}`,
                    severity: "error",
                }));
            }).finally(() => {
                setIsLoading(false);
            });
    },[rol]);

    return (
    <div>
            <NavigationDrawer
                content={
                    <Container
                        maxWidth='md'
                        sx={{
                            marginY: 2,
                            marginX: "auto",
                        }}>

                        <Header onChangeLista={setRol} />
                        {/* <NewUserButton /> */}
                        <UsuariosCreadosListado rol= {rol} data = {rolData}/>
                    </Container>
                    }
                />
    </div>

    )
}

export default Users;