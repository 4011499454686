///BOCA URNA
export const ESCUELAS_LANUS = [
    { "circuitoElectoral": 260, "nombreEscuela": "ESCUELA EP N°22/ES N°31", "codigoEscuela": "1" },
    { "circuitoElectoral": 260, "nombreEscuela": "ESCUELA EP N°58", "codigoEscuela": "2" },
    { "circuitoElectoral": 260, "nombreEscuela": "ESCUELA EP N°6/ES N°28", "codigoEscuela": "3" },
    { "circuitoElectoral": 260, "nombreEscuela": "ESCUELA ES N°3", "codigoEscuela": "4" },
    { "circuitoElectoral": 261, "nombreEscuela": "ESCUELA EP N°25/ES N°46", "codigoEscuela": "5" },
    { "circuitoElectoral": 261, "nombreEscuela": "ESCUELA EP N°53", "codigoEscuela": "6" },
    { "circuitoElectoral": 262, "nombreEscuela": "ESCUELA EP N°30/ES N°5", "codigoEscuela": "7" },
    { "circuitoElectoral": 262, "nombreEscuela": "ESCUELA EP N°46/ES N°40", "codigoEscuela": "8" },
    { "circuitoElectoral": 262, "nombreEscuela": "ESCUELA EP N°66", "codigoEscuela": "9" },
    { "circuitoElectoral": 262, "nombreEscuela": "ESCUELA ES N°11", "codigoEscuela": "10" },
    { "circuitoElectoral": 262, "nombreEscuela": "SOC.DE FOMENTO M.M.GUEMES", "codigoEscuela": "11" },
    { "circuitoElectoral": 263, "nombreEscuela": "ESCUELA EP N°42/ES N°45", "codigoEscuela": "12" },
    { "circuitoElectoral": 263, "nombreEscuela": "ESCUELA EST N°6", "codigoEscuela": "13" },
    { "circuitoElectoral": 265, "nombreEscuela": "ESCUELA EP N°45", "codigoEscuela": "14" },
    { "circuitoElectoral": 265, "nombreEscuela": "INSTITUTO SAN AGUSTIN(ES)", "codigoEscuela": "15" },
    { "circuitoElectoral": 271, "nombreEscuela": "ESCUELA EP N°8/ES N°15 ANEXO", "codigoEscuela": "16" },
    { "circuitoElectoral": 272, "nombreEscuela": "ESCUELA EP N°20/ES N°1", "codigoEscuela": "17" },
    { "circuitoElectoral": 272, "nombreEscuela": "ESCUELA EP N°3/EST N°2", "codigoEscuela": "18" },
    { "circuitoElectoral": 273, "nombreEscuela": "ESCUELA EP N°49", "codigoEscuela": "19" },
    { "circuitoElectoral": 273, "nombreEscuela": "ESCUELA EP N°59/ESB N°58", "codigoEscuela": "20" },
    { "circuitoElectoral": 273, "nombreEscuela": "ESCUELA EP N°64/ESB N°61", "codigoEscuela": "21" },
    { "circuitoElectoral": 273, "nombreEscuela": "ESCUELA ES N°6", "codigoEscuela": "22" },
    { "circuitoElectoral": 273, "nombreEscuela": "ESCUELA EST N°4", "codigoEscuela": "23" },
    { "circuitoElectoral": 274, "nombreEscuela": "CENTRO EDUCATIVO N°7 JUANA MANSO", "codigoEscuela": "24" },
    { "circuitoElectoral": 274, "nombreEscuela": "ESCUELA EP N°26", "codigoEscuela": "25" },
    { "circuitoElectoral": 274, "nombreEscuela": "INSTITUTO REMEDIOS DE ESCALADA", "codigoEscuela": "26" },
    { "circuitoElectoral": 275, "nombreEscuela": "ESCUELA EP N°55/ES N°51", "codigoEscuela": "27" },
    { "circuitoElectoral": 275, "nombreEscuela": "INSTITUTO MAGDALENA ABRAIN", "codigoEscuela": "28" },
    { "circuitoElectoral": 276, "nombreEscuela": "COLEGIO JOSE M.ESTRADA", "codigoEscuela": "29" },
    { "circuitoElectoral": 276, "nombreEscuela": "ESCUELA EP N°14", "codigoEscuela": "30" }
];

//MESA TESTIGO
export const ESCUELAS_LANUS_MESA_TESTIGO = [
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 259, "nombreEscuela": "COLEGIO LA ASUNCION", "codigoEscuela": "LANMT1", "mesa": 17, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 260, "nombreEscuela": "ESCUELA EP N°27", "codigoEscuela": "LANMT2", "mesa": 109, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 260, "nombreEscuela": "ESCUELA EP N°6 ES N°28", "codigoEscuela": "LANMT3", "mesa": 49, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 260, "nombreEscuela": "ESCUELA EP N°7", "codigoEscuela": "LANMT4", "mesa": 58, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 260, "nombreEscuela": "ESCUELA ES N°3", "codigoEscuela": "LANMT5", "mesa": 90, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 260, "nombreEscuela": "ESCUELA ES N°7", "codigoEscuela": "LANMT6", "mesa": 51, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 260, "nombreEscuela": "INSTITUTO SANTO CRISTO", "codigoEscuela": "LANMT7", "mesa": 42, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 261, "nombreEscuela": "ESCUELA EP N°50 ES N°32", "codigoEscuela": "LANMT8", "mesa": 198, "expansor": 37.77777777777778 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 261, "nombreEscuela": "ESCUELA EP N°53", "codigoEscuela": "LANMT9", "mesa": 192, "expansor": 37.77777777777778 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 262, "nombreEscuela": "CENTRO DE FORMAC.PROFES.N°401", "codigoEscuela": "LANMT10", "mesa": 350, "expansor": 35.333333333333336 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 262, "nombreEscuela": "ESCUELA EP N°37", "codigoEscuela": "LANMT11", "mesa": 292, "expansor": 35.333333333333336 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 262, "nombreEscuela": "ESCUELA EP N°39 ES N°37", "codigoEscuela": "LANMT12", "mesa": 334, "expansor": 35.333333333333336 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 262, "nombreEscuela": "ESCUELA EP N°63 ES N°42", "codigoEscuela": "LANMT13", "mesa": 273, "expansor": 35.333333333333336 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 262, "nombreEscuela": "ESCUELA MEDALLA MILAGROSA", "codigoEscuela": "LANMT14", "mesa": 228, "expansor": 35.333333333333336 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 262, "nombreEscuela": "JARDIN DE INF.NTRA .SRA.DE LA CONSOLACION", "codigoEscuela": "LANMT15", "mesa": 302, "expansor": 35.333333333333336 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 262, "nombreEscuela": "SOC.DE FOMENTO VILLA UNION", "codigoEscuela": "LANMT16", "mesa": 374, "expansor": 35.333333333333336 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 263, "nombreEscuela": "ESCUELA EP N°36", "codigoEscuela": "LANMT17", "mesa": 396, "expansor": 37.77777777777778 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 264, "nombreEscuela": "ESCUELA CHARLES CHAPLIN", "codigoEscuela": "LANMT18", "mesa": 468, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 264, "nombreEscuela": "ESCUELA EP N°16", "codigoEscuela": "LANMT19", "mesa": 456, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 265, "nombreEscuela": "ESCUELA EP N°44", "codigoEscuela": "LANMT20", "mesa": 481, "expansor": 37.77777777777778 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 265, "nombreEscuela": "ESCUELA ES N°27", "codigoEscuela": "LANMT21", "mesa": 540, "expansor": 37.77777777777778 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 265, "nombreEscuela": "INSTITUTO SAN AGUSTIN(ES)", "codigoEscuela": "LANMT22", "mesa": 533, "expansor": 37.77777777777778 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 273, "nombreEscuela": "ESCUELA EP N°57 ESB N°21", "codigoEscuela": "LANMT23", "mesa": 775, "expansor": 35.333333333333336 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 273, "nombreEscuela": "ESCUELA ES N°23", "codigoEscuela": "LANMT24", "mesa": 741, "expansor": 35.333333333333336 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 274, "nombreEscuela": "ESCUELA EP N°12 ES N°52", "codigoEscuela": "LANMT25", "mesa": 803, "expansor": 37.77777777777778 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 274, "nombreEscuela": "ESCUELA ES N°18", "codigoEscuela": "LANMT26", "mesa": 866, "expansor": 37.77777777777778 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 274, "nombreEscuela": "ESCUELA SANTA FAZ", "codigoEscuela": "LANMT27", "mesa": 826, "expansor": 37.77777777777778 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 275, "nombreEscuela": "COLEGIO MARIA REINA(EP ES)", "codigoEscuela": "LANMT28", "mesa": 994, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 276, "nombreEscuela": "ESCUELA EP N°11 ESB N°31", "codigoEscuela": "LANMT29", "mesa": 1001, "expansor": 32.33333333333333 },
    { "operativo": "generales-23-lanus", "seccionElectoral": 3, "partido": "Lanús", "circuitoElectoral": 276, "nombreEscuela": "ESCUELA RECONOC.SANTA TERESA", "codigoEscuela": "LANMT30", "mesa": 1063, "expansor": 32.33333333333333 }
]

//MESA TESTIGO
export const ESCUELAS_PROVINCIA = [
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General Rodríguez", "circuitoElectoral": "374", "nombreEscuela": "ESCUELA EP N°1 ES N°6", "codigoEscuela": "PROV1", "mesa": 7, "expansor": 143.54354354354354 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General Rodríguez", "circuitoElectoral": "374", "nombreEscuela": "ESCUELA NTRA.SRA.DE LUJAN", "codigoEscuela": "PROV2", "mesa": 26, "expansor": 143.54354354354354 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General Rodríguez", "circuitoElectoral": "374", "nombreEscuela": "ESCUELA ES N°2", "codigoEscuela": "PROV3", "mesa": 54, "expansor": 143.54354354354354 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General Rodríguez", "circuitoElectoral": "374", "nombreEscuela": "NUEVA ESCUELA DEL SUR", "codigoEscuela": "PROV4", "mesa": 79, "expansor": 143.54354354354354 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General Rodríguez", "circuitoElectoral": "374", "nombreEscuela": "ESCUELA EP N°9", "codigoEscuela": "PROV5", "mesa": 87, "expansor": 143.54354354354354 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General Rodríguez", "circuitoElectoral": "374", "nombreEscuela": "ESCUELA EP N°12", "codigoEscuela": "PROV6", "mesa": 149, "expansor": 143.54354354354354 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General Rodríguez", "circuitoElectoral": "374", "nombreEscuela": "ESCUELA EP N°14", "codigoEscuela": "PROV7", "mesa": 152, "expansor": 143.54354354354354 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General Rodríguez", "circuitoElectoral": "374", "nombreEscuela": "COLEGIO P.A.C.(EP Y ES)", "codigoEscuela": "PROV8", "mesa": 193, "expansor": 143.54354354354354 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General Rodríguez", "circuitoElectoral": "374", "nombreEscuela": "ESCUELA EP N°11", "codigoEscuela": "PROV9", "mesa": 203, "expansor": 143.54354354354354 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668B", "nombreEscuela": "ESCUELA EP N°21 ES N°21", "codigoEscuela": "PROV10", "mesa": 296, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668B", "nombreEscuela": "ESCUELA EP N°23", "codigoEscuela": "PROV11", "mesa": 340, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668B", "nombreEscuela": "ESCUELA EST N°1", "codigoEscuela": "PROV12", "mesa": 364, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668B", "nombreEscuela": "ESCUELA EP N°13", "codigoEscuela": "PROV13", "mesa": 383, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668B", "nombreEscuela": "INSTITUTO SAN JOSE DE CALASANZ", "codigoEscuela": "PROV14", "mesa": 386, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668B", "nombreEscuela": "INSTITUTO SANTA MARTA", "codigoEscuela": "PROV15", "mesa": 428, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668B", "nombreEscuela": "ESCUELA EDUCAC.ESPECIAL N°502", "codigoEscuela": "PROV16", "mesa": 434, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668A", "nombreEscuela": "JARDIN DE INF.MUNICIPAL N°1", "codigoEscuela": "PROV17", "mesa": 178, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668A", "nombreEscuela": "INSTITUTO LINCOLN", "codigoEscuela": "PROV18", "mesa": 197, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668A", "nombreEscuela": "ESCUELA EP N°27 ES N°22", "codigoEscuela": "PROV19", "mesa": 209, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "0668A", "nombreEscuela": "JARDIN DE INFANTES N°903", "codigoEscuela": "PROV20", "mesa": 272, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "668", "nombreEscuela": "COLEGIO SAN FERNANDO", "codigoEscuela": "PROV21", "mesa": 25, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "668", "nombreEscuela": "ESCUELA EP N°10", "codigoEscuela": "PROV22", "mesa": 58, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "668", "nombreEscuela": "ESCUELA EP N°8 ES N°18", "codigoEscuela": "PROV23", "mesa": 65, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "668", "nombreEscuela": "ESCUELA ES N°3 ES N°5", "codigoEscuela": "PROV24", "mesa": 90, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "668", "nombreEscuela": "ESCUELA ES N°6", "codigoEscuela": "PROV25", "mesa": 142, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Hurlingham", "circuitoElectoral": "668", "nombreEscuela": "ESCUELA EP N°14", "codigoEscuela": "PROV26", "mesa": 149, "expansor": 115.88009925558313 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "0666A", "nombreEscuela": "INSTITUTO NTRA.SRA.DE LOURDES", "codigoEscuela": "PROV27", "mesa": 193, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "667", "nombreEscuela": "ESCUELA 24 DE OCTUBRE", "codigoEscuela": "PROV28", "mesa": 255, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "667", "nombreEscuela": "ESCUELA EP N°1 ES N°13", "codigoEscuela": "PROV29", "mesa": 266, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "667", "nombreEscuela": "ESCUELA EP N°2", "codigoEscuela": "PROV30", "mesa": 289, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "667", "nombreEscuela": "ESC.DEL SAGR.CORAZON DE JESUS", "codigoEscuela": "PROV31", "mesa": 330, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "667", "nombreEscuela": "ESCUELA EP N°3", "codigoEscuela": "PROV32", "mesa": 340, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "667", "nombreEscuela": "ESCUELA EP N°3", "codigoEscuela": "PROV33", "mesa": 341, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "667", "nombreEscuela": "COLEGIO MADRE SOFIA BUNGE", "codigoEscuela": "PROV34", "mesa": 360, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "667", "nombreEscuela": "COLEGIO NUESTRA SRA.DE BELEN", "codigoEscuela": "PROV35", "mesa": 376, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "667", "nombreEscuela": "ESCUELA MODELO GRAL.BELGRANO", "codigoEscuela": "PROV36", "mesa": 390, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "666", "nombreEscuela": "ESCUELA NUESTRA SRA.DEL PILAR", "codigoEscuela": "PROV37", "mesa": 50, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "666", "nombreEscuela": "ESCUELA EP N°8 ES N°9", "codigoEscuela": "PROV38", "mesa": 52, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "666", "nombreEscuela": "ESCUELA ES N°6", "codigoEscuela": "PROV39", "mesa": 63, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "666", "nombreEscuela": "ESCUELA EP N°15", "codigoEscuela": "PROV40", "mesa": 69, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "666", "nombreEscuela": "ESCUELA ES N°4", "codigoEscuela": "PROV41", "mesa": 77, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "666", "nombreEscuela": "COLEGIO TOMAS EDISON S.R.L.", "codigoEscuela": "PROV42", "mesa": 100, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Ituziangó", "circuitoElectoral": "666", "nombreEscuela": "#N A", "codigoEscuela": "PROV43", "mesa": 153, "expansor": 117.77977301387139 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0664B", "nombreEscuela": "ESCUELA EP N°80", "codigoEscuela": "PROV44", "mesa": 924, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0664B", "nombreEscuela": "ESCUELA EM N°14", "codigoEscuela": "PROV45", "mesa": 963, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0664B", "nombreEscuela": "ESCUELA NTRA.SRA.DEL CARMEN", "codigoEscuela": "PROV46", "mesa": 978, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0664A", "nombreEscuela": "ESCUELA EP N°46", "codigoEscuela": "PROV47", "mesa": 852, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0664A", "nombreEscuela": "ESCUELA EP N°23 ES N°35", "codigoEscuela": "PROV48", "mesa": 872, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0664A", "nombreEscuela": "ESCUELA EP N°76 ES N°65", "codigoEscuela": "PROV49", "mesa": 896, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663D", "nombreEscuela": "ESCUELA ES N°9", "codigoEscuela": "PROV50", "mesa": 680, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663D", "nombreEscuela": "ESCUELA EP N°5 ES N°5 ANEXO", "codigoEscuela": "PROV51", "mesa": 746, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663D", "nombreEscuela": "ESCUELA EP N°5 ES N°5 ANEXO", "codigoEscuela": "PROV52", "mesa": 748, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663C", "nombreEscuela": "ESCUELA EP N°45 ES N°25", "codigoEscuela": "PROV53", "mesa": 443, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663C", "nombreEscuela": "ESCUELA EP N°11", "codigoEscuela": "PROV54", "mesa": 458, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663C", "nombreEscuela": "ESCUELA EP N°8 ES N°32", "codigoEscuela": "PROV55", "mesa": 509, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663C", "nombreEscuela": "ESCUELA EP N°68 ES N°58", "codigoEscuela": "PROV56", "mesa": 518, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663C", "nombreEscuela": "ESCUELA EP N°49", "codigoEscuela": "PROV57", "mesa": 535, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663C", "nombreEscuela": "ESCUELA EP N°54 ES N°59", "codigoEscuela": "PROV58", "mesa": 552, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663C", "nombreEscuela": "ESCUELA EP N°70 ES N°28", "codigoEscuela": "PROV59", "mesa": 575, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663C", "nombreEscuela": "ESCUELA ES N°22", "codigoEscuela": "PROV60", "mesa": 589, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663C", "nombreEscuela": "ESCUELA EP N°60 ES N°51", "codigoEscuela": "PROV61", "mesa": 623, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663B", "nombreEscuela": "ESCUELA EP N°36 ES N°76", "codigoEscuela": "PROV62", "mesa": 269, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663B", "nombreEscuela": "ESCUELA EP N°28 ES N°62", "codigoEscuela": "PROV63", "mesa": 274, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663B", "nombreEscuela": "ESCUELA EP N°6 ES N°78", "codigoEscuela": "PROV64", "mesa": 297, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663B", "nombreEscuela": "COLEGIO AMANCIO ALCORTA", "codigoEscuela": "PROV65", "mesa": 349, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663B", "nombreEscuela": "ESCUELA EP N°74 ES N°79", "codigoEscuela": "PROV66", "mesa": 377, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663B", "nombreEscuela": "ESCUELA ES N°23", "codigoEscuela": "PROV67", "mesa": 418, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663A", "nombreEscuela": "ESCUELA ES N°3", "codigoEscuela": "PROV68", "mesa": 143, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663A", "nombreEscuela": "INSTITUTO TIBERIO BOTTO", "codigoEscuela": "PROV69", "mesa": 158, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663A", "nombreEscuela": "INSTITUTO TIBERIO BOTTO", "codigoEscuela": "PROV70", "mesa": 161, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "0663A", "nombreEscuela": "ESCUELA ES N°31", "codigoEscuela": "PROV71", "mesa": 163, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "664", "nombreEscuela": "ESCUELA EP N°10 ES N°44", "codigoEscuela": "PROV72", "mesa": 757, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "664", "nombreEscuela": "ESCUELA S.FCO.DE ASIS(EP Y ES)", "codigoEscuela": "PROV73", "mesa": 768, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "664", "nombreEscuela": "ESCUELA EP N°15", "codigoEscuela": "PROV74", "mesa": 773, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "663", "nombreEscuela": "ESCUELA EP N°1 ES N°39", "codigoEscuela": "PROV75", "mesa": 13, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "663", "nombreEscuela": "ESCUELA ES N°2", "codigoEscuela": "PROV76", "mesa": 37, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "663", "nombreEscuela": "ESCUELA EP N°77", "codigoEscuela": "PROV77", "mesa": 44, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "663", "nombreEscuela": "ESCUELA EP N°38", "codigoEscuela": "PROV78", "mesa": 56, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "663", "nombreEscuela": "ESCUELA EP N°25 ES N°46", "codigoEscuela": "PROV79", "mesa": 73, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "663", "nombreEscuela": "ESCUELA EST N°2", "codigoEscuela": "PROV80", "mesa": 116, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "663", "nombreEscuela": "ESCUELA EST N°2", "codigoEscuela": "PROV81", "mesa": 118, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Moreno", "circuitoElectoral": "663", "nombreEscuela": "ESCUELA EST N°2", "codigoEscuela": "PROV82", "mesa": 119, "expansor": 52.8276923076923 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0772A", "nombreEscuela": "ESCUELA ES N°5", "codigoEscuela": "PROV83", "mesa": 712, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0771D", "nombreEscuela": "ESCUELA EP N°2 ES N°29 ANEXO", "codigoEscuela": "PROV84", "mesa": 572, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0771D", "nombreEscuela": "ESCUELA EP N°33", "codigoEscuela": "PROV85", "mesa": 595, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0771D", "nombreEscuela": "INSTITUTO ANTARTIDA ARGENTINA", "codigoEscuela": "PROV86", "mesa": 605, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0771C", "nombreEscuela": "ESCUELA EP N°39", "codigoEscuela": "PROV87", "mesa": 519, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0771C", "nombreEscuela": "ESCUELA EP N°39", "codigoEscuela": "PROV88", "mesa": 522, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0771B", "nombreEscuela": "COLEGIO NORBRIDGE", "codigoEscuela": "PROV89", "mesa": 490, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0770D", "nombreEscuela": "ESCUELA EP N°22", "codigoEscuela": "PROV90", "mesa": 321, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0770D", "nombreEscuela": "ESCUELA EP N°15 ES N°14", "codigoEscuela": "PROV91", "mesa": 335, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0770D", "nombreEscuela": "ESCUELA EP N°20 ES N°16", "codigoEscuela": "PROV92", "mesa": 339, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0770D", "nombreEscuela": "ESCUELA EP N°31 ES N°31", "codigoEscuela": "PROV93", "mesa": 349, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0770D", "nombreEscuela": "ESCUELA ES N°2", "codigoEscuela": "PROV94", "mesa": 359, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0770A", "nombreEscuela": "ESCUELA SAN JUAN", "codigoEscuela": "PROV95", "mesa": 277, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0768C", "nombreEscuela": "ESCUELA EP N°35", "codigoEscuela": "PROV96", "mesa": 185, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0768C", "nombreEscuela": "COLEGIO ALMAFUERTE I.S.F.D", "codigoEscuela": "PROV97", "mesa": 199, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0768B", "nombreEscuela": "INSTITUTO ATLETICO PILAR", "codigoEscuela": "PROV98", "mesa": 137, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0768B", "nombreEscuela": "CENTRO EDUCATIVO SAN JOSE", "codigoEscuela": "PROV99", "mesa": 152, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0768A", "nombreEscuela": "ESCUELA EP N°26 ES N°19", "codigoEscuela": "PROV100", "mesa": 82, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "0768A", "nombreEscuela": "ESCUELA EP N°26 ES N°19", "codigoEscuela": "PROV101", "mesa": 87, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "772", "nombreEscuela": "COLEGIO MAGNO", "codigoEscuela": "PROV102", "mesa": 660, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "772", "nombreEscuela": "COLEGIO MAGNO", "codigoEscuela": "PROV103", "mesa": 668, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "772", "nombreEscuela": "COLEGIO SOLAR DEL PILAR", "codigoEscuela": "PROV104", "mesa": 684, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "771", "nombreEscuela": "COLEGIO INMACULADA CONCEPCION", "codigoEscuela": "PROV105", "mesa": 449, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "771", "nombreEscuela": "COLEGIO INMACULADA CONCEPCION", "codigoEscuela": "PROV106", "mesa": 450, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "769", "nombreEscuela": "ESCUELA EP N°19   ES N°17", "codigoEscuela": "PROV107", "mesa": 251, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "768", "nombreEscuela": "COLEGIO N.SRA.DE LA PAZ(EP ES)", "codigoEscuela": "PROV108", "mesa": 10, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Pilar", "circuitoElectoral": "768", "nombreEscuela": "ESCUELA EP N°40", "codigoEscuela": "PROV109", "mesa": 58, "expansor": 69.08236686390534 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "0892A", "nombreEscuela": "COLEGIO SAN CAYETANO", "codigoEscuela": "PROV110", "mesa": 531, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "0892A", "nombreEscuela": "ESCUELA ES N°7", "codigoEscuela": "PROV111", "mesa": 542, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "0892A", "nombreEscuela": "ESCUELA EP N°30 ES N°19", "codigoEscuela": "PROV112", "mesa": 577, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "0892A", "nombreEscuela": "ESCUELA ES N°11", "codigoEscuela": "PROV113", "mesa": 595, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "895", "nombreEscuela": "INSTITUTO EDUCATIVO FATIMA", "codigoEscuela": "PROV114", "mesa": 738, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "895", "nombreEscuela": "INSTITUTO EDUCATIVO FATIMA", "codigoEscuela": "PROV115", "mesa": 739, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "895", "nombreEscuela": "JARDIN DE INFANTES N°902", "codigoEscuela": "PROV116", "mesa": 782, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "894", "nombreEscuela": "COLEGIO SANTA INES", "codigoEscuela": "PROV117", "mesa": 723, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "893", "nombreEscuela": "ESC.EDUC.SEC.STO.DOMINGO SAVIO", "codigoEscuela": "PROV118", "mesa": 668, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "893", "nombreEscuela": "COLEGIO DE LOS PEREGRINOS", "codigoEscuela": "PROV119", "mesa": 688, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "893", "nombreEscuela": "JARDIN DE INFANTES JUAN DIEGO", "codigoEscuela": "PROV120", "mesa": 706, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "892", "nombreEscuela": "COLEGIO NUEVO DE LAS LOMAS", "codigoEscuela": "PROV121", "mesa": 328, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "892", "nombreEscuela": "INSTITUTO 25 DE MAYO", "codigoEscuela": "PROV122", "mesa": 345, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "892", "nombreEscuela": "COL.PARRO.NTRA.SRA.DEL REFUGIO", "codigoEscuela": "PROV123", "mesa": 381, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "892", "nombreEscuela": "ESCUELA ES N°14", "codigoEscuela": "PROV124", "mesa": 408, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "892", "nombreEscuela": "INSTITUTO CARDENAL NEWMAN (ES)", "codigoEscuela": "PROV125", "mesa": 460, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "892", "nombreEscuela": "ESCUELA EP N°27", "codigoEscuela": "PROV126", "mesa": 470, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "892", "nombreEscuela": "ESCUELA EP N°27", "codigoEscuela": "PROV127", "mesa": 472, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "891", "nombreEscuela": "ESCUELA EP N°9", "codigoEscuela": "PROV128", "mesa": 196, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "891", "nombreEscuela": "CENTRO REGIONAL NORTE UBA", "codigoEscuela": "PROV129", "mesa": 248, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "891", "nombreEscuela": "UNIVERSIDAD KENNEDY", "codigoEscuela": "PROV130", "mesa": 258, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "891", "nombreEscuela": "ESCUELA EP N°22", "codigoEscuela": "PROV131", "mesa": 265, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "891", "nombreEscuela": "ESCUELA REPUBLICA ARGENTINA", "codigoEscuela": "PROV132", "mesa": 309, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "891", "nombreEscuela": "EL JARDIN DE LOS NARANJOS", "codigoEscuela": "PROV133", "mesa": 310, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "889", "nombreEscuela": "COLEGIO MARIN", "codigoEscuela": "PROV134", "mesa": 108, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "889", "nombreEscuela": "ESCUELA EP N°3", "codigoEscuela": "PROV135", "mesa": 124, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "889", "nombreEscuela": "ESCUELA EP N°3", "codigoEscuela": "PROV136", "mesa": 125, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "888", "nombreEscuela": "ESCUELA EP N°2 ESB N°3", "codigoEscuela": "PROV137", "mesa": 46, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "888", "nombreEscuela": "COLEGIO CARDENAL SPINOLA", "codigoEscuela": "PROV138", "mesa": 70, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "San Isidro", "circuitoElectoral": "888", "nombreEscuela": "COLEGIO SANTA TRINIDAD", "codigoEscuela": "PROV139", "mesa": 85, "expansor": 55.69431127012523 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "0377A", "nombreEscuela": "ESCUELA ES N°28 EP N°44", "codigoEscuela": "PROV140", "mesa": 191, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "0377A", "nombreEscuela": "INSTITUTO CONCORDIA", "codigoEscuela": "PROV141", "mesa": 237, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "0377A", "nombreEscuela": "ESCUELA EP N°50 ES N°2", "codigoEscuela": "PROV142", "mesa": 258, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "0377A", "nombreEscuela": "ESCUELA EP N°65 ES N°38", "codigoEscuela": "PROV143", "mesa": 275, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "0377A", "nombreEscuela": "INST.DE ENS.ALMAFUERTE S.R.L", "codigoEscuela": "PROV144", "mesa": 292, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "0377A", "nombreEscuela": "ESCUELA ES N°8", "codigoEscuela": "PROV145", "mesa": 300, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "0377A", "nombreEscuela": "ESCUELA EP N°51 ES N°47", "codigoEscuela": "PROV146", "mesa": 317, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "388", "nombreEscuela": "ESCUELA EP N°6", "codigoEscuela": "PROV147", "mesa": 770, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "388", "nombreEscuela": "ESCUELA EP N°6", "codigoEscuela": "PROV148", "mesa": 772, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "388", "nombreEscuela": "JARDIN DE INFANTES N°929", "codigoEscuela": "PROV149", "mesa": 875, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "388", "nombreEscuela": "ESCUELA EP N°37 EPA N°10", "codigoEscuela": "PROV150", "mesa": 903, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "388", "nombreEscuela": "ESCUELA EP N°75 ES N°24", "codigoEscuela": "PROV151", "mesa": 916, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "388", "nombreEscuela": "ESCUELA EP N°8", "codigoEscuela": "PROV152", "mesa": 922, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "388", "nombreEscuela": "JARDIN DE INFANTES N°913", "codigoEscuela": "PROV153", "mesa": 933, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "388", "nombreEscuela": "JARDIN DE INFANTES N°924", "codigoEscuela": "PROV154", "mesa": 948, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "383", "nombreEscuela": "ESCUELA EP N°34 ES N°48", "codigoEscuela": "PROV155", "mesa": 687, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "383", "nombreEscuela": "ESCUELA EP N°19", "codigoEscuela": "PROV156", "mesa": 696, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "382", "nombreEscuela": "C.E.B.A.S. Nº2 EDUCADOR PAULO FREIRE", "codigoEscuela": "PROV157", "mesa": 684, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "381", "nombreEscuela": "ESCUELA EP N°7 ESB N°2 ES N°7", "codigoEscuela": "PROV158", "mesa": 543, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "381", "nombreEscuela": "ESCUELA EP N°24  ES N°51", "codigoEscuela": "PROV159", "mesa": 566, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "381", "nombreEscuela": "INSTITUTO BALLESTER", "codigoEscuela": "PROV160", "mesa": 609, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "381", "nombreEscuela": "COLEGIO INCORP.SAN ANDRES", "codigoEscuela": "PROV161", "mesa": 624, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "381", "nombreEscuela": "COLEGIO NTRA.SRA.DE LUJAN", "codigoEscuela": "PROV162", "mesa": 627, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "381", "nombreEscuela": "ESCUELA ES N°26 EP N°21", "codigoEscuela": "PROV163", "mesa": 641, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "379", "nombreEscuela": "ESCUELA EP N°22 ES N°6", "codigoEscuela": "PROV164", "mesa": 443, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "379", "nombreEscuela": "ESCUELA EP N°22 ES N°6", "codigoEscuela": "PROV165", "mesa": 445, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "378", "nombreEscuela": "ESCUELA EP N°36 ES N°3", "codigoEscuela": "PROV166", "mesa": 337, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "378", "nombreEscuela": "ESCUELA EP N°36 ES N°3", "codigoEscuela": "PROV167", "mesa": 338, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "378", "nombreEscuela": "ESCUELA EP N°16", "codigoEscuela": "PROV168", "mesa": 392, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "378", "nombreEscuela": "ESCUELA EP N°16", "codigoEscuela": "PROV169", "mesa": 394, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "377", "nombreEscuela": "INSTITUTO SANTA ANA", "codigoEscuela": "PROV170", "mesa": 86, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "377", "nombreEscuela": "ESCUELA ES N°13", "codigoEscuela": "PROV171", "mesa": 113, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "377", "nombreEscuela": "ESCUELA EP N°69", "codigoEscuela": "PROV172", "mesa": 159, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "377", "nombreEscuela": "CLUB SOCIAL Y DEP.LAS HERAS", "codigoEscuela": "PROV173", "mesa": 174, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "376", "nombreEscuela": "ESCUELA EP N°1 ES N°49", "codigoEscuela": "PROV174", "mesa": 29, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "General San Martín", "circuitoElectoral": "376", "nombreEscuela": "ESCUELA ES N°1", "codigoEscuela": "PROV175", "mesa": 71, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0655A", "nombreEscuela": "ESCUELA EP N°21 ES N°47", "codigoEscuela": "PROV176", "mesa": 1019, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0655A", "nombreEscuela": "ESCUELA EP N°15 ESB N°29", "codigoEscuela": "PROV177", "mesa": 1051, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0655A", "nombreEscuela": "ESCUELA EP N°40 ES N°50", "codigoEscuela": "PROV178", "mesa": 1079, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0655A", "nombreEscuela": "ESCUELA EP N°18", "codigoEscuela": "PROV179", "mesa": 1147, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA ES N°13", "codigoEscuela": "PROV180", "mesa": 178, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EST N°4", "codigoEscuela": "PROV181", "mesa": 193, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EP N°13", "codigoEscuela": "PROV182", "mesa": 202, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EP N°25", "codigoEscuela": "PROV183", "mesa": 277, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EP N°25", "codigoEscuela": "PROV184", "mesa": 279, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "#N A", "codigoEscuela": "PROV185", "mesa": 334, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EP N°34", "codigoEscuela": "PROV186", "mesa": 337, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EP N°66 ES N°30", "codigoEscuela": "PROV187", "mesa": 358, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EP N°66 ES N°30", "codigoEscuela": "PROV188", "mesa": 362, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EP N°12", "codigoEscuela": "PROV189", "mesa": 370, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EP N°42", "codigoEscuela": "PROV190", "mesa": 410, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA SOLAR DE HORNEROS", "codigoEscuela": "PROV191", "mesa": 456, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA ESB N°10", "codigoEscuela": "PROV192", "mesa": 505, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EP N°65 ES N°72", "codigoEscuela": "PROV193", "mesa": 506, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA ES N°45", "codigoEscuela": "PROV194", "mesa": 530, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "0652A", "nombreEscuela": "ESCUELA EDUC.INTEGRAL SIEMBRA", "codigoEscuela": "PROV195", "mesa": 546, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "655", "nombreEscuela": "INSTITUTO D.F.SARMIENTO", "codigoEscuela": "PROV196", "mesa": 881, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "655", "nombreEscuela": "ESCUELA EP N°9 ES N°42", "codigoEscuela": "PROV197", "mesa": 899, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "655", "nombreEscuela": "ESCUELA EP N°10 ESB N°42", "codigoEscuela": "PROV198", "mesa": 923, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "655", "nombreEscuela": "ESCUELA EP N°19 ES N°35", "codigoEscuela": "PROV199", "mesa": 946, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "655", "nombreEscuela": "ESCUELA EP N°28 ES N°24", "codigoEscuela": "PROV200", "mesa": 1003, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "655", "nombreEscuela": "ESCUELA EP N°28 ES N°24", "codigoEscuela": "PROV201", "mesa": 1007, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "654", "nombreEscuela": "ESCUELA EP N°4", "codigoEscuela": "PROV202", "mesa": 772, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "654", "nombreEscuela": "ESCUELA EP N°55", "codigoEscuela": "PROV203", "mesa": 781, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "654", "nombreEscuela": "ESCUELA EP N°58 ES N°28", "codigoEscuela": "PROV204", "mesa": 841, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "654", "nombreEscuela": "ESCUELA EP N°70 ESB N°60", "codigoEscuela": "PROV205", "mesa": 845, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "653", "nombreEscuela": "ESCUELA EP N°7", "codigoEscuela": "PROV206", "mesa": 619, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "653", "nombreEscuela": "ESCUELA SAN JOSE EP ES", "codigoEscuela": "PROV207", "mesa": 641, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "653", "nombreEscuela": "ESCUELA EP N°52 ES N°40", "codigoEscuela": "PROV208", "mesa": 688, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "653", "nombreEscuela": "ESCUELA EP N°67", "codigoEscuela": "PROV209", "mesa": 706, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "653", "nombreEscuela": "ESCUELA EP N°63", "codigoEscuela": "PROV210", "mesa": 735, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "653", "nombreEscuela": "JARDIN DE INFANTES N°927", "codigoEscuela": "PROV211", "mesa": 755, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "652", "nombreEscuela": "ESCUELA EP N°47 ES N°22", "codigoEscuela": "PROV212", "mesa": 16, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "652", "nombreEscuela": "ESCUELA EP N°47 ES N°22", "codigoEscuela": "PROV213", "mesa": 19, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "652", "nombreEscuela": "ESCUELA EP N°3", "codigoEscuela": "PROV214", "mesa": 30, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "652", "nombreEscuela": "ESCUELA EP N°44 ES N°26", "codigoEscuela": "PROV215", "mesa": 97, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "652", "nombreEscuela": "ESCUELA ES N°32", "codigoEscuela": "PROV216", "mesa": 123, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "652", "nombreEscuela": "ESCUELA ES N°16", "codigoEscuela": "PROV217", "mesa": 143, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "652", "nombreEscuela": "ESCUELA ES N°16", "codigoEscuela": "PROV218", "mesa": 144, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 1, "partido": "Merlo", "circuitoElectoral": "652", "nombreEscuela": "JARDIN DE INFANTES ALMAFUERTE", "codigoEscuela": "PROV219", "mesa": 167, "expansor": 25.425 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "Arrecifes", "circuitoElectoral": "117", "nombreEscuela": "ESCUELA ES N°2", "codigoEscuela": "PROV630", "mesa": 14, "expansor": 410.10294117647067 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "Arrecifes", "circuitoElectoral": "117", "nombreEscuela": "ESCUELA EST N°1 ES N°1", "codigoEscuela": "PROV631", "mesa": 22, "expansor": 410.10294117647067 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "0901B", "nombreEscuela": "ESCUELA EP N°29", "codigoEscuela": "PROV643", "mesa": 149, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "0901B", "nombreEscuela": "ESCUELA EP N°40 ES N°13 ANEXO", "codigoEscuela": "PROV644", "mesa": 169, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "911", "nombreEscuela": "ESCUELA EST N°5", "codigoEscuela": "PROV645", "mesa": 351, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "905", "nombreEscuela": "JARDIN DE INFANTES Nº918 EJERCITO DE LOS ANDES", "codigoEscuela": "PROV646", "mesa": 324, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "904", "nombreEscuela": "INST.TECNOLOGICO R.DE AGUIAR AUDITORIO", "codigoEscuela": "PROV647", "mesa": 267, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "904", "nombreEscuela": "JARDIN DE INFANTES Nº906", "codigoEscuela": "PROV648", "mesa": 294, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "903", "nombreEscuela": "ESCUELA EP N°19", "codigoEscuela": "PROV649", "mesa": 222, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "901", "nombreEscuela": "ESCUELA EP N°1", "codigoEscuela": "PROV650", "mesa": 50, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "901", "nombreEscuela": "ESCUELA EP N°4 ES N°26", "codigoEscuela": "PROV651", "mesa": 81, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "San Nicolás", "circuitoElectoral": "901", "nombreEscuela": "ESCUELA EP N°12 ES N°13", "codigoEscuela": "PROV652", "mesa": 90, "expansor": 133.64166666666665 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "Zárate", "circuitoElectoral": "1021", "nombreEscuela": "ESCUELA EP N°17", "codigoEscuela": "PROV653", "mesa": 279, "expansor": 163.64285714285714 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "Zárate", "circuitoElectoral": "1017", "nombreEscuela": "C.E.C WOLF SCHCOLNIK", "codigoEscuela": "PROV654", "mesa": 184, "expansor": 163.64285714285714 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "Zárate", "circuitoElectoral": "1016", "nombreEscuela": "ESCUELA EP N°37", "codigoEscuela": "PROV655", "mesa": 155, "expansor": 163.64285714285714 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "Zárate", "circuitoElectoral": "1014", "nombreEscuela": "ESCUELA EP N°26 ES N°16", "codigoEscuela": "PROV656", "mesa": 67, "expansor": 163.64285714285714 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "Zárate", "circuitoElectoral": "1014", "nombreEscuela": "ESCUELA EP N°26 ES N°16", "codigoEscuela": "PROV657", "mesa": 68, "expansor": 163.64285714285714 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "Zárate", "circuitoElectoral": "1014", "nombreEscuela": "ESCUELA EP N°26 ES N°16", "codigoEscuela": "PROV658", "mesa": 73, "expansor": 163.64285714285714 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 2, "partido": "Zárate", "circuitoElectoral": "1013", "nombreEscuela": "ESCUELA EP N°10 ESB N°7", "codigoEscuela": "PROV659", "mesa": 30, "expansor": 163.64285714285714 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ensenada", "circuitoElectoral": "0491A", "nombreEscuela": "ESCUELA EP N°6", "codigoEscuela": "PROV220", "mesa": 129, "expansor": 73.21333333333334 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ensenada", "circuitoElectoral": "0491A", "nombreEscuela": "ESCUELA EP N°6", "codigoEscuela": "PROV221", "mesa": 131, "expansor": 73.21333333333334 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ensenada", "circuitoElectoral": "0491A", "nombreEscuela": "ESCUELA ES N°2", "codigoEscuela": "PROV222", "mesa": 140, "expansor": 73.21333333333334 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ensenada", "circuitoElectoral": "0491A", "nombreEscuela": "JARDIN DE INFANTES N°905", "codigoEscuela": "PROV223", "mesa": 158, "expansor": 73.21333333333334 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ensenada", "circuitoElectoral": "491", "nombreEscuela": "ESCUELA ES N°1", "codigoEscuela": "PROV224", "mesa": 47, "expansor": 73.21333333333334 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ensenada", "circuitoElectoral": "491", "nombreEscuela": "ESCUELA EP N°2 ES N°9", "codigoEscuela": "PROV225", "mesa": 60, "expansor": 73.21333333333334 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ensenada", "circuitoElectoral": "491", "nombreEscuela": "ESCUELA EP N°3", "codigoEscuela": "PROV226", "mesa": 66, "expansor": 73.21333333333334 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ensenada", "circuitoElectoral": "490", "nombreEscuela": "ESCUELA EP N°5 ES N°4", "codigoEscuela": "PROV227", "mesa": 2, "expansor": 73.21333333333334 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ensenada", "circuitoElectoral": "490", "nombreEscuela": "ESCUELA EP N°14 ES N°10", "codigoEscuela": "PROV228", "mesa": 19, "expansor": 73.21333333333334 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "San Vicente", "circuitoElectoral": "925", "nombreEscuela": "COLEGIO MONSENOR A.SCHELL", "codigoEscuela": "PROV229", "mesa": 122, "expansor": 65.36904761904762 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "San Vicente", "circuitoElectoral": "924", "nombreEscuela": "ESCUELA EST N°2", "codigoEscuela": "PROV230", "mesa": 20, "expansor": 65.36904761904762 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "San Vicente", "circuitoElectoral": "924", "nombreEscuela": "ESCUELA ES N°1", "codigoEscuela": "PROV231", "mesa": 35, "expansor": 65.36904761904762 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berisso", "circuitoElectoral": "0513A", "nombreEscuela": "ESCUELA EST N°1", "codigoEscuela": "PROV232", "mesa": 202, "expansor": 43.623803827751196 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berisso", "circuitoElectoral": "0513A", "nombreEscuela": "ESCUELA EST N°1", "codigoEscuela": "PROV233", "mesa": 204, "expansor": 43.623803827751196 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berisso", "circuitoElectoral": "513", "nombreEscuela": "ESCUELA EP N°17", "codigoEscuela": "PROV234", "mesa": 107, "expansor": 43.623803827751196 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berisso", "circuitoElectoral": "513", "nombreEscuela": "ESCUELA EP N°6 ESB N°10", "codigoEscuela": "PROV235", "mesa": 112, "expansor": 43.623803827751196 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berisso", "circuitoElectoral": "513", "nombreEscuela": "JARDIN DE INFANTES Nº910 MANUEL BELGRANO", "codigoEscuela": "PROV236", "mesa": 153, "expansor": 43.623803827751196 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berisso", "circuitoElectoral": "512", "nombreEscuela": "ESCUELA EP N°22", "codigoEscuela": "PROV237", "mesa": 30, "expansor": 43.623803827751196 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berisso", "circuitoElectoral": "512", "nombreEscuela": "ESCUELA EP N°22", "codigoEscuela": "PROV238", "mesa": 31, "expansor": 43.623803827751196 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berisso", "circuitoElectoral": "512", "nombreEscuela": "ESCUELA EP N°5", "codigoEscuela": "PROV239", "mesa": 51, "expansor": 43.623803827751196 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "0290B", "nombreEscuela": "ESCUELA ES N°2", "codigoEscuela": "PROV240", "mesa": 359, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "0290B", "nombreEscuela": "ESCUELA DE EDUC.AGRARIA N°1", "codigoEscuela": "PROV241", "mesa": 373, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "0290B", "nombreEscuela": "JARDIN DE INFANTES PINOCHO", "codigoEscuela": "PROV242", "mesa": 381, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "0290A", "nombreEscuela": "ESCUELA EP N°17", "codigoEscuela": "PROV243", "mesa": 246, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "0290A", "nombreEscuela": "COLEGIO LATINOAMERICANO", "codigoEscuela": "PROV244", "mesa": 314, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "290", "nombreEscuela": "ESCUELA EP N°14 ES N°18", "codigoEscuela": "PROV245", "mesa": 155, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "290", "nombreEscuela": "ESCUELA EP N°14 ES N°18", "codigoEscuela": "PROV246", "mesa": 160, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "290", "nombreEscuela": "ESCUELA DE EDUC.ESPECIAL N°501", "codigoEscuela": "PROV247", "mesa": 170, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "290", "nombreEscuela": "ESCUELA EST N°1", "codigoEscuela": "PROV248", "mesa": 191, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "290", "nombreEscuela": "ESCUELA EP N°5", "codigoEscuela": "PROV249", "mesa": 204, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "289", "nombreEscuela": "CTRO.EDUC.COMPLEMENTARIO N°801", "codigoEscuela": "PROV250", "mesa": 13, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "289", "nombreEscuela": "ESCUELA EP N°10", "codigoEscuela": "PROV251", "mesa": 25, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "289", "nombreEscuela": "COLEGIO SAN IGNACIO (EP ES)", "codigoEscuela": "PROV252", "mesa": 51, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Ezeiza", "circuitoElectoral": "289", "nombreEscuela": "INSTITUTO AMANECER", "codigoEscuela": "PROV253", "mesa": 130, "expansor": 32.50401069518716 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0022B", "nombreEscuela": "ESCUELA EP N°51 ES N°58", "codigoEscuela": "PROV254", "mesa": 1255, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0022A", "nombreEscuela": "ESCUELA ES N°8", "codigoEscuela": "PROV255", "mesa": 1086, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0022A", "nombreEscuela": "ESCUELA ES N°8", "codigoEscuela": "PROV256", "mesa": 1087, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0022A", "nombreEscuela": "ESCUELA ES N°8", "codigoEscuela": "PROV257", "mesa": 1092, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0022A", "nombreEscuela": "INSTITUTO ADOLFO ALSINA(EP ES)", "codigoEscuela": "PROV258", "mesa": 1105, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0022A", "nombreEscuela": "JARDIN DE INFANTES N°932", "codigoEscuela": "PROV259", "mesa": 1135, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0022A", "nombreEscuela": "ESCUELA EP N°75  ES N°47", "codigoEscuela": "PROV260", "mesa": 1185, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0022A", "nombreEscuela": "ESCUELA ES N°4", "codigoEscuela": "PROV261", "mesa": 1205, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0021A", "nombreEscuela": "ESCUELA EP N°31 ES N°25", "codigoEscuela": "PROV262", "mesa": 966, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0020A", "nombreEscuela": "ESCUELA ES N°6", "codigoEscuela": "PROV263", "mesa": 692, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0020A", "nombreEscuela": "ESCUELA ES N°21", "codigoEscuela": "PROV264", "mesa": 707, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0020A", "nombreEscuela": "ESCUELA EP N°55", "codigoEscuela": "PROV265", "mesa": 747, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0018A", "nombreEscuela": "ESCUELA EP N°36", "codigoEscuela": "PROV266", "mesa": 185, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0018A", "nombreEscuela": "ESCUELA EP N°72 ES N°34", "codigoEscuela": "PROV267", "mesa": 211, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0018A", "nombreEscuela": "ESCUELA EP N°72 ES N°34", "codigoEscuela": "PROV268", "mesa": 216, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0018A", "nombreEscuela": "ESCUELA EP N°72 ES N°34", "codigoEscuela": "PROV269", "mesa": 218, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0018A", "nombreEscuela": "ESCUELA EP N°76 ES N°72", "codigoEscuela": "PROV270", "mesa": 230, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "0018A", "nombreEscuela": "ESCUELA CRIST.EVANG.ARGENTINA", "codigoEscuela": "PROV271", "mesa": 276, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "22", "nombreEscuela": "ESCUELA EP N°35", "codigoEscuela": "PROV272", "mesa": 972, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "22", "nombreEscuela": "ESCUELA EST N°2", "codigoEscuela": "PROV273", "mesa": 999, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "22", "nombreEscuela": "ESCUELA ES N°3", "codigoEscuela": "PROV274", "mesa": 1046, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "22", "nombreEscuela": "ESCUELA EP N°69 ES N°68", "codigoEscuela": "PROV275", "mesa": 1060, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "21", "nombreEscuela": "ESCUELA EP N°47 EM N°10", "codigoEscuela": "PROV276", "mesa": 841, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "21", "nombreEscuela": "COLEGIO MARIANO MORENO", "codigoEscuela": "PROV277", "mesa": 844, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "21", "nombreEscuela": "ESCUELA EP N°37 ES N°26", "codigoEscuela": "PROV278", "mesa": 849, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "21", "nombreEscuela": "ESCUELA EP N°60 ES N°64", "codigoEscuela": "PROV279", "mesa": 862, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "21", "nombreEscuela": "JARDIN DE INFANTES N°938", "codigoEscuela": "PROV280", "mesa": 921, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "20", "nombreEscuela": "INST.INMACULADA CONCEPCION", "codigoEscuela": "PROV281", "mesa": 598, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "20", "nombreEscuela": "ESCUELA EP N°13", "codigoEscuela": "PROV282", "mesa": 602, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "20", "nombreEscuela": "COLEGIO SAN JOSE", "codigoEscuela": "PROV283", "mesa": 627, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "20", "nombreEscuela": "COLEGIO SAN JOSE", "codigoEscuela": "PROV284", "mesa": 628, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "19", "nombreEscuela": "ESCUELA DE ADULTOS N°705", "codigoEscuela": "PROV285", "mesa": 347, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "19", "nombreEscuela": "ESCUELA EP N°25", "codigoEscuela": "PROV286", "mesa": 352, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "19", "nombreEscuela": "INSTITUTO DEL SALVADOR", "codigoEscuela": "PROV287", "mesa": 385, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "19", "nombreEscuela": "ESCUELA EP N°19 ESB N°78", "codigoEscuela": "PROV288", "mesa": 410, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "19", "nombreEscuela": "ESCUELA EP N°50 ES N°27", "codigoEscuela": "PROV289", "mesa": 420, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "19", "nombreEscuela": "ESCUELA ES N°9", "codigoEscuela": "PROV290", "mesa": 441, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "19", "nombreEscuela": "ESCUELA ES N°28", "codigoEscuela": "PROV291", "mesa": 519, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "19", "nombreEscuela": "ESCUELA EP N°42 ES N°29", "codigoEscuela": "PROV292", "mesa": 535, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "19", "nombreEscuela": "INSTITUTO CERVANTES", "codigoEscuela": "PROV293", "mesa": 565, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "18", "nombreEscuela": "ESCUELA ES N°31 EP N°7", "codigoEscuela": "PROV294", "mesa": 69, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "18", "nombreEscuela": "JARDIN DE INFANTES N°905", "codigoEscuela": "PROV295", "mesa": 107, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "18", "nombreEscuela": "COLEGIO SECUNDARIO DE LA REINA", "codigoEscuela": "PROV296", "mesa": 148, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "18", "nombreEscuela": "COLEGIO PRIMARIO DE LA REINA", "codigoEscuela": "PROV297", "mesa": 151, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "17", "nombreEscuela": "ESCUELA EP N°5", "codigoEscuela": "PROV298", "mesa": 45, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Almirante Brown", "circuitoElectoral": "17", "nombreEscuela": "COLEGIO IRLANDES", "codigoEscuela": "PROV299", "mesa": 60, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "40", "nombreEscuela": "COLEGIO MODELO SARA ECCLESTON", "codigoEscuela": "PROV300", "mesa": 655, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "40", "nombreEscuela": "COLEGIO SAN MIGUEL ARCANGEL", "codigoEscuela": "PROV301", "mesa": 676, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "40", "nombreEscuela": "JARDIN DE INFANTES N°935", "codigoEscuela": "PROV302", "mesa": 700, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "40", "nombreEscuela": "COLEGIO SAN IGNACIO", "codigoEscuela": "PROV303", "mesa": 703, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "40", "nombreEscuela": "J.DE INFANTES MUNICIPAL N°11", "codigoEscuela": "PROV304", "mesa": 716, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "40", "nombreEscuela": "ESCUELA EP N°55 ES N°14", "codigoEscuela": "PROV305", "mesa": 725, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "40", "nombreEscuela": "ESCUELA EST N°3", "codigoEscuela": "PROV306", "mesa": 767, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "40", "nombreEscuela": "ESCUELA EP N°51 ES N°25", "codigoEscuela": "PROV307", "mesa": 798, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "39", "nombreEscuela": "ESCUELA EP N°16 ESB N°6", "codigoEscuela": "PROV308", "mesa": 489, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "39", "nombreEscuela": "ESCUELA ES N°28", "codigoEscuela": "PROV309", "mesa": 514, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "39", "nombreEscuela": "J.DE INFANTES MUNICIPAL N°38", "codigoEscuela": "PROV310", "mesa": 563, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "39", "nombreEscuela": "ESCUELA EST N°2", "codigoEscuela": "PROV311", "mesa": 572, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "39", "nombreEscuela": "J.DE INFANTES MUNICIPAL N°33", "codigoEscuela": "PROV312", "mesa": 620, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "39", "nombreEscuela": "J.DE INFANTES MUNICIPAL N°33", "codigoEscuela": "PROV313", "mesa": 623, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "38", "nombreEscuela": "ESCUELA SANTO TOMAS", "codigoEscuela": "PROV314", "mesa": 431, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "38", "nombreEscuela": "ESCUELA EP N°22 ES N°1", "codigoEscuela": "PROV315", "mesa": 452, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "38", "nombreEscuela": "ESCUELA EP N°22 ES N°1", "codigoEscuela": "PROV316", "mesa": 457, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "38", "nombreEscuela": "ESCUELA ES N°3", "codigoEscuela": "PROV317", "mesa": 470, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "37", "nombreEscuela": "J.DE INFANTES MUNICIPAL N°4", "codigoEscuela": "PROV318", "mesa": 317, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "37", "nombreEscuela": "J.DE INFANTES MUNICIPAL N°31", "codigoEscuela": "PROV319", "mesa": 323, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "34", "nombreEscuela": "ESCUELA EP N°37", "codigoEscuela": "PROV320", "mesa": 262, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "34", "nombreEscuela": "ESCUELA EP N°40", "codigoEscuela": "PROV321", "mesa": 276, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "32", "nombreEscuela": "JARDIN DE INFANTES N°904", "codigoEscuela": "PROV322", "mesa": 215, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "30", "nombreEscuela": "ESCUELA EP N°14", "codigoEscuela": "PROV323", "mesa": 192, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "27", "nombreEscuela": "ESCUELA EP N°35 ESB N°28", "codigoEscuela": "PROV324", "mesa": 133, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "27", "nombreEscuela": "ESCUELA EP N°67", "codigoEscuela": "PROV325", "mesa": 145, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "27", "nombreEscuela": "COLEGIO CRISTO REY", "codigoEscuela": "PROV326", "mesa": 167, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "26", "nombreEscuela": "JARDIN DE INF.MUNICIPAL N°3", "codigoEscuela": "PROV327", "mesa": 119, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "25", "nombreEscuela": "ESCUELA EP N°31", "codigoEscuela": "PROV328", "mesa": 83, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "24", "nombreEscuela": "COLEGIO RACING CLUB", "codigoEscuela": "PROV329", "mesa": 62, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "23", "nombreEscuela": "ESCUELA ES N°13 ES N° 14", "codigoEscuela": "PROV330", "mesa": 11, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Avellaneda", "circuitoElectoral": "23", "nombreEscuela": "ESCUELA EST N°5", "codigoEscuela": "PROV331", "mesa": 32, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0792D", "nombreEscuela": "ESCUELA EP N°22 ES N°52", "codigoEscuela": "PROV332", "mesa": 723, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0792D", "nombreEscuela": "JARDIN DE INFANTES N°901", "codigoEscuela": "PROV333", "mesa": 744, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0792C", "nombreEscuela": "ESCUELA EST N°2", "codigoEscuela": "PROV334", "mesa": 701, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0792C", "nombreEscuela": "ESCUELA ES N°37", "codigoEscuela": "PROV335", "mesa": 713, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0792B", "nombreEscuela": "JARDIN DE INFANTES N°907", "codigoEscuela": "PROV336", "mesa": 658, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0790A", "nombreEscuela": "ESCUELA ES N°20", "codigoEscuela": "PROV337", "mesa": 502, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0790A", "nombreEscuela": "ESCUELA ES N°20", "codigoEscuela": "PROV338", "mesa": 507, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789F", "nombreEscuela": "ESCUELA EP N°24", "codigoEscuela": "PROV339", "mesa": 405, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789F", "nombreEscuela": "ESCUELA PRIMARIA L.DA VINCI", "codigoEscuela": "PROV340", "mesa": 431, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789D", "nombreEscuela": "ESCUELA EST N°3", "codigoEscuela": "PROV341", "mesa": 304, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789D", "nombreEscuela": "ESCUELA ES N°10", "codigoEscuela": "PROV342", "mesa": 346, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789D", "nombreEscuela": "ESCUELA EDUC.ESPECIAL N°502", "codigoEscuela": "PROV343", "mesa": 354, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789C", "nombreEscuela": "ESCUELA EP N°9 ES N°5", "codigoEscuela": "PROV344", "mesa": 254, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789C", "nombreEscuela": "ESCUELA PRIMARIA MARTIN M.GUEMES", "codigoEscuela": "PROV345", "mesa": 276, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789C", "nombreEscuela": "INSTITUTO MANUEL BELGRANO", "codigoEscuela": "PROV346", "mesa": 289, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789B", "nombreEscuela": "COLEGIO SECUND.B.RIVADAVIA", "codigoEscuela": "PROV347", "mesa": 235, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789A", "nombreEscuela": "ESCUELA EP N°45", "codigoEscuela": "PROV348", "mesa": 109, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789A", "nombreEscuela": "ESCUELA EP N°32", "codigoEscuela": "PROV349", "mesa": 141, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789A", "nombreEscuela": "ESCUELA EP N°49 ES N°54", "codigoEscuela": "PROV350", "mesa": 163, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "0789A", "nombreEscuela": "ESCUELA EP N°53 ES N°26", "codigoEscuela": "PROV351", "mesa": 180, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "792", "nombreEscuela": "ESCUELA ES N°3", "codigoEscuela": "PROV352", "mesa": 580, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "792", "nombreEscuela": "INSTITUTO GUILLERMO E.HUDSON", "codigoEscuela": "PROV353", "mesa": 585, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "790", "nombreEscuela": "ESCUELA EST N°5", "codigoEscuela": "PROV354", "mesa": 457, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "790", "nombreEscuela": "ESCUELA ES N°2", "codigoEscuela": "PROV355", "mesa": 494, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "789", "nombreEscuela": "#N A", "codigoEscuela": "PROV356", "mesa": 9, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "789", "nombreEscuela": "ESCUELA PRIVADA BME.MITRE", "codigoEscuela": "PROV357", "mesa": 78, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Berazategui", "circuitoElectoral": "789", "nombreEscuela": "ESCUELA PRIVADA BME.MITRE", "codigoEscuela": "PROV358", "mesa": 82, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0291A", "nombreEscuela": "COLEGIO LA ANUNCIACION", "codigoEscuela": "PROV359", "mesa": 650, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0291A", "nombreEscuela": "COLEGIO LA ANUNCIACION", "codigoEscuela": "PROV360", "mesa": 652, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0288B", "nombreEscuela": "ESCUELA EP N°41 SB N°16", "codigoEscuela": "PROV361", "mesa": 484, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0288B", "nombreEscuela": "ESCUELA EP N°46", "codigoEscuela": "PROV362", "mesa": 503, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0288B", "nombreEscuela": "ESCUELA ES N°13", "codigoEscuela": "PROV363", "mesa": 525, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0288B", "nombreEscuela": "ESCUELA INTI HUASI", "codigoEscuela": "PROV364", "mesa": 538, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0288A", "nombreEscuela": "ESCUELA EP N°6", "codigoEscuela": "PROV365", "mesa": 428, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0288A", "nombreEscuela": "COLEGIO SAN AGUSTIN", "codigoEscuela": "PROV366", "mesa": 473, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0287D", "nombreEscuela": "ESCUELA ES N°18 PB N°37", "codigoEscuela": "PROV367", "mesa": 329, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0287D", "nombreEscuela": "ESCUELA EP N°55", "codigoEscuela": "PROV368", "mesa": 343, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0287C", "nombreEscuela": "ESCUELA EP N°2", "codigoEscuela": "PROV369", "mesa": 246, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0287C", "nombreEscuela": "ESCUELA ES N°24", "codigoEscuela": "PROV370", "mesa": 279, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0287A", "nombreEscuela": "ESCUELA EP N°53  ESB N°19", "codigoEscuela": "PROV371", "mesa": 192, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0287A", "nombreEscuela": "ESCUELA EP N°23", "codigoEscuela": "PROV372", "mesa": 207, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0286A", "nombreEscuela": "ESCUELA EP N°5 ES N°39", "codigoEscuela": "PROV373", "mesa": 33, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0286A", "nombreEscuela": "ESCUELA EP N°30 ES N°13", "codigoEscuela": "PROV374", "mesa": 62, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "0286A", "nombreEscuela": "JARD.DE INFANTES LA MILAGROSA", "codigoEscuela": "PROV375", "mesa": 92, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "291", "nombreEscuela": "ESCUELA EP N°19", "codigoEscuela": "PROV376", "mesa": 573, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "291", "nombreEscuela": "ESCUELA ES N°6", "codigoEscuela": "PROV377", "mesa": 587, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "288", "nombreEscuela": "ESCUELA EP N°37", "codigoEscuela": "PROV378", "mesa": 362, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "288", "nombreEscuela": "ESCUELA EP N°24", "codigoEscuela": "PROV379", "mesa": 398, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "288", "nombreEscuela": "INSTITUTO PARISH ROBERTSON", "codigoEscuela": "PROV380", "mesa": 419, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "287", "nombreEscuela": "ESCUELA EP N°3", "codigoEscuela": "PROV381", "mesa": 112, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Esteban Echeverría", "circuitoElectoral": "287", "nombreEscuela": "INST.PRIVADO LA ESCUELA DE HOY", "codigoEscuela": "PROV382", "mesa": 152, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0303B", "nombreEscuela": "ESCUELA EP N°35", "codigoEscuela": "PROV383", "mesa": 845, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0303B", "nombreEscuela": "ESCUELA EP N°31", "codigoEscuela": "PROV384", "mesa": 857, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0303B", "nombreEscuela": "ESCUELA EP N°7", "codigoEscuela": "PROV385", "mesa": 879, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0303A", "nombreEscuela": "ESCUELA EP N°61 ES N°57", "codigoEscuela": "PROV386", "mesa": 823, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302F", "nombreEscuela": "COLEGIO ASUNCION DE MARIA", "codigoEscuela": "PROV387", "mesa": 794, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302E", "nombreEscuela": "ESCUELA EP N°38 ES N°63", "codigoEscuela": "PROV388", "mesa": 655, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302E", "nombreEscuela": "ESCUELA EST N°1", "codigoEscuela": "PROV389", "mesa": 714, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302E", "nombreEscuela": "ESCUELA EP N°29 ES N°35", "codigoEscuela": "PROV390", "mesa": 734, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302E", "nombreEscuela": "ESCUELA EP N°29 ES N°35", "codigoEscuela": "PROV391", "mesa": 737, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302E", "nombreEscuela": "ESCUELA DE ADULTOS N°721", "codigoEscuela": "PROV392", "mesa": 770, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302D", "nombreEscuela": "ESCUELA ES N°4", "codigoEscuela": "PROV393", "mesa": 609, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302D", "nombreEscuela": "ESCUELA ES N°5", "codigoEscuela": "PROV394", "mesa": 646, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302C", "nombreEscuela": "ESCUELA EP N°47 ES N°43", "codigoEscuela": "PROV395", "mesa": 542, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302C", "nombreEscuela": "ESCUELA EP N°25 ES N°18", "codigoEscuela": "PROV396", "mesa": 573, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302C", "nombreEscuela": "INST.PRIV.MALVINAS ARGENTINAS", "codigoEscuela": "PROV397", "mesa": 585, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302B", "nombreEscuela": "ESCUELA ES N°3", "codigoEscuela": "PROV398", "mesa": 464, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302B", "nombreEscuela": "ESCUELA EP N°63", "codigoEscuela": "PROV399", "mesa": 468, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302B", "nombreEscuela": "ESCUELA EP N°69", "codigoEscuela": "PROV400", "mesa": 486, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302B", "nombreEscuela": "ESCUELA ES N° 14", "codigoEscuela": "PROV401", "mesa": 498, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302A", "nombreEscuela": "INSTITUTO SAN JORGE", "codigoEscuela": "PROV402", "mesa": 321, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302A", "nombreEscuela": "ESCUELA EP N°60 ES N°44", "codigoEscuela": "PROV403", "mesa": 364, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302A", "nombreEscuela": "ESCUELA EP N°68", "codigoEscuela": "PROV404", "mesa": 395, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "0302A", "nombreEscuela": "INSTITUTO DON ORIONE", "codigoEscuela": "PROV405", "mesa": 406, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "COLEG.N.S.DEL SAGRADO CORAZON", "codigoEscuela": "PROV406", "mesa": 16, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "ESCUELA EP N°22 ES N°50", "codigoEscuela": "PROV407", "mesa": 65, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "INSTITUTO WILLIAMS MORRIS", "codigoEscuela": "PROV408", "mesa": 86, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "ESCUELA EP N°15 ES N°28", "codigoEscuela": "PROV409", "mesa": 112, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "ESCUELA EP N°28", "codigoEscuela": "PROV410", "mesa": 146, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "COLEGIO SAN MARTIN", "codigoEscuela": "PROV411", "mesa": 190, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "ESCUELA EP N°48 ES N°30", "codigoEscuela": "PROV412", "mesa": 215, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "ESCUELA EP N°48 ES N°30", "codigoEscuela": "PROV413", "mesa": 216, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "COLEGIO STO.DOMINGO DE GUZMAN", "codigoEscuela": "PROV414", "mesa": 248, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Florencio Varela", "circuitoElectoral": "302", "nombreEscuela": "ESCUELA EP N°13", "codigoEscuela": "PROV415", "mesa": 257, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635E", "nombreEscuela": "ESCUELA EP N°67 ES N°120", "codigoEscuela": "PROV416", "mesa": 2800, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635E", "nombreEscuela": "ESCUELA E.S. N°82", "codigoEscuela": "PROV417", "mesa": 2813, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635E", "nombreEscuela": "ESCUELA ES N°50", "codigoEscuela": "PROV418", "mesa": 2873, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635E", "nombreEscuela": "ESCUELA EP N°209", "codigoEscuela": "PROV419", "mesa": 2898, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635E", "nombreEscuela": "ESCUELA EP N°126 E.ESTETICA N°3", "codigoEscuela": "PROV420", "mesa": 2914, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635D", "nombreEscuela": "ESCUELA EP N°76  ES N°114", "codigoEscuela": "PROV421", "mesa": 2675, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635D", "nombreEscuela": "ESCUELA EP N°145", "codigoEscuela": "PROV422", "mesa": 2692, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635D", "nombreEscuela": "ESCUELA EP N°194", "codigoEscuela": "PROV423", "mesa": 2729, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635D", "nombreEscuela": "INSTITUTO EDUCATIVO MERCEDITAS", "codigoEscuela": "PROV424", "mesa": 2763, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635D", "nombreEscuela": "INSTITUTO EDUCATIVO MERCEDITAS", "codigoEscuela": "PROV425", "mesa": 2764, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635C", "nombreEscuela": "ESCUELA EP N°134 ES N°102", "codigoEscuela": "PROV426", "mesa": 2512, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635C", "nombreEscuela": "ESCUELA EP N°31", "codigoEscuela": "PROV427", "mesa": 2562, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635C", "nombreEscuela": "ESCUELA EP N°204", "codigoEscuela": "PROV428", "mesa": 2599, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635C", "nombreEscuela": "ESCUELA EP N°199 ES N°62", "codigoEscuela": "PROV429", "mesa": 2619, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635C", "nombreEscuela": "ESCUELA EP N°178 ES N°173", "codigoEscuela": "PROV430", "mesa": 2629, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635C", "nombreEscuela": "ESCUELA EP N°58", "codigoEscuela": "PROV431", "mesa": 2651, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "ESCUELA EP N°19", "codigoEscuela": "PROV432", "mesa": 2188, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "ESCUELA EP N°19", "codigoEscuela": "PROV433", "mesa": 2193, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "ESCUELA EP N°84 ES N°150", "codigoEscuela": "PROV434", "mesa": 2238, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "INSTITUTO MARIANO ACOSTA", "codigoEscuela": "PROV435", "mesa": 2271, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "ESCUELA EP N°112  ES N°160", "codigoEscuela": "PROV436", "mesa": 2290, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "ESCUELA ES N°23", "codigoEscuela": "PROV437", "mesa": 2367, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "INSTITUTO ST.DOMENICA(EP Y ES)", "codigoEscuela": "PROV438", "mesa": 2392, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "INSTITUTO ST.DOMENICA(EP Y ES)", "codigoEscuela": "PROV439", "mesa": 2394, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "ESCUELA EP N°28 ESB N°88", "codigoEscuela": "PROV440", "mesa": 2395, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "ESCUELA EP N°111 ES N°155", "codigoEscuela": "PROV441", "mesa": 2416, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635B", "nombreEscuela": "ESCUELA EDUC. MEDIA N° 3", "codigoEscuela": "PROV442", "mesa": 2430, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "ESCUELA ES N°81", "codigoEscuela": "PROV443", "mesa": 1944, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "COLEGIO SANTO TOMAS DE AQUINO", "codigoEscuela": "PROV444", "mesa": 1964, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "ESCUELA EP N°211", "codigoEscuela": "PROV445", "mesa": 1971, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "ESCUELA EP N°211", "codigoEscuela": "PROV446", "mesa": 1976, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "ESCUELA EP N°127", "codigoEscuela": "PROV447", "mesa": 1984, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "EXTENSION I ESCUELA ES N°51", "codigoEscuela": "PROV448", "mesa": 2009, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "COLEGIO HOGAR SAN MAURICIO", "codigoEscuela": "PROV449", "mesa": 2059, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "ESCUELA ES N°113", "codigoEscuela": "PROV450", "mesa": 2126, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "INSTITUTO D.F.SARMIENTO", "codigoEscuela": "PROV451", "mesa": 2135, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "ESCUELA RAMON LISTA", "codigoEscuela": "PROV452", "mesa": 2153, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "ESCUELA EP N°197", "codigoEscuela": "PROV453", "mesa": 2169, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0635A", "nombreEscuela": "ESCUELA EP N°197", "codigoEscuela": "PROV454", "mesa": 2172, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0632A", "nombreEscuela": "ESCUELA ES N°35", "codigoEscuela": "PROV455", "mesa": 1362, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631D", "nombreEscuela": "ESCUELA EP N°6", "codigoEscuela": "PROV456", "mesa": 1197, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631D", "nombreEscuela": "ESCUELA EP N°113 ES N°120", "codigoEscuela": "PROV457", "mesa": 1253, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631D", "nombreEscuela": "ESCUELA EP N°162 ES N°53", "codigoEscuela": "PROV458", "mesa": 1283, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631C", "nombreEscuela": "ESCUELA EP N°85 ES N°188", "codigoEscuela": "PROV459", "mesa": 1149, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631C", "nombreEscuela": "INSTITUTO ISLAS MALVINAS", "codigoEscuela": "PROV460", "mesa": 1172, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631C", "nombreEscuela": "INSTITUTO ISLAS MALVINAS", "codigoEscuela": "PROV461", "mesa": 1174, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631B", "nombreEscuela": "ESCUELA EST N°2", "codigoEscuela": "PROV462", "mesa": 1125, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631A", "nombreEscuela": "ESCUELA EDUC.ESPECIAL N°508", "codigoEscuela": "PROV463", "mesa": 1039, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631A", "nombreEscuela": "ESCUELA EP N°186", "codigoEscuela": "PROV464", "mesa": 1043, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631A", "nombreEscuela": "ESCUELA EP N°190", "codigoEscuela": "PROV465", "mesa": 1067, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0631A", "nombreEscuela": "ESCUELA EP N°190", "codigoEscuela": "PROV466", "mesa": 1068, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629B", "nombreEscuela": "ESCUELA EP N°72 ES N°179", "codigoEscuela": "PROV467", "mesa": 710, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629B", "nombreEscuela": "JARDIN DE INFANTES N°943", "codigoEscuela": "PROV468", "mesa": 719, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629B", "nombreEscuela": "ESCUELA EP N°59 ES N°147", "codigoEscuela": "PROV469", "mesa": 761, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629B", "nombreEscuela": "ESCUELA EP N°116 ES N°136", "codigoEscuela": "PROV470", "mesa": 785, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629A", "nombreEscuela": "ESCUELA EP N°96", "codigoEscuela": "PROV471", "mesa": 551, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629A", "nombreEscuela": "ESCUELA EP N°37 ESB N°132", "codigoEscuela": "PROV472", "mesa": 581, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629A", "nombreEscuela": "ESCUELA EP N°91 ES N°112", "codigoEscuela": "PROV473", "mesa": 583, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629A", "nombreEscuela": "ESCUELA ES N°14", "codigoEscuela": "PROV474", "mesa": 646, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629A", "nombreEscuela": "ESCUELA EP N°109", "codigoEscuela": "PROV475", "mesa": 649, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0629A", "nombreEscuela": "ESCUELA ES N°37", "codigoEscuela": "PROV476", "mesa": 691, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0626A", "nombreEscuela": "INSTITUTO LA PAZ (EP Y ES)", "codigoEscuela": "PROV477", "mesa": 151, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0626A", "nombreEscuela": "INSTITUTO LA PAZ (EP Y ES)", "codigoEscuela": "PROV478", "mesa": 152, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0626A", "nombreEscuela": "ESCUELA ES N°148 EP N°65", "codigoEscuela": "PROV479", "mesa": 159, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0626A", "nombreEscuela": "ESCUELA EP N°15 ES N°165", "codigoEscuela": "PROV480", "mesa": 180, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "0626A", "nombreEscuela": "ESCUELA DE ARTE N°1", "codigoEscuela": "PROV481", "mesa": 189, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA EP N°78 ES N°169", "codigoEscuela": "PROV482", "mesa": 1628, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA EP N°81 ES N°149", "codigoEscuela": "PROV483", "mesa": 1650, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA ES N°1", "codigoEscuela": "PROV484", "mesa": 1679, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA EP N°93", "codigoEscuela": "PROV485", "mesa": 1704, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA FRAGATA LIBERTAD", "codigoEscuela": "PROV486", "mesa": 1740, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA FRAGATA LIBERTAD", "codigoEscuela": "PROV487", "mesa": 1742, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA EP N°90 ES N°170", "codigoEscuela": "PROV488", "mesa": 1749, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA EP N°213", "codigoEscuela": "PROV489", "mesa": 1787, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "INST.ARGENTINO FAMILIA ESCUELA", "codigoEscuela": "PROV490", "mesa": 1821, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA EP N°201 ES N°137", "codigoEscuela": "PROV491", "mesa": 1870, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "635", "nombreEscuela": "ESCUELA ES N°134 EP N°189", "codigoEscuela": "PROV492", "mesa": 1892, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "634", "nombreEscuela": "ESCUELA EP N°7 ES N°8", "codigoEscuela": "PROV493", "mesa": 1552, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "634", "nombreEscuela": "#N A", "codigoEscuela": "PROV494", "mesa": 1611, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "633", "nombreEscuela": "INST. Y COLEG. SAN L. GONZAGA", "codigoEscuela": "PROV495", "mesa": 1405, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "633", "nombreEscuela": "ESCUELA EST N°9", "codigoEscuela": "PROV496", "mesa": 1423, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "633", "nombreEscuela": "INSTITUTO JUAN MANUEL DE ROSAS", "codigoEscuela": "PROV497", "mesa": 1483, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "633", "nombreEscuela": "ESCUELA EP N°137", "codigoEscuela": "PROV498", "mesa": 1487, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "633", "nombreEscuela": "ESCUELA EP N°137", "codigoEscuela": "PROV499", "mesa": 1490, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "633", "nombreEscuela": "ESCUELA EP N°138 ES N°117", "codigoEscuela": "PROV500", "mesa": 1496, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "633", "nombreEscuela": "INSTITUTO NICOLAS REPETTO", "codigoEscuela": "PROV501", "mesa": 1537, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "632", "nombreEscuela": "COLEGIO DOMINGO SAVIO", "codigoEscuela": "PROV502", "mesa": 1307, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "631", "nombreEscuela": "#N A", "codigoEscuela": "PROV503", "mesa": 921, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "631", "nombreEscuela": "COLEGIO CERVANTES", "codigoEscuela": "PROV504", "mesa": 974, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "630", "nombreEscuela": "ESCUELA EP N°23", "codigoEscuela": "PROV505", "mesa": 827, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "630", "nombreEscuela": "ESCUELA ES N°42", "codigoEscuela": "PROV506", "mesa": 842, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "630", "nombreEscuela": "ESCUELA ES N°18", "codigoEscuela": "PROV507", "mesa": 844, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "630", "nombreEscuela": "ESCUELA EP N°24", "codigoEscuela": "PROV508", "mesa": 853, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "630", "nombreEscuela": "ESCUELA EP N°20", "codigoEscuela": "PROV509", "mesa": 902, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "629", "nombreEscuela": "ESCUELA EP N°82", "codigoEscuela": "PROV510", "mesa": 466, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "629", "nombreEscuela": "ESCUELA M.MAZZARELLO", "codigoEscuela": "PROV511", "mesa": 505, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "629", "nombreEscuela": "ESCUELA ES N°33", "codigoEscuela": "PROV512", "mesa": 509, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "629", "nombreEscuela": "JARDIN DE INFANTES N°966", "codigoEscuela": "PROV513", "mesa": 539, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "628", "nombreEscuela": "ESCUELA EP N°42 ES N°99", "codigoEscuela": "PROV514", "mesa": 313, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "628", "nombreEscuela": "ESCUELA ES N°19 EP N°4", "codigoEscuela": "PROV515", "mesa": 322, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "628", "nombreEscuela": "ESCUELA EP N°114", "codigoEscuela": "PROV516", "mesa": 381, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "628", "nombreEscuela": "COLEGIO PARROQ.JUAN XXIII(ES)", "codigoEscuela": "PROV517", "mesa": 442, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "628", "nombreEscuela": "COLEGIO PARROQ.JUAN XXIII(ES)", "codigoEscuela": "PROV518", "mesa": 445, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "627", "nombreEscuela": "ESCUELA EP N°143 ES N°57", "codigoEscuela": "PROV519", "mesa": 283, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "627", "nombreEscuela": "ESCUELA ES N°13", "codigoEscuela": "PROV520", "mesa": 299, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "626", "nombreEscuela": "ESCUELA ES N°49 EP N°192 ISFD N°106", "codigoEscuela": "PROV521", "mesa": 22, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "626", "nombreEscuela": "ESCUELA EP N°16 ESB N°185", "codigoEscuela": "PROV522", "mesa": 55, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "626", "nombreEscuela": "ESCUELA EP N°140 ESM N°5", "codigoEscuela": "PROV523", "mesa": 73, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "626", "nombreEscuela": "ESCUELA ES N°32", "codigoEscuela": "PROV524", "mesa": 101, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "626", "nombreEscuela": "ESCUELA ES N°32", "codigoEscuela": "PROV525", "mesa": 103, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "La Matanza", "circuitoElectoral": "626", "nombreEscuela": "ESCUELA EP N°79 ES N° 63", "codigoEscuela": "PROV526", "mesa": 145, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0593A", "nombreEscuela": "COLEGIO PRIMARIO AGAPITO OTERO", "codigoEscuela": "PROV527", "mesa": 1431, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0590A", "nombreEscuela": "ESCUELA EP N°61", "codigoEscuela": "PROV528", "mesa": 1172, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0590A", "nombreEscuela": "ESCUELA EP N°80 ES N°47", "codigoEscuela": "PROV529", "mesa": 1202, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0590A", "nombreEscuela": "ESCUELA ES N°15", "codigoEscuela": "PROV530", "mesa": 1251, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0590A", "nombreEscuela": "ESCUELA EST N°3", "codigoEscuela": "PROV531", "mesa": 1254, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583E", "nombreEscuela": "ESCUELA EST N°9", "codigoEscuela": "PROV532", "mesa": 527, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583D", "nombreEscuela": "ESCUELA EP N°83 ES N°49", "codigoEscuela": "PROV533", "mesa": 421, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583D", "nombreEscuela": "ESCUELA EP N°62 ES N°48", "codigoEscuela": "PROV534", "mesa": 439, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583C", "nombreEscuela": "ESCUELA EP N°68", "codigoEscuela": "PROV535", "mesa": 375, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583C", "nombreEscuela": "COLEGIO NTRA.SRA.DE COPACABANA", "codigoEscuela": "PROV536", "mesa": 388, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583B", "nombreEscuela": "ESCUELA EP N°82 ES N°24", "codigoEscuela": "PROV537", "mesa": 294, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583B", "nombreEscuela": "ESCUELA EP N°64 ES N°78", "codigoEscuela": "PROV538", "mesa": 306, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583B", "nombreEscuela": "ESCUELA EP N°43 ES N°9", "codigoEscuela": "PROV539", "mesa": 328, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583B", "nombreEscuela": "ESCUELA EP N°43 ES N°9", "codigoEscuela": "PROV540", "mesa": 333, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583A", "nombreEscuela": "ESCUELA EST N°8", "codigoEscuela": "PROV541", "mesa": 182, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583A", "nombreEscuela": "ESCUELA ES N°11 EP N°79", "codigoEscuela": "PROV542", "mesa": 189, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583A", "nombreEscuela": "ESCUELA EP N°86 ES N°73", "codigoEscuela": "PROV543", "mesa": 226, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "0583A", "nombreEscuela": "ESCUELA EP N°44 ES N°53", "codigoEscuela": "PROV544", "mesa": 276, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "593", "nombreEscuela": "ESCUELA ES N°2", "codigoEscuela": "PROV545", "mesa": 1352, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "593", "nombreEscuela": "ESCUELA ES N°51", "codigoEscuela": "PROV546", "mesa": 1363, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "593", "nombreEscuela": "ESCUELA EP N°74", "codigoEscuela": "PROV547", "mesa": 1396, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "592", "nombreEscuela": "INSTITUTO VICENTE PALLOTTI", "codigoEscuela": "PROV548", "mesa": 1325, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "592", "nombreEscuela": "ESCUELA EP N°72 ES N°41", "codigoEscuela": "PROV549", "mesa": 1333, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "590", "nombreEscuela": "ESCUELA EP N°36 ES N°42", "codigoEscuela": "PROV550", "mesa": 1054, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "590", "nombreEscuela": "COLEGIO TOMAS JEFFERSON (EP)", "codigoEscuela": "PROV551", "mesa": 1059, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "590", "nombreEscuela": "COLEGIO TOMAS JEFFERSON (EP)", "codigoEscuela": "PROV552", "mesa": 1063, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "590", "nombreEscuela": "ESCUELA ESB N°5", "codigoEscuela": "PROV553", "mesa": 1086, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "589", "nombreEscuela": "ESCUELA EP N°24 ES N°79", "codigoEscuela": "PROV554", "mesa": 987, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "589", "nombreEscuela": "ESCUELA EP N°17 ES N°310", "codigoEscuela": "PROV555", "mesa": 1029, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "589", "nombreEscuela": "#N A", "codigoEscuela": "PROV556", "mesa": 1033, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "588", "nombreEscuela": "INSTITUTO NTRA.SRA.DEL CARMEN", "codigoEscuela": "PROV557", "mesa": 959, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "588", "nombreEscuela": "INSTITUTO NTRA.SRA.DEL CARMEN", "codigoEscuela": "PROV558", "mesa": 960, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "587", "nombreEscuela": "ESCUELA EP N°38", "codigoEscuela": "PROV559", "mesa": 680, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "587", "nombreEscuela": "ESCUELA EP N°8 ES N°80", "codigoEscuela": "PROV560", "mesa": 717, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "587", "nombreEscuela": "ESCUELA EP N°8 ES N°80", "codigoEscuela": "PROV561", "mesa": 718, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "587", "nombreEscuela": "COLEGIO SAN ALBERTO MAGNO", "codigoEscuela": "PROV562", "mesa": 746, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "587", "nombreEscuela": "ESCUELA ES N°13", "codigoEscuela": "PROV563", "mesa": 774, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "587", "nombreEscuela": "SAN ALBANO SCHOOL", "codigoEscuela": "PROV564", "mesa": 810, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "587", "nombreEscuela": "COLEGIO MODELO PARQUE BARON", "codigoEscuela": "PROV565", "mesa": 887, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "587", "nombreEscuela": "COLEGIO ALMAFUERTE", "codigoEscuela": "PROV566", "mesa": 930, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "587", "nombreEscuela": "JARDIN DE INF.MUNICIPAL N°6", "codigoEscuela": "PROV567", "mesa": 943, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "585", "nombreEscuela": "ESCUELA EP N°10 ES N°6 ANEXO", "codigoEscuela": "PROV568", "mesa": 605, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "584", "nombreEscuela": "ESCUELA EP N°49", "codigoEscuela": "PROV569", "mesa": 538, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "584", "nombreEscuela": "ESCUELA EP N°33 ES N°53", "codigoEscuela": "PROV570", "mesa": 543, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "584", "nombreEscuela": "ESCUELA ES N°12", "codigoEscuela": "PROV571", "mesa": 576, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "584", "nombreEscuela": "ESCUELA EST N°7", "codigoEscuela": "PROV572", "mesa": 592, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "583", "nombreEscuela": "ESCUELA EP N°76 ES N°40", "codigoEscuela": "PROV573", "mesa": 22, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "583", "nombreEscuela": "ESCUELA EP N°76 ES N°40", "codigoEscuela": "PROV574", "mesa": 23, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "583", "nombreEscuela": "ESC.EDUC.MEDIA N°4 ESB N°352", "codigoEscuela": "PROV575", "mesa": 40, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "583", "nombreEscuela": "ESCUELA EP N°70 ES N°361", "codigoEscuela": "PROV576", "mesa": 92, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "583", "nombreEscuela": "ESCUELA EP N°70 ES N°361", "codigoEscuela": "PROV577", "mesa": 93, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "583", "nombreEscuela": "ESCUELA EP N°70 ES N°361", "codigoEscuela": "PROV578", "mesa": 100, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "583", "nombreEscuela": "INSTITUTO NUEVO DIA", "codigoEscuela": "PROV579", "mesa": 151, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Lomas de Zamora", "circuitoElectoral": "583", "nombreEscuela": "JARDIN DE INFANTES N°943", "codigoEscuela": "PROV580", "mesa": 161, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0791B", "nombreEscuela": "ESCUELA EP N°54", "codigoEscuela": "PROV581", "mesa": 1318, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0791B", "nombreEscuela": "ESCUELA EP N°54", "codigoEscuela": "PROV582", "mesa": 1321, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0791A", "nombreEscuela": "ESCUELA EP N°44 ES N°53", "codigoEscuela": "PROV583", "mesa": 1178, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0791A", "nombreEscuela": "ESCUELA EP N°74 ES N°72", "codigoEscuela": "PROV584", "mesa": 1232, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0791A", "nombreEscuela": "ESCUELA EP N°74 ES N°72", "codigoEscuela": "PROV585", "mesa": 1235, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0791A", "nombreEscuela": "COLEGIO S.T.DEL NINO JESUS(ES)", "codigoEscuela": "PROV586", "mesa": 1245, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788C", "nombreEscuela": "ESCUELA EP N°87", "codigoEscuela": "PROV587", "mesa": 1042, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788C", "nombreEscuela": "ESCUELA EP N°76", "codigoEscuela": "PROV588", "mesa": 1081, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788C", "nombreEscuela": "ESCUELA EP N°77 ES N°75", "codigoEscuela": "PROV589", "mesa": 1102, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788B", "nombreEscuela": "ESCUELA EP N°68 ES N°67", "codigoEscuela": "PROV590", "mesa": 951, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788B", "nombreEscuela": "ESCUELA EP N°51 ESB N°57", "codigoEscuela": "PROV591", "mesa": 961, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788B", "nombreEscuela": "COLEGIO SAN JOSE OBRERO", "codigoEscuela": "PROV592", "mesa": 980, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788A", "nombreEscuela": "ESCUELA EP N°64 ES N°65", "codigoEscuela": "PROV593", "mesa": 798, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788A", "nombreEscuela": "ESCUELA EP N°4", "codigoEscuela": "PROV594", "mesa": 803, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788A", "nombreEscuela": "ESCUELA ES N°34", "codigoEscuela": "PROV595", "mesa": 813, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788A", "nombreEscuela": "ESCUELA EM N°10", "codigoEscuela": "PROV596", "mesa": 887, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788A", "nombreEscuela": "ESCUELA EST N°3", "codigoEscuela": "PROV597", "mesa": 892, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0788A", "nombreEscuela": "ESCUELA EP N°50", "codigoEscuela": "PROV598", "mesa": 926, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0787A", "nombreEscuela": "ESCUELA EP N°18 ES N°17", "codigoEscuela": "PROV599", "mesa": 641, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0787A", "nombreEscuela": "COLEGIO NUEVA ESCUELA DEL SUR", "codigoEscuela": "PROV600", "mesa": 648, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0787A", "nombreEscuela": "ESCUELA EP N°20 ES N°8", "codigoEscuela": "PROV601", "mesa": 686, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0787A", "nombreEscuela": "COLEGIO PRIVADO BERNAL(EP)", "codigoEscuela": "PROV602", "mesa": 698, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0786A", "nombreEscuela": "ESCUELA EP N°15 ES N°80", "codigoEscuela": "PROV603", "mesa": 531, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0785B", "nombreEscuela": "ESCUELA EP N°62 ES N°31", "codigoEscuela": "PROV604", "mesa": 359, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0785B", "nombreEscuela": "INSTITUTO CRISTO REY", "codigoEscuela": "PROV605", "mesa": 400, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0785B", "nombreEscuela": "ESCUELA EP N°8 ES N°19", "codigoEscuela": "PROV606", "mesa": 422, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0785B", "nombreEscuela": "ESCUELA EP N°8 ES N°19", "codigoEscuela": "PROV607", "mesa": 428, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0785A", "nombreEscuela": "ESCUELA EST N°6", "codigoEscuela": "PROV608", "mesa": 309, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0785A", "nombreEscuela": "ESCUELA SECUND.MIGUEL CANE", "codigoEscuela": "PROV609", "mesa": 318, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0783A", "nombreEscuela": "ESCUELA EST N°5", "codigoEscuela": "PROV610", "mesa": 84, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "0783A", "nombreEscuela": "ESCUELA EP N°29", "codigoEscuela": "PROV611", "mesa": 100, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "791", "nombreEscuela": "ESCUELA EP N°67 ES N°66", "codigoEscuela": "PROV612", "mesa": 1134, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "791", "nombreEscuela": "ESCUELA ES N°18", "codigoEscuela": "PROV613", "mesa": 1173, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "788", "nombreEscuela": "ESCUELA EP N°65", "codigoEscuela": "PROV614", "mesa": 703, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "788", "nombreEscuela": "ESCUELA EP N°63 ES N°36", "codigoEscuela": "PROV615", "mesa": 711, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "788", "nombreEscuela": "ESCUELA EST N°8", "codigoEscuela": "PROV616", "mesa": 760, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "788", "nombreEscuela": "CTO.INTEGRADOR COMUNITARIO IAPI", "codigoEscuela": "PROV617", "mesa": 769, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "787", "nombreEscuela": "COLEGIO ALMAFUERTE (EP)", "codigoEscuela": "PROV618", "mesa": 600, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "787", "nombreEscuela": "INST.SUP.FORMAC.DOCENTE N°24", "codigoEscuela": "PROV619", "mesa": 609, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "786", "nombreEscuela": "ESCUELA EP N°61", "codigoEscuela": "PROV620", "mesa": 522, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "785", "nombreEscuela": "ESCUELA EP N°17", "codigoEscuela": "PROV621", "mesa": 198, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "785", "nombreEscuela": "ESCUELA EP N°13 ES N°77", "codigoEscuela": "PROV622", "mesa": 227, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "785", "nombreEscuela": "ESCUELA EP N°13 ES N°77", "codigoEscuela": "PROV623", "mesa": 229, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "785", "nombreEscuela": "ESCUELA EP N°58", "codigoEscuela": "PROV624", "mesa": 277, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "785", "nombreEscuela": "ESCUELA ES N°67", "codigoEscuela": "PROV625", "mesa": 288, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "784", "nombreEscuela": "ESCUELA ES N°33", "codigoEscuela": "PROV626", "mesa": 162, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "784", "nombreEscuela": "ESCUELA EP N°73 ES N°49", "codigoEscuela": "PROV627", "mesa": 172, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "783", "nombreEscuela": "ESCUELA EST N°4", "codigoEscuela": "PROV628", "mesa": 38, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 3, "partido": "Quilmes", "circuitoElectoral": "783", "nombreEscuela": "ESCUELA EST N°4", "codigoEscuela": "PROV629", "mesa": 39, "expansor": 26.510843373493977 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 4, "partido": "Carlos Casares", "circuitoElectoral": "0167A", "nombreEscuela": "ESCUELA EP N°12 EST N°1", "codigoEscuela": "PROV632", "mesa": 61, "expansor": 387.3194444444445 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 4, "partido": "Carlos Casares", "circuitoElectoral": "0165A", "nombreEscuela": "ESCUELA EP N°6", "codigoEscuela": "PROV633", "mesa": 54, "expansor": 387.3194444444445 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 4, "partido": "General Villegas", "circuitoElectoral": "419", "nombreEscuela": "ESCUELA EP N°7 ES N°10", "codigoEscuela": "PROV638", "mesa": 74, "expansor": 348.63043478260863 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 4, "partido": "General Villegas", "circuitoElectoral": "411", "nombreEscuela": "ESCUELA EP N°3", "codigoEscuela": "PROV639", "mesa": 19, "expansor": 348.63043478260863 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 4, "partido": "Lincoln", "circuitoElectoral": "554", "nombreEscuela": "ESCUELA EP N°2", "codigoEscuela": "PROV640", "mesa": 15, "expansor": 308.4038461538461 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 4, "partido": "Lincoln", "circuitoElectoral": "554", "nombreEscuela": "ESCUELA.MAYOR ANTONIO SURCE", "codigoEscuela": "PROV641", "mesa": 25, "expansor": 308.4038461538461 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 4, "partido": "Lincoln", "circuitoElectoral": "554", "nombreEscuela": "ESCUELA EST N°2", "codigoEscuela": "PROV642", "mesa": 55, "expansor": 308.4038461538461 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "Chascomús", "circuitoElectoral": "201", "nombreEscuela": "ESCUELA EST N°1", "codigoEscuela": "PROV634", "mesa": 11, "expansor": 366.93421052631584 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "Chascomús", "circuitoElectoral": "201", "nombreEscuela": "ESCUELA EP N°17", "codigoEscuela": "PROV635", "mesa": 46, "expansor": 366.93421052631584 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "Chascomús", "circuitoElectoral": "201", "nombreEscuela": "ESCUELA EP N°55", "codigoEscuela": "PROV636", "mesa": 55, "expansor": 366.93421052631584 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "Chascomús", "circuitoElectoral": "201", "nombreEscuela": "ESCUELA EP N°3", "codigoEscuela": "PROV637", "mesa": 63, "expansor": 366.93421052631584 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0370F", "nombreEscuela": "INSTITUTO G. GALILEI(EP Y ES)", "codigoEscuela": "PROV678", "mesa": 1524, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0370F", "nombreEscuela": "INSTITUTO G. GALILEI(EP Y ES)", "codigoEscuela": "PROV679", "mesa": 1526, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0370F", "nombreEscuela": "ESCUELA MUNICIPAL EP N°6 ES N°205", "codigoEscuela": "PROV680", "mesa": 1578, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0370A", "nombreEscuela": "JARDIN DE INF.MUNICIPAL N°12", "codigoEscuela": "PROV681", "mesa": 1490, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0366A", "nombreEscuela": "COLEGIO Y ES DR.A.SCHWEITZER", "codigoEscuela": "PROV682", "mesa": 989, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0366A", "nombreEscuela": "ESCUELA EP N°31", "codigoEscuela": "PROV683", "mesa": 1020, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0366A", "nombreEscuela": "COLEGIO ATLANTICO DEL SUR", "codigoEscuela": "PROV684", "mesa": 1046, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0366A", "nombreEscuela": "ESC.DE ART.VISUALES M.MALHARRO", "codigoEscuela": "PROV685", "mesa": 1114, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0365F", "nombreEscuela": "ESCUELA AMUYEN", "codigoEscuela": "PROV686", "mesa": 718, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0365F", "nombreEscuela": "JARDIN DE INFANTES N°903", "codigoEscuela": "PROV687", "mesa": 722, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0365E", "nombreEscuela": "ESCUELA MUNIC.EP N°2 ES N°212", "codigoEscuela": "PROV688", "mesa": 682, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0365D", "nombreEscuela": "ESCUELA EP N°13 ES N°53", "codigoEscuela": "PROV689", "mesa": 603, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0365B", "nombreEscuela": "INSTITUTO EDUCATIVO J.CORTAZAR", "codigoEscuela": "PROV690", "mesa": 530, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "0365A", "nombreEscuela": "COLEGIO ISAAC NEWTON", "codigoEscuela": "PROV691", "mesa": 495, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "370", "nombreEscuela": "ESCUELA EP N°78", "codigoEscuela": "PROV692", "mesa": 1438, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "370", "nombreEscuela": "#N A", "codigoEscuela": "PROV693", "mesa": 1452, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "370", "nombreEscuela": "JARDIN DE INFANTES N°945", "codigoEscuela": "PROV694", "mesa": 1467, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "368", "nombreEscuela": "ESCUELA EP N°79", "codigoEscuela": "PROV695", "mesa": 1363, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "367", "nombreEscuela": "INSTITUTO PERALTA RAMOS", "codigoEscuela": "PROV696", "mesa": 1174, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "367", "nombreEscuela": "COLEGIO SAN ROQUE", "codigoEscuela": "PROV697", "mesa": 1189, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "367", "nombreEscuela": "COLEGIO C.SAAVEDRA LAMAS", "codigoEscuela": "PROV698", "mesa": 1207, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "367", "nombreEscuela": "INSTITUTO ARG.DE LA EMPRESA", "codigoEscuela": "PROV699", "mesa": 1316, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "366", "nombreEscuela": "ESCUELA ES N°24 ES N°22", "codigoEscuela": "PROV700", "mesa": 742, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "366", "nombreEscuela": "JARDIN DE INFANTES N°915", "codigoEscuela": "PROV701", "mesa": 823, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "366", "nombreEscuela": "#N A", "codigoEscuela": "PROV702", "mesa": 837, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "366", "nombreEscuela": "COLEGIO MAR DEL PLATA", "codigoEscuela": "PROV703", "mesa": 847, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "366", "nombreEscuela": "COLEGIO MAR DEL PLATA", "codigoEscuela": "PROV704", "mesa": 848, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "366", "nombreEscuela": "ESCUELA EP N°53", "codigoEscuela": "PROV705", "mesa": 893, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "366", "nombreEscuela": "ESCUELA ES N°3", "codigoEscuela": "PROV706", "mesa": 901, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "366", "nombreEscuela": "COLEG.STA.MARIA DEL BUEN AYRE", "codigoEscuela": "PROV707", "mesa": 957, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "365", "nombreEscuela": "INSTITUTO JESUS MARIA(ES)", "codigoEscuela": "PROV708", "mesa": 399, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "365", "nombreEscuela": "ESCUELA MUN. EP N°13 ESC N°208", "codigoEscuela": "PROV709", "mesa": 414, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "364", "nombreEscuela": "ESCUELA ES N° 79", "codigoEscuela": "PROV710", "mesa": 2, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "364", "nombreEscuela": "INSTITUTO AYELEN", "codigoEscuela": "PROV711", "mesa": 19, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "364", "nombreEscuela": "JARDIN DE INF.MUNICIPAL N°31", "codigoEscuela": "PROV712", "mesa": 73, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "364", "nombreEscuela": "JARDIN DE INF.MUNICIPAL N°31", "codigoEscuela": "PROV713", "mesa": 74, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "364", "nombreEscuela": "ESCUELA MUNI.N°7 EMS N°201", "codigoEscuela": "PROV714", "mesa": 167, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "364", "nombreEscuela": "ESCUELA NACIONAL DE PESCA", "codigoEscuela": "PROV715", "mesa": 269, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "364", "nombreEscuela": "COLEGIO TOMAS ALVA EDISON", "codigoEscuela": "PROV716", "mesa": 303, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "364", "nombreEscuela": "ESC.CRISTIANA S.E.SORENSEN(EP)", "codigoEscuela": "PROV717", "mesa": 345, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 5, "partido": "General Pueyrredón", "circuitoElectoral": "364", "nombreEscuela": "ESC.CRISTIANA S.E.SORENSEN(EP)", "codigoEscuela": "PROV718", "mesa": 347, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "92", "nombreEscuela": "ESCUELA EP N°13", "codigoEscuela": "PROV660", "mesa": 702, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "91", "nombreEscuela": "ESCUELA EP N°24 ES N°29", "codigoEscuela": "PROV661", "mesa": 645, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "91", "nombreEscuela": "ESCUELA EP N°36", "codigoEscuela": "PROV662", "mesa": 666, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "89", "nombreEscuela": "ESCUELA ES N°12", "codigoEscuela": "PROV663", "mesa": 626, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "88", "nombreEscuela": "ESCUELA ES N°7", "codigoEscuela": "PROV664", "mesa": 609, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "86", "nombreEscuela": "ESCUELA ES N°30", "codigoEscuela": "PROV665", "mesa": 524, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "85", "nombreEscuela": "JARDIN DE INFANTES N°920", "codigoEscuela": "PROV666", "mesa": 455, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "85", "nombreEscuela": "ESCUELA ES N°16 EP N°84", "codigoEscuela": "PROV667", "mesa": 472, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "84", "nombreEscuela": "COLEGIO MARIA AUXILIADORA", "codigoEscuela": "PROV668", "mesa": 400, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "83", "nombreEscuela": "ESCUELA EP N°6 ES N°20", "codigoEscuela": "PROV669", "mesa": 367, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "76", "nombreEscuela": "ESCUELA EP N°46", "codigoEscuela": "PROV670", "mesa": 194, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "76", "nombreEscuela": "ESCUELA EP N°73", "codigoEscuela": "PROV671", "mesa": 217, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "76", "nombreEscuela": "ESCUELA EP N°73", "codigoEscuela": "PROV672", "mesa": 221, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "76", "nombreEscuela": "JARDIN DE INFANTES N°944", "codigoEscuela": "PROV673", "mesa": 259, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "76", "nombreEscuela": "JARDIN DE INFANTES N°951", "codigoEscuela": "PROV674", "mesa": 260, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "75", "nombreEscuela": "ESCUELA EP N°16 ES N°22", "codigoEscuela": "PROV675", "mesa": 166, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "73", "nombreEscuela": "ESCUELA EP N°67", "codigoEscuela": "PROV676", "mesa": 72, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 6, "partido": "Bahía Blanca", "circuitoElectoral": "73", "nombreEscuela": "ESCUELA EP N°67", "codigoEscuela": "PROV677", "mesa": 75, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0509A", "nombreEscuela": "ESCUELA ES N°9 (ANEXO)", "codigoEscuela": "PROV719", "mesa": 1523, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0509A", "nombreEscuela": "COLEGIO SAN AGUSTIN EP", "codigoEscuela": "PROV720", "mesa": 1531, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0508E", "nombreEscuela": "COOPERATIVA DE TRABAJO COLEGIO EMANUEL", "codigoEscuela": "PROV721", "mesa": 1470, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0508D", "nombreEscuela": "ESCUELA EST N°4", "codigoEscuela": "PROV722", "mesa": 1451, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0508C", "nombreEscuela": "ESCUELA EP N°63", "codigoEscuela": "PROV723", "mesa": 1409, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0508A", "nombreEscuela": "ESCUELA SAN JUAN BAUTISTA", "codigoEscuela": "PROV724", "mesa": 1371, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0505A", "nombreEscuela": "ESCUELA ES N°7", "codigoEscuela": "PROV725", "mesa": 1286, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0504A", "nombreEscuela": "ESCUELA EP N°18", "codigoEscuela": "PROV726", "mesa": 1149, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0504A", "nombreEscuela": "INSTITUTO SAN JOSE OBRERO", "codigoEscuela": "PROV727", "mesa": 1215, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0497D", "nombreEscuela": "ESCUELA EP N°83", "codigoEscuela": "PROV728", "mesa": 684, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0497D", "nombreEscuela": "JARDIN DE INFANTES N°989", "codigoEscuela": "PROV729", "mesa": 696, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0497D", "nombreEscuela": "ESCUELA EDUC.ESPECIAL N°535", "codigoEscuela": "PROV730", "mesa": 705, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0497B", "nombreEscuela": "CENTRO DE FOMENTO 17 DE AGOSTO", "codigoEscuela": "PROV731", "mesa": 634, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0496E", "nombreEscuela": "ESCUELA EP N°46 ES N°37", "codigoEscuela": "PROV732", "mesa": 564, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0496D", "nombreEscuela": "JARDIN DE INFANTES N°956", "codigoEscuela": "PROV733", "mesa": 555, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0496D", "nombreEscuela": "JARDIN DE INFANTES N°956", "codigoEscuela": "PROV734", "mesa": 556, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0496C", "nombreEscuela": "ESCUELA EP N°9 ES N°43", "codigoEscuela": "PROV735", "mesa": 487, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "0496A", "nombreEscuela": "JARDIN DE INF.MUNICIPAL N°11", "codigoEscuela": "PROV736", "mesa": 420, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "516", "nombreEscuela": "ESCUELA EST N°6", "codigoEscuela": "PROV737", "mesa": 1536, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "508", "nombreEscuela": "ESCUELA ES N°22", "codigoEscuela": "PROV738", "mesa": 1322, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "508", "nombreEscuela": "ESCUELA ES N°22", "codigoEscuela": "PROV739", "mesa": 1329, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "505", "nombreEscuela": "COLEGIO SAN FRANCISCO DE ASIS", "codigoEscuela": "PROV740", "mesa": 1237, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "504", "nombreEscuela": "ESCUELA EP N°44", "codigoEscuela": "PROV741", "mesa": 1061, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "504", "nombreEscuela": "ESCUELA FRAY MAMERTO ESQUIU", "codigoEscuela": "PROV742", "mesa": 1080, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "504", "nombreEscuela": "COLEG. CLUB ESTUDIANTES DE L.P", "codigoEscuela": "PROV743", "mesa": 1115, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "504", "nombreEscuela": "ESCUELA EP N°34 ES N°63", "codigoEscuela": "PROV744", "mesa": 1127, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "502", "nombreEscuela": "ESCUELA EP N°60 ES N°85", "codigoEscuela": "PROV745", "mesa": 960, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "502", "nombreEscuela": "JARDIN DE INFANTES N°910", "codigoEscuela": "PROV746", "mesa": 996, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "501", "nombreEscuela": "ESCUELA EP N°16", "codigoEscuela": "PROV747", "mesa": 752, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "501", "nombreEscuela": "ESCUELA ES N°11", "codigoEscuela": "PROV748", "mesa": 823, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "497", "nombreEscuela": "ESCUELA EP N°21", "codigoEscuela": "PROV749", "mesa": 587, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "497", "nombreEscuela": "ESCUELA EP N°21", "codigoEscuela": "PROV750", "mesa": 590, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "497", "nombreEscuela": "ESCUELA EST N°3", "codigoEscuela": "PROV751", "mesa": 595, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "496", "nombreEscuela": "ESCUELA EP N°125 ES N°71", "codigoEscuela": "PROV752", "mesa": 323, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "496", "nombreEscuela": "ESCUELA EP N°121 ES N°55", "codigoEscuela": "PROV753", "mesa": 337, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "495", "nombreEscuela": "COLEGIO MAYOR.ALBERTI (EP)", "codigoEscuela": "PROV754", "mesa": 311, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "488", "nombreEscuela": "ESCUELA EP N°58", "codigoEscuela": "PROV755", "mesa": 240, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "484", "nombreEscuela": "ESCUELA ES N°2", "codigoEscuela": "PROV756", "mesa": 204, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "483", "nombreEscuela": "ESCUELA ES N°62", "codigoEscuela": "PROV757", "mesa": 202, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "477", "nombreEscuela": "ESCUELA EDUC.ESPECIAL N°534", "codigoEscuela": "PROV758", "mesa": 152, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "475", "nombreEscuela": "ESCUELA ES N°1", "codigoEscuela": "PROV759", "mesa": 133, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "464", "nombreEscuela": "ESCUELA ES N°32 EX NORMAL 1", "codigoEscuela": "PROV760", "mesa": 24, "expansor": 37.76470588235294 },
    { "operativo": "generales-23-provincia-bs-as", "seccionElectoral": 8, "partido": "La Plata", "circuitoElectoral": "462", "nombreEscuela": "MINISTERIO DE LA PRODUCCION", "codigoEscuela": "PROV761", "mesa": 12, "expansor": 37.76470588235294 }
]

