/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchEncuestaBocaUrnas = /* GraphQL */ `
  query SearchEncuestaBocaUrnas(
    $filter: SearchableEncuestaBocaUrnaFilterInput
    $sort: [SearchableEncuestaBocaUrnaSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEncuestaBocaUrnaAggregationInput]
  ) {
    searchEncuestaBocaUrnas(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        circuitoElectoral
        codigoEscuela
        escuela
        operativo
        encuestador
        rangoEtario
        genero
        pudoVotar
        votoEnBlanco
        cortoBoleta
        votoListaPresidente
        votoListaGobernador
        votoListaIntendente
        noPudoVotarMotivo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchMesaTestigos = /* GraphQL */ `
  query SearchMesaTestigos(
    $filter: SearchableMesaTestigoFilterInput
    $sort: [SearchableMesaTestigoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMesaTestigoAggregationInput]
  ) {
    searchMesaTestigos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        operativo
        seccionElectoral
        partido
        circuitoElectoral
        nombreEscuela
        codigoEscuela
        mesa
        fiscalizador
        cantidadPersonasPadron
        cantidadPersonasVotaron
        lastSyncPersonasVotaron
        expansor
        cerrada
        cargoCallCenter
        escrutinioPresidenteSubido
        escrutinioGobernadorSubido
        escrutinioIntendenteSubido
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchEscrutinios = /* GraphQL */ `
  query SearchEscrutinios(
    $filter: SearchableEscrutinioFilterInput
    $sort: [SearchableEscrutinioSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEscrutinioAggregationInput]
  ) {
    searchEscrutinios(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        operativo
        seccionElectoral
        partido
        circuitoElectoral
        nombreEscuela
        codigoEscuela
        mesa
        fiscalizador
        tipo
        lista
        codigoLista
        candidato
        fuerzaPolitica
        votos
        votosExpandidos
        expansor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEncuestaBocaUrna = /* GraphQL */ `
  query GetEncuestaBocaUrna($id: ID!) {
    getEncuestaBocaUrna(id: $id) {
      id
      circuitoElectoral
      codigoEscuela
      escuela
      operativo
      encuestador
      rangoEtario
      genero
      pudoVotar
      votoEnBlanco
      cortoBoleta
      votoListaPresidente
      votoListaGobernador
      votoListaIntendente
      noPudoVotarMotivo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEncuestaBocaUrnas = /* GraphQL */ `
  query ListEncuestaBocaUrnas(
    $filter: ModelEncuestaBocaUrnaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEncuestaBocaUrnas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        circuitoElectoral
        codigoEscuela
        escuela
        operativo
        encuestador
        rangoEtario
        genero
        pudoVotar
        votoEnBlanco
        cortoBoleta
        votoListaPresidente
        votoListaGobernador
        votoListaIntendente
        noPudoVotarMotivo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPersonal = /* GraphQL */ `
  query GetPersonal($id: ID!) {
    getPersonal(id: $id) {
      id
      nickname
      rol
      codigoEscuelaAsignada
      nombreEscuelaAsignada
      circuitoElectoralAsignado
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPersonals = /* GraphQL */ `
  query ListPersonals(
    $filter: ModelPersonalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nickname
        rol
        codigoEscuelaAsignada
        nombreEscuelaAsignada
        circuitoElectoralAsignado
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSeguimiento = /* GraphQL */ `
  query GetSeguimiento($id: ID!) {
    getSeguimiento(id: $id) {
      id
      encuestador
      operativo
      codigoEscuela
      circuitoElectoral
      escuela
      cantidadEncuestas
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSeguimientos = /* GraphQL */ `
  query ListSeguimientos(
    $filter: ModelSeguimientoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeguimientos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        encuestador
        operativo
        codigoEscuela
        circuitoElectoral
        escuela
        cantidadEncuestas
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMesaTestigo = /* GraphQL */ `
  query GetMesaTestigo($id: ID!) {
    getMesaTestigo(id: $id) {
      id
      operativo
      seccionElectoral
      partido
      circuitoElectoral
      nombreEscuela
      codigoEscuela
      mesa
      fiscalizador
      cantidadPersonasPadron
      cantidadPersonasVotaron
      lastSyncPersonasVotaron
      expansor
      cerrada
      cargoCallCenter
      escrutinioPresidenteSubido
      escrutinioGobernadorSubido
      escrutinioIntendenteSubido
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMesaTestigos = /* GraphQL */ `
  query ListMesaTestigos(
    $filter: ModelMesaTestigoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMesaTestigos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        operativo
        seccionElectoral
        partido
        circuitoElectoral
        nombreEscuela
        codigoEscuela
        mesa
        fiscalizador
        cantidadPersonasPadron
        cantidadPersonasVotaron
        lastSyncPersonasVotaron
        expansor
        cerrada
        cargoCallCenter
        escrutinioPresidenteSubido
        escrutinioGobernadorSubido
        escrutinioIntendenteSubido
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEscrutinio = /* GraphQL */ `
  query GetEscrutinio($id: ID!) {
    getEscrutinio(id: $id) {
      id
      operativo
      seccionElectoral
      partido
      circuitoElectoral
      nombreEscuela
      codigoEscuela
      mesa
      fiscalizador
      tipo
      lista
      codigoLista
      candidato
      fuerzaPolitica
      votos
      votosExpandidos
      expansor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEscrutinios = /* GraphQL */ `
  query ListEscrutinios(
    $filter: ModelEscrutinioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEscrutinios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        operativo
        seccionElectoral
        partido
        circuitoElectoral
        nombreEscuela
        codigoEscuela
        mesa
        fiscalizador
        tipo
        lista
        codigoLista
        candidato
        fuerzaPolitica
        votos
        votosExpandidos
        expansor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const encuestaBocaUrnasByCodigoEscuela = /* GraphQL */ `
  query EncuestaBocaUrnasByCodigoEscuela(
    $codigoEscuela: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEncuestaBocaUrnaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    encuestaBocaUrnasByCodigoEscuela(
      codigoEscuela: $codigoEscuela
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        circuitoElectoral
        codigoEscuela
        escuela
        operativo
        encuestador
        rangoEtario
        genero
        pudoVotar
        votoEnBlanco
        cortoBoleta
        votoListaPresidente
        votoListaGobernador
        votoListaIntendente
        noPudoVotarMotivo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const encuestaBocaUrnasByEncuestador = /* GraphQL */ `
  query EncuestaBocaUrnasByEncuestador(
    $encuestador: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEncuestaBocaUrnaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    encuestaBocaUrnasByEncuestador(
      encuestador: $encuestador
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        circuitoElectoral
        codigoEscuela
        escuela
        operativo
        encuestador
        rangoEtario
        genero
        pudoVotar
        votoEnBlanco
        cortoBoleta
        votoListaPresidente
        votoListaGobernador
        votoListaIntendente
        noPudoVotarMotivo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const personalsByRol = /* GraphQL */ `
  query PersonalsByRol(
    $rol: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPersonalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalsByRol(
      rol: $rol
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nickname
        rol
        codigoEscuelaAsignada
        nombreEscuelaAsignada
        circuitoElectoralAsignado
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const seguimientosByEncuestador = /* GraphQL */ `
  query SeguimientosByEncuestador(
    $encuestador: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSeguimientoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seguimientosByEncuestador(
      encuestador: $encuestador
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        encuestador
        operativo
        codigoEscuela
        circuitoElectoral
        escuela
        cantidadEncuestas
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const seguimientosByOperativo = /* GraphQL */ `
  query SeguimientosByOperativo(
    $operativo: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSeguimientoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seguimientosByOperativo(
      operativo: $operativo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        encuestador
        operativo
        codigoEscuela
        circuitoElectoral
        escuela
        cantidadEncuestas
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mesaTestigosByOperativo = /* GraphQL */ `
  query MesaTestigosByOperativo(
    $operativo: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMesaTestigoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mesaTestigosByOperativo(
      operativo: $operativo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        operativo
        seccionElectoral
        partido
        circuitoElectoral
        nombreEscuela
        codigoEscuela
        mesa
        fiscalizador
        cantidadPersonasPadron
        cantidadPersonasVotaron
        lastSyncPersonasVotaron
        expansor
        cerrada
        cargoCallCenter
        escrutinioPresidenteSubido
        escrutinioGobernadorSubido
        escrutinioIntendenteSubido
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mesaTestigosByFiscalizador = /* GraphQL */ `
  query MesaTestigosByFiscalizador(
    $fiscalizador: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMesaTestigoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mesaTestigosByFiscalizador(
      fiscalizador: $fiscalizador
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        operativo
        seccionElectoral
        partido
        circuitoElectoral
        nombreEscuela
        codigoEscuela
        mesa
        fiscalizador
        cantidadPersonasPadron
        cantidadPersonasVotaron
        lastSyncPersonasVotaron
        expansor
        cerrada
        cargoCallCenter
        escrutinioPresidenteSubido
        escrutinioGobernadorSubido
        escrutinioIntendenteSubido
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const escrutiniosByOperativo = /* GraphQL */ `
  query EscrutiniosByOperativo(
    $operativo: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEscrutinioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    escrutiniosByOperativo(
      operativo: $operativo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        operativo
        seccionElectoral
        partido
        circuitoElectoral
        nombreEscuela
        codigoEscuela
        mesa
        fiscalizador
        tipo
        lista
        codigoLista
        candidato
        fuerzaPolitica
        votos
        votosExpandidos
        expansor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const escrutiniosByFiscalizador = /* GraphQL */ `
  query EscrutiniosByFiscalizador(
    $fiscalizador: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEscrutinioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    escrutiniosByFiscalizador(
      fiscalizador: $fiscalizador
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        operativo
        seccionElectoral
        partido
        circuitoElectoral
        nombreEscuela
        codigoEscuela
        mesa
        fiscalizador
        tipo
        lista
        codigoLista
        candidato
        fuerzaPolitica
        votos
        votosExpandidos
        expansor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
