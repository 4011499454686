import { createTheme } from "@mui/material";

const AppTheme = createTheme({
    palette: {
        primary: {
            main: "#4D416F",
            light: "",
            dark: "",
            contrastText: "#FAFAFA",
        },
        secondary: {
            main: "#D4DA97",
            light: "",
            dark: "",
            contrastText: "#3E3E3E",
        },
        tertiary: {
            main: "#D4DA97",
            light: "",
            dark: "",
            contrastText: "#3E3E3E",
        },
        cta: {
            main: "#AFAEC8",
            light: "",
            dark: "",
            contrastText: "#3E3157"
        },
        info: {
            main: "#3E3157",
            light: "#3E3157",
            dark: "",
            contrastText: "",
        },
        text: {
            primary: "#424242",
            secondary: "#3E3E3E",
            // disabled: ""
        }
    },

    typography: {
        h1: {
            fontFamily: 'Chivo',
            fontSize: '2.9375rem',
            fontWeight: 700,
        },
        h2: {
            fontFamily: 'Chivo',
            fontSize: '1.7rem',
            fontWeight: 500,
        },
        h3: {
            fontFamily: 'Chivo',
            fontSize: '1.25rem',
            fontWeight: 500,
        },
        h4: {
            fontFamily: 'Chivo',
            fontSize: '18px',
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: '16px',
        },
        button: {
            fontFamily: 'Chivo',

        },
    },
});

export default AppTheme;