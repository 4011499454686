export const APP_ROLES = {
    SUPER_ADMIN: "SuperAdmin",
    SUPERVISOR: "Supervisor",
    FISCALIZADOR: "Fiscalizador",
    ENCUESTADOR: "Encuestador",
    ANALISTA: "Analista",
    CALL_CENTER_LANUS: "CallCenterLanus",
    CALL_CENTER_PROV: "CallCenterProv",
};

/**
 * Variables para definir las rutas y navegación dentro del sitio
 * @constant
 */
export const APP_ROUTES = {
    LOGIN: "/iniciar-sesion",
    SIGN_UP: "/crear-cuenta",
    FORGOT_PASSWORD: "/regenerar",
    HOME: "/",
    TABLEROS: "/tableros",
    TABLERO_BOCA_DE_URNA: "/boca-urna",
    TABLERO_MESA_TESTIGO: "/mesa-testigo",
    MESA_TESTIGO: "/mesa-testigo",
    BOCA_DE_URNA: "/boca-urna",
    BOCA_DE_URNA_ENCUESTA: "/encuesta",
    STATUS_OPERATIVO: "/estado-operativo",
    FINISH_BOCA_DE_URNA_ENCUESTA: "/encuesta-finalizada",
    MUESTRALES: "/muestrales",
    MESA_TESTIGO_CONTEO: "/conteo",
    MESA_TESTIGO_CANT_PADRON: "/padron",
    MESA_TESTIGO_CANT_PARTICIPACION: "/participacion",
    USUARIOS: "/usuarios",
};

export const TIPOS_OPERATIVOS = {
    LANUS: { value: "generales-23-lanus", descripcion: "Lanús - Generales" },
    PROVINCIA_BA: {
        value: "generales-23-provincia-bs-as", descripcion: "Provincia Buenos Aires - Generales"
    }
};

export const MESA_TESTIGO_STATUS_CONTEO_CANDIDATURAS = {
    EN_PROGRESO: "en-progreso",
    COMPLETADO: "completado", //se envio. se puede editar por si hubo errores
    ENVIADO: "enviado", //se enviaron todos los escrutinios. usuario final hizo click en "cerrar mesa"
    SIN_EMPEZAR: "sin-empezar",
    ERROR_SINC: "error-sinc",
};

export const LISTADO_CANDIDATURAS = [
    { "operativo": TIPOS_OPERATIVOS.LANUS.value, "tipo": "escrutinioPresidente", "name": "lanus-presidente", "label": "Votos para candidatos a Presidente", "linkTo": `${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CONTEO}/lanus-presidente` },
    { "operativo": TIPOS_OPERATIVOS.LANUS.value, "tipo": "escrutinioGobernador", "name": "lanus-gobernador", "label": "Votos para candidatos a Gobernador", "linkTo": `${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CONTEO}/lanus-gobernador` },
    { "operativo": TIPOS_OPERATIVOS.LANUS.value, "tipo": "escrutinioIntendente", "name": "lanus-intendente", "label": "Votos para candidatos a Intendente", "linkTo": `${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CONTEO}/lanus-intendente` },
    { "operativo": TIPOS_OPERATIVOS.PROVINCIA_BA.value, "tipo": "escrutinioPresidente", "name": "prov-presidente", "label": "Votos para candidatos a Presidente", "linkTo": `${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CONTEO}/prov-presidente` },
    { "operativo": TIPOS_OPERATIVOS.PROVINCIA_BA.value, "tipo": "escrutinioGobernador", "name": "prov-gobernador", "label": "Votos para candidatos a Gobernador", "linkTo": `${APP_ROUTES.MESA_TESTIGO}${APP_ROUTES.MESA_TESTIGO_CONTEO}/prov-gobernador` },
];

export const CANDIDATURAS_PASO_23_PRESIDENCIA = [
    // { "posicion": 1, "tipo": "escrutinioPresidente", "lista": "P222|Horacio Rodriguez Larreta - Gerardo Morales(JxC)", "votos": 0 },
    { "posicion": 1, "tipo": "escrutinioPresidente", "lista": "P111|Patricia Bulrich - Luis Petri(JxC)", "votos": 0 },
    { "posicion": 2, "tipo": "escrutinioPresidente", "lista": "P444|Sergio Massa - Agustín Rossi(Unión por la Patria)", "votos": 0 },
    { "posicion": 3, "tipo": "escrutinioPresidente", "lista": "P333|Javier Milei - Victoria Villaroel(La Libertad Avanza)", "votos": 0 },
    { "posicion": 4, "tipo": "escrutinioPresidente", "lista": "P666|Juan Schiaretti - Florencio Randazzo(Hacemos por nuestro País)", "votos": 0 },
    // { "posicion": 5, "tipo": "escrutinioPresidente", "lista": "P555|Juan Grabois - Paula Abal Medina(Unión por la Patria)", "votos": 0 },
    { "posicion": 5, "tipo": "escrutinioPresidente", "lista": "P777|Myriam Bregman - Nicolás Del Caño(FIT-U)", "votos": 0 },
    { "posicion": 6, "tipo": "escrutinioPresidente", "lista": "P999|Blanco(Blanco)", "votos": 0 },
    { "posicion": 7, "tipo": "escrutinioPresidente", "lista": "P990|Nulos(Nulos)", "votos": 0 },
    // { "posicion": 8, "tipo": "escrutinioPresidente", "lista": "P888|Otros candidatos(Otros)", "votos": 0 }, //siempre dejar este como último elemento de la lista
];

export const CANDIDATURAS_PASO_23_GOBERNADOR = [
    // { "posicion": 1, "tipo": "escrutinioGobernador", "lista": "G111|Diego Santilli - Gustavo Pose(JxC)", "votos": 0 },
    { "posicion": 1, "tipo": "escrutinioGobernador", "lista": "G222|Néstor Grindetti - Miguel Fernandez(JxC)", "votos": 0 },
    { "posicion": 2, "tipo": "escrutinioGobernador", "lista": "G444|Axel Kicillof - Verónica Magario(Unión por la Patria)", "votos": 0 },
    { "posicion": 3, "tipo": "escrutinioGobernador", "lista": "G333|Carolina Píparo - Francisco Oneto(La Libertad Avanza)", "votos": 0 },
    { "posicion": 4, "tipo": "escrutinioPresidente", "lista": "G777|Rubén Sobrero - Nathalia Seligra(FIT-U)", "votos": 0 },
    { "posicion": 5, "tipo": "escrutinioGobernador", "lista": "G999|Blanco(Blanco)", "votos": 0 },
    { "posicion": 6, "tipo": "escrutinioPresidente", "lista": "G990|Nulos(Nulos)", "votos": 0 },
    // { "posicion": 7, "tipo": "escrutinioGobernador", "lista": "G888|Otros candidatos(Otros)", "votos": 0 }, //siempre dejar este como último elemento de la lista
];

export const CANDIDATURAS_PASO_23_INTENDENCIA = [
    // { "posicion": 1, "tipo": "escrutinioIntendente", "lista": "I222|Ignacio Moroni(JxC, Falta Menos para vivir sin miedo, HRL)", "votos": 0 },
    { "posicion": 1, "tipo": "escrutinioIntendente", "lista": "I111|Diego Kravetz(JxC)", "votos": 0 },
    { "posicion": 2, "tipo": "escrutinioIntendente", "lista": "I333|Julián Álvarez(Unión por la Patria)", "votos": 0 },
    // { "posicion": 4, "tipo": "escrutinioIntendente", "lista": "I555|Agustín Balladares(Unión por la Patria, Lanús Da Para Más)", "votos": 0 },
    // { "posicion": 5, "tipo": "escrutinioIntendente", "lista": "I444|Nicolás Russo(Unión por la Patria, Frente Renovador)", "votos": 0 },
    // { "posicion": 6, "tipo": "escrutinioIntendente", "lista": "I666|Víctor De Genaro(Unión por la Patria, Unidad Popular)", "votos": 0 },
    { "posicion": 3, "tipo": "escrutinioIntendente", "lista": "I777|Jorge García Zajac(La Libertad Avanza)", "votos": 0 },
    { "posicion": 4, "tipo": "escrutinioPresidente", "lista": "I789|Sofía Belen Salce(FIT-U)", "votos": 0 },
    { "posicion": 5, "tipo": "escrutinioIntendente", "lista": "I999|Blanco(Blanco)", "votos": 0 },
    { "posicion": 7, "tipo": "escrutinioPresidente", "lista": "I990|Nulos(Nulos)", "votos": 0 },
    // { "posicion": 9, "tipo": "escrutinioIntendente", "lista": "I888|Otros candidatos(Otros)", "votos": 0 }, //siempre dejar este como último elemento de la lista
];

export const FLAG_FINALIZAR = "finalizar";

export const VOTOS_EN_BLANCO = {
    "votoListaPresidente": "Voto en blanco",
    "votoListaGobernador": "Voto en blanco",
    "votoListaIntendente": "Voto en blanco",
}

export const PREGUNTAS_DESCARTAR_CONSISTENTE = [
    "votoListaGobernador",
    "votoListaIntendente",
];

/// el atributo "value" de cada pregunta, tiene que coincidir con el atributo en la tabla de DynamoDB
export const CUESTIONARIO_PASO_23_LANUS = {
    PASOS: [
        { value: "pudoVotar", label: "01" },
        { value: "rangoEtario", label: "02" },
        { value: "genero", label: "03" },
        { value: "votoEnBlanco", label: "04" },
        { value: "votoListaPresidente", label: "05" },
        { value: "cortoBoleta", label: "06" },
        { value: "votoListaGobernador", label: "07" },
        { value: "votoListaIntendente", label: "08" },
        // { value: "noPudoVotarMotivo", label: "09" },
    ],
    PREGUNTAS: [
        {
            value: "pudoVotar", tituloPregunta: "¿Pudiste votar?", anterior: null, siguiente: "rangoEtario", opciones: [
                { value: "Sí", label: "Sí" },
                { value: "No", label: "No", siguiente: FLAG_FINALIZAR },
            ]
        },
        {
            value: "rangoEtario", tituloPregunta: "¿Qué edad tenés?", anterior: "pudoVotar", siguiente: "genero", opciones: [
                { value: "17 - 29 años", label: "17 - 29 años" },
                { value: "30 - 59 años", label: "30 - 59 años" },
                { value: "60 años y más", label: "60 años y más" },
            ]
        },
        {
            value: "genero", tituloPregunta: "¿Cuál es tu sexo?", anterior: "rangoEtario", siguiente: "votoEnBlanco", opciones: [
                { value: "Varón", label: "Varón" },
                { value: "Mujer", label: "Mujer" },
                { value: "Intersex", label: "Intersex" },
            ]
        },
        {
            value: "votoEnBlanco", tituloPregunta: "¿Votaste en blanco?", anterior: "genero", siguiente: "#", opciones: [
                { value: "Sí", label: "Sí", siguiente: FLAG_FINALIZAR },
                { value: "No", label: "No", siguiente: "votoListaPresidente" },
            ]
        },
        {
            value: "votoListaPresidente", tituloPregunta: "¿A quién votaste para presidente/a?", anterior: "votoEnBlanco", siguiente: "cortoBoleta", opciones: [
                { value: "Patricia Bullrich - Luis Petri (JxC)", label: "Patricia Bullrich - Luis Petri (JxC)" },
                // { value: "Horacio Rodríguez Larreta - Gerardo Morales (JxC)", label: "Horacio Rodríguez Larreta - Gerardo Morales (JxC)" },
                { value: "Javier Milei - Victoria Villaroel (La libertad Avanza)", label: "Javier Milei - Victoria Villaroel (La libertad Avanza)" },
                { value: "Sergio Massa - Agustín Rossi (Unión por la Patria)", label: "Sergio Massa - Agustín Rossi (Unión por la Patria)" },
                // { value: "Juan Grabois - Paula Abal Medina (Unión por la Patria)", label: "Juan Grabois - Paula Abal Medina (Unión por la Patria)" },
                // { value: "Juan Schiaretti - Florencio Randazzo (Hacemos por nuestro País)", label: "Juan Schiaretti - Florencio Randazzo (Hacemos por nuestro País)" },
                { value: "Otros", label: "Otros" },
                // { value: "No sabe - No contesta", label: "No sabe/No contesta" },
            ]
        },
        {
            value: "cortoBoleta", tituloPregunta: "¿Cortaste boleta?", anterior: "votoListaPresidente", siguiente: "#", opciones: [
                { value: "Sí", label: "Sí", siguiente: "votoListaGobernador" },
                { value: "No", label: "No", siguiente: FLAG_FINALIZAR },
            ]
        },
        {
            value: "votoListaGobernador", tituloPregunta: "¿A quién votaste para gobernador/a?", anterior: "cortoBoleta", siguiente: "votoListaIntendente", opciones: [
                { value: "Néstor Grindetti - Miguel Fernández (JxC)", label: "Néstor Grindetti - Miguel Fernández (JxC)" },
                // { value: "Diego Santilli - Gustavo Pose (JxC)", label: "Diego Santilli - Gustavo Pose (JxC)" },
                { value: "Carolina Píparo - Francisco Oneto (La libertad Avanza)", label: "Carolina Píparo - Francisco Oneto (La libertad Avanza)" },
                { value: "Axel Kicillof - Verónica Magario (Unión por la Patria)", label: "Axel Kicillof - Verónica Magario (Unión por la Patria)" },
                { value: "Otros", label: "Otros" },
                // { value: "No sabe - No contesta", label: "No sabe/No contesta" },
            ]
        },
        {
            value: "votoListaIntendente", tituloPregunta: "¿A quién votaste para intendente/a?", anterior: "votoListaGobernador", siguiente: FLAG_FINALIZAR, opciones: [
                { value: "Diego Kravetz (JxC)", label: "Diego Kravetz (JxC)" },
                // { value: "Ignacio Moroni (JXC, Falta Menos para vivir sin miedo, HRL)", label: "Ignacio Moroni (JXC, Falta Menos para vivir sin miedo, HRL)" },
                { value: "Jorge García Zajac (La libertad Avanza)", label: "Jorge García Zajac (La libertad Avanza)" },
                { value: "Julián Álvarez (Unión por la Patria)", label: "Julián Álvarez (Unión por la Patria)" },
                // { value: "Nicolás Russo (Unión por la Patria, Frente Renovador)", label: "Nicolás Russo (Unión por la Patria, Frente Renovador)" },
                // { value: "Agustín Balladares (Unión por la Patria, Lanús Da Para Más)", label: "Agustín Balladares (Unión por la Patria, Lanús Da Para Más)" },
                // { value: "Víctor De Genaro (Unión por la Patria, Unidad Popular)", label: "Víctor De Genaro (Unión por la Patria, Unidad Popular)" },
                // { value: "Peronistas otros", label: "Peronistas otros" },
                { value: "Otros", label: "Otros" },
                // { value: "No sabe - No contesta", label: "No sabe/No contesta" },
            ]
        },
        // {
        //     value: "noPudoVotarMotivo", tituloPregunta: "¿Por qué no pudiste votar?", anterior: "pudoVotar", siguiente: FLAG_FINALIZAR, opciones: [
        //         { value: "No estaba en el padrón", label: "No estaba en el padrón" },
        //         { value: "Estaba desorganizado y había mucha espera", label: "Estaba desorganizado y había mucha espera" },
        //         { value: "No me dejaron votar", label: "No me dejaron votar" },
        //     ]
        // },
    ]
}