import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { mockPersonalByRol_Encuestador_Lanus, mockPersonalByRol_Encuestador_Prov } from "../mocks/graphql/PersonalApiMock";
import { TIPOS_OPERATIVOS } from "../constants/AppConstants";

export const PersonalApi = {
    getPersonalByRol: async (rol, operativoId) => {
        if (!rol) return;
        if (process.env.NODE_ENV === "development") {
            switch (operativoId) {
                case TIPOS_OPERATIVOS.LANUS.value:
                    return mockPersonalByRol_Encuestador_Lanus;
                case TIPOS_OPERATIVOS.PROVINCIA_BA.value:
                    return mockPersonalByRol_Encuestador_Prov;
                default:
                    break;
            }
        };

        ///filtro personal a partir del operativo seleccionado
        let variables = { rol: rol }

        if (operativoId === TIPOS_OPERATIVOS.LANUS.value) {
            //si es Lanus, me quedo solo con aquel personal cuyo "circuitoElectoral" esté entre 260 y 276
            variables = {
                ...variables,
                filter: {
                    circuitoElectoralAsignado: { between: ["260", "276"] },
                }
            }
        }
        else {
            variables = {
                ...variables,
                filter: {
                    not: { circuitoElectoralAsignado: { between: ["260", "276"] } }
                }
            }
        }

        const result = await API.graphql(
            graphqlOperation(queries.personalsByRol, variables)
        );

        return result;
    },
    createPersonal: async (personal) => {
        if (!personal) return;

        const newPersonal = await API.graphql(
            graphqlOperation(mutations.createPersonal, { input: personal })
        );

        return newPersonal;
    },

    updatePersonal: async (personal) => {
        if (!personal) return;

        const personalUpdated = {
            ...personal,
            codigoEscuelaAsignada: personal.codigoEscuelaAsignada,
            nombreEscuelaAsignada: personal.nombreEscuelaAsignada,
            circuitoElectoralAsignado: personal.circuitoElectoralAsignado,
        }

        const updatedPersonal = await API.graphql(
            graphqlOperation(mutations.updatePersonal, { input: personalUpdated })
        );

        return personal;
    },

    deletePersonal: async (personal) => {
        if (!personal) return;

        const deletedPersonal = await API.graphql(
            graphqlOperation(mutations.deletePersonal, { input: personal })
        );

        return deletedPersonal;
    }
}